<template>
  <div class="bcgcolor">
    <header>
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i4/O1CN01wnAEuy26SmycqEP3r_!!6000000007661-2-tps-750-451.png"
        alt=""
      />
    </header>
    <section>
      <div class="main1">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i4/O1CN01IWbRYW202KOuLxKMg_!!6000000006791-2-tps-704-759.png"
          alt=""
        />
      </div>
      <div class="main2">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i3/O1CN01GCITcl1YwNdEpkxEN_!!6000000003123-2-tps-704-1023.png"
          alt=""
        />
      </div>
      <div class="main3">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i4/O1CN01FvXGoB1LZr9Gb9EED_!!6000000001314-2-tps-704-820.png"
          alt=""
        />
      </div>
      <div class="main4">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i2/O1CN01c5KdTV1zOwLCpGHpN_!!6000000006705-2-tps-704-659.png"
          alt=""
        />
      </div>
      <div class="main5">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i3/O1CN0173JCBl1KzDSsDkJ7a_!!6000000001234-2-tps-704-659.png"
          alt=""
        />
      </div>
      <div class="main6">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i1/O1CN0121ydMT1LOPFUDb1Yz_!!6000000001289-2-tps-704-2741.png"
          alt=""
        />
      </div>
      <div class="main7">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i1/O1CN01OIVU3P1ZZlh2E240C_!!6000000003209-2-tps-702-285.png"
          alt=""
        />
      </div>
      <div class="main7">
        <img
          width="1000px"
          src="https://img.alicdn.com/imgextra/i2/O1CN01E86M6e24v4CbjZojf_!!6000000007452-2-tps-710-22.png"
          alt=""
        />
      </div>
      <div class="main8">
        <div class="main8sum">
          <img
            width="100%"
            src="https://img.alicdn.com/imgextra/i3/O1CN01EHd34x1mS42P3r2T8_!!6000000004952-2-tps-750-300.png"
            alt=""
          />
          <div class="main8title">
            <div>洪思：18857714638</div>
            <div style="margin-top: 3px;">
              在线咨询旺旺
            </div>
            <div style="margin-top: 2px;">
              淘宝教育商家成长安静
            </div>
            <a
              target="_blank"
              href="https://www.taobao.com/go/market/webww/ww.php?ver=3&touid=淘宝教育商家成长安静&siteid=cntaobao&status=1&charset=utf-8"
              ><img
                src="https://img.alicdn.com/imgextra/i3/O1CN01pshhXZ1px9jw3DGAk_!!6000000005426-1-tps-77-19.gif"
                alt=""
            /></a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    gobuy() {
      let returnEle = null;
      returnEle = document.querySelector("#buy");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    buy() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/413");
    },
  },
};
</script>

<style scoped>
.main8title {
  top: 260px;
  left: 780px;
  position: absolute;
  font-size: 22px;
  font-weight: 600;
}
.main8sum {
  width: 1150px;
  position: relative;
  margin: 0 auto;
}
.title {
  width: 110px;
  height: 30px;
  background-color: rgb(251, 199, 1);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.title1 {
  width: 110px;
  height: 30px;
  background-color: rgb(14, 210, 250);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.bttitle {
  margin-left: 10px;
  width: 150px;
  height: 30px;
  font-size: 16px;
  font-weight: 450;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.carousel1 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 570px;
}
.carousel2 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 100px;
}
#buy {
  position: absolute;
  top: 0;
}
.buy {
  width: 470px;
  height: 55px;
  color: white;
  position: absolute;
  bottom: 13%;
  left: 50%;
  line-height: 50px;
}
.buy:hover {
  cursor: pointer;
}
.buysum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1 {
  text-align: center;
  padding-top: 100px;
}
.main2 {
  text-align: center;
  margin-top: 65px;
}
.main3 {
  text-align: center;
  margin-top: 75px;
}
.main4 {
  text-align: center;
  margin-top: 75px;
}
.main5 {
  text-align: center;
  margin-top: 75px;
}
.main6 {
  text-align: center;
  margin-top: 75px;
}
.main7 {
  text-align: center;
  margin-top: 75px;
}
.main8 {
  text-align: center;
  margin-top: 70px;
  background-color: #ffefef;
}
.main9 {
  text-align: center;
  padding-top: 70px;
}
.main10 {
  text-align: center;
  padding-top: 70px;
}
.main11 {
  text-align: center;
  padding-top: 70px;
  padding-right: 85px;
}
section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01Qm3Re91OiVTd9jx9w_!!6000000001739-2-tps-750-7610.png");
  background-size: cover;
  margin: -40px auto 0;
  padding-bottom: 0px;
  box-shadow: inset 0px 45px 10px -15px white;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
.headimg {
  position: absolute;
  width: 25%;
  bottom: 25%;
  left: 52%;
  transform: translateX(-50%);
}
.headimg:hover {
  cursor: pointer;
}
</style>
