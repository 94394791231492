<template>
  <div>
    <section>
      <div class="main1">
        <img
          width="100%"
          src="https://img.alicdn.com/imgextra/i2/O1CN01A8LOKD1K9SrFhYdFm_!!6000000001121-0-tps-1920-5772.jpg"
          alt=""
        />
        <div class="first" @click="goshop"></div>
        <div class="first1" @click="goshop1"></div>
        <div class="first2" @click="goshop2"></div>
 
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rightshow: true,
      phoneshow: false,
      a: "visible",
    };
  },
  methods: {
    changeStyle() {
      if (this.a === "visible") {
        this.a = "hidden";
      } else {
        this.a = "visible";
      }
    },
    gobuy() {
      let returnEle = null;
      returnEle = document.querySelector("#buy");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    goshop() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/622");
    },
    goshop1() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/623");
    },
    goshop2() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/624");
    },
  },
};
</script>

<style scoped>
.first {
  position: absolute;
  width: 12.76%;
  height: 0.79%;
  top: 26.59%;
  left: 25.98%;
  cursor: pointer;
}

.first1 {
  position: absolute;
  width: 12.55%;
  height: 0.74%;
  top: 26.58%;
  left: 57.81%;
  cursor: pointer;
}
.first2 {
  position: absolute;
  width: 19.47%;
  height: 1.19%;
  top: 28.99%;
  left: 54.68%;
  cursor: pointer;
}

#buy {
  position: absolute;
  top: 0;
}
.buy {
  width: 470px;
  height: 55px;
  color: white;
  position: absolute;
  bottom: 13%;
  left: 50%;
  line-height: 50px;
}
.buy:hover {
  cursor: pointer;
}
.buysum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1 {
  text-align: center;
  padding-top: 50px;
  position: relative;
}

section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;

  margin: -50px auto 0;
  box-shadow: inset 0px 45px 10px -15px white;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
.headimg {
  position: absolute;
  width: 25%;
  bottom: 25%;
  left: 52%;
  transform: translateX(-50%);
}
.headimg:hover {
  cursor: pointer;
}
</style>
