import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { getColumn } from "@/api/column";
import { getBanner } from "@/api/banner";
import { getAdvertisement } from "@/api/advertisement";
import { getTrends } from "@/api/trends";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    columnList: [],
    topswiperList: [],
    advertisementList: [],
    trendsList: [],
  },
  mutations: {
    getTopswiper(state, value) {
      state.topswiperList = value;
    },
    getAdvertisement(state, value) {
      state.advertisementList = value;
    },
    getTrends(state, value) {
      state.trendsList = value;
    },
    getColumn(state, value) {
      state.columnList = value;
    },
  },
  actions: {
    getList(context) {
      axios
        .all([
          getColumn(), //栏目

          getBanner({ columnId: 11, page: 1, pageSize: 4 }), //轮播图

          getAdvertisement({ columnId: 20, page: 1, pageSize: 6 }), //广告位

          getTrends({ columnId: 21, page: 1, pageSize: 5 }), //业务动态
        ])

        .then(
          axios.spread(
            (columnResp, topswiperResp, advertisementResp, trendsResp) => {
              context.commit("getColumn", columnResp.data.data.result);

              context.commit("getTopswiper", topswiperResp.data.data.result);
              context.commit(
                "getAdvertisement",
                advertisementResp.data.data.result
              );
              context.commit("getTrends", trendsResp.data.data.result);
            }
          )
        );
    },
  },
  modules: {},
});
