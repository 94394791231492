<template>
  <div>
    <div>
      <div class="home">
        <home-header class="fixed-top" @showSearch="showSearch"></home-header>
        <div class="section">
          <div class="container">
            <home-leftaside
              class="fixed-aside"
              @showSearch="showSearch"
            ></home-leftaside>
            <div class="left-aside"></div>
            <home-section class="page"></home-section>
            <home-rightaside class="right-aside"></home-rightaside>
          </div>
        </div>
        <home-footer class="footer"></home-footer>
      </div>
    </div>
    <div>
      <home-search v-if="searchShow" @exitSearch="exitSearch"></home-search>
    </div>
  </div>
</template>

<script>
import HomeHeader from "../common/Header.vue";
import HomeLeftaside from "../common/Leftaside.vue";
import HomeSection from "./components/Section.vue";
import HomeRightaside from "../common/Rightaside.vue";
import HomeFooter from "../common/Footer.vue";
import HomeSearch from "../activity/components/Search.vue";

export default {
  components: {
    HomeHeader,
    HomeLeftaside,
    HomeSection,
    HomeRightaside,
    HomeFooter,
    HomeSearch,
  },
  data() {
    return {
      searchShow: false,
    };
  },
  methods: {
    showSearch() {
      this.searchShow = true;
    },
    exitSearch() {
      this.searchShow = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "Articledetail") {
      next();
    } else {
      sessionStorage.removeItem("currentPage");
      next();
    }
  },
};
</script>

<style scoped>
@import "./../../assets/css/common.css";

/* body *{
-webkit-filter: grayscale(100%); 
-moz-filter: grayscale(100%); 
-ms-filter: grayscale(100%); 
-o-filter: grayscale(100%);
filter: grayscale(100%);
filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1); 
filter:gray; 
} */
/*首页置灰，但是这行代码是为了兼容IE, 虽然一行代码很少, 但是效果并不好, 而且网页比较卡, 不推荐使用[即删除,], 同时发现, csdn, 京东, 淘宝也都没有兼容IE11浏览器 */

</style>
