import request from "@/utils/request";

// 首页列表数量
export function getHomeListCount() {
  return request({
    url: "/api/product/content/count",
    method: "get",
  });
}

//首页列表
export function getHomeList(params) {
  return request({
    url: "/api/product/content/list",
    method: "get",
    params,
  });
}
