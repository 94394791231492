<template>
  <div id="app" v-cloak>
    <router-view />
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
export default {
  name: "App",
  data() {
    return {
      isActive: false,
      top: false,
      logo2: "https://img.alicdn.com/tfs/TB1.VQLtGNj0u4jSZFyXXXgMVXa-60-44.png",
    };
  },
  watch: {
    $route() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.checkSign();
    },
  },

  methods: {
    backTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 1);
    },

    showCode() {
      this.isActive = true;
    },
    checkSign() {
      let nowUrl = window.location.href.split("#")[0];
      this.$https
        .get(this.host + `/api/product/wechat/getSign?url=${nowUrl}`)
        .then(function(res) {
          wx.config({
            debug: false, // 开启调试模式,
            appId: res.data.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名，见附录1
            jsApiList: ["updateAppMessageShareData"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          wx.ready(function() {
            //需在用户可能点击分享按钮前就先调用
            wx.updateAppMessageShareData({
              title: "天下网商", // 分享标题
              desc:
                "天下网商掌握权威的电商资讯和人脉，专注为新网商提供专业原创的电商资讯、知识，同时还提供天猫和淘宝商家集培训、营销、实战于一体的系统化服务，成为电商第一入口", // 分享描述
              link: nowUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl:
                "https://img.alicdn.com/imgextra/i4/O1CN01edOyRD27O2UDuVmGv_!!6000000007786-0-tps-500-500.jpg", // 分享图标
              success: function() {},
            });
          });
          wx.error(function(res) {
            console.log(res);
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          });
        });
    },
  },
  beforeCreate() {
    this.$store.dispatch("getList");
  },
  mounted() {
    this.host = process.env.VUE_APP_API || "";
    var that = this;
    window.addEventListener("scroll", function() {
      //监听滚动
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 1000) {
        //如果当前滚动位置大于您的所需要达到的高度则显示置顶元素，反之不显示
        that.top = true;
      } else {
        that.top = false;
      }
    });
    this.checkSign();
  },
};
</script>
<style>
[v-cloak] {
  display: none;
}
#app {
  font-family: "PingFang SC", "Microsoft Yahei", "宋体", "Helvetica Neue",
    "Helvetica", "Arial", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#app a {
  text-decoration: none;
}
body {
  margin: 0;
}
.backtop {
  position: fixed;
  left: 50%;
  margin-left: 640px;
  bottom: 310px;
  width: 50px;
  height: 135px;
}
@media screen and (max-device-width: 960px) {
  .backtop {
    position: fixed;
    left: 50%;
    margin-left: 600px;
    bottom: 290px;
    width: 50px;
    height: 135px;
  }
}

.backtop .border-box {
  width: 50px;
  height: 50px;
  border: 1px solid rgb(232, 232, 232);
  margin-bottom: 10px;
}
.backtop .border-box img {
  padding: 3px;
  width: 44px;
  height: 44px;
}
.backtop span {
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  background-color: rgb(255, 229, 232);
  border: 1px solid rgb(215, 10, 25);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.top-icon {
  font-size: 25px;
  color: rgb(216, 12, 24);
}
.sweep {
  position: absolute;
  right: 90px;
  bottom: -9px;
}
.logo2 {
  width: 53px;
  height: 46px;
  text-align: center;
}
.logo2 img {
  width: 53px;
  height: 46px;
}
</style>
