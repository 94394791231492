<template>
  <div>
    <header>
      <nav>
        <span>
          <img
            src="https://img.alicdn.com/tfs/TB1_6Gqvj39YK4jSZPcXXXrUFXa-105-36.png"
            alt=""
          />
        </span>
        <ul>
          <li @click="move1" class="nav_home">首页</li>
          <li @click="move2" class="nav_person">参会嘉宾</li>
          <li @click="move3" class="nav_agenda">大会议程</li>
          <li @click="move4" class="nav_sale">早鸟票预售</li>
          <li @click="move5" class="nav_media">合作媒体</li>
          <li @click="move6" class="nav_contact">联系我们</li>
        </ul>
        <div class="back">
          <a
            href="https://www.tianxiawangshang.com/activity/wsfh/newispower/review"
            >峰会回顾</a
          >
        </div>
      </nav>
    </header>
    <div class="bckcolor">
      <div class="banner" id="home">
        <img
          src="https://img.alicdn.com/tfs/TB1xu1jp9slXu8jSZFuXXXg7FXa-1920-552.jpg"
          alt=""
        />
      </div>

      <section>
        <div>
          <div class="left">
            <p>2020注定是一个被写进商业史册的年份</p>
            <p>一场突如其来的疫情</p>
            <p>加速了全社会商业数字化的进程</p>
            <p>新消费人群更迭，新消费品牌崛起</p>

            <p>新营销持续“造浪”，新制造蓬勃发展</p>
            <p>在时代的变革中</p>
            <p>新的商业力量在孕育</p>

            <p style="margin-top: 50px;">2020年末，我们继续以新商业媒体身份</p>
            <p>与数千万新商业从业者一起</p>
            <p>盘点这一年的商业里程碑大事件</p>
            <p>共同评选新网商年度影响力人物</p>
          </div>
          <div class="right">
            <p>
              时间：2020年12月22日
            </p>
            <p>地点：中国·杭州 泛海钓鱼台酒店</p>
          </div>
        </div>

        <div class="cont">
          <div class="img1">
            <img
              src="https://img.alicdn.com/tfs/TB1d1U7smR26e4jSZFEXXbwuXXa-342-140.png"
              alt=""
            />
          </div>

          <div class="img2">
            <img
              src="https://img.alicdn.com/tfs/TB1XMJ04Hr1gK0jSZFDXXb9yVXa-1172-342.jpg"
              alt=""
            />
          </div>
        </div>

        <div id="person">
          <img
            src="https://img.alicdn.com/tfs/TB1TbyJqOpE_u4jSZKbXXbCUVXa-1172-1241.jpg"
            alt=""
          />
        </div>
        <div id="agenda">
          <img
            src="https://img.alicdn.com/tfs/TB1a.dN47Y2gK0jSZFgXXc5OFXa-1177-3010.jpg"
            alt=""
          />
        </div>

        <div id="Sale">
          <div class="sale">
            <img
              src="https://img.alicdn.com/tfs/TB1h4FI4Nz1gK0jSZSgXXavwpXa-451-141.jpg"
              alt=""
            />
          </div>
          <div class="content">
            <div class="left1">
              <div style="height: 130px;">
                <div class="bg-1"><span>普通票</span></div>
                <div class="bg-2"><span> 580元</span></div>
              </div>
              <div class="link">
                <span
                  ><a href="http://r6d.cn/Le8a" target="_blank"
                    >点击购票</a
                  ></span
                >
              </div>
              <span class="desc">普通票权益：大会全天通票</span>
            </div>
            <div class="right1">
              <div style="height: 130px;">
                <div class="bg-1"><span> VIP票</span></div>
                <div class="bg-2"><span> 980元</span></div>
              </div>
              <div class="link">
                <span
                  ><a href="http://r6d.cn/Le8a" target="_blank"
                    >点击购票</a
                  ></span
                >
              </div>
              <span class="desc"
                >VIP票专属权益：仅限100席 大会全天通票 快速签到通道 VIP专属席位
                会议授权资料</span
              >
            </div>
          </div>
        </div>

        <div id="media">
          <img
            src="https://img.alicdn.com/tfs/TB1GOXZ4FT7gK0jSZFpXXaTkpXa-1172-704.jpg"
            alt=""
          />
        </div>
      </section>
      <footer>
        <div id="contact">
          <p>联系我们</p>
          <ul>
            <li>
              <ul>
                <h1>商务合作：</h1>
                <h3>院长</h3>
                <h4>wechat：edison6066</h4>
                <h4>Email：wb-wds464292@alibaba-inc.com</h4>
              </ul>
            </li>
            <li>
              <ul>
                <h1>媒体合作：</h1>
                <h3>Suri</h3>
                <h4>wechat：88288769</h4>
                <h4>E-mail：wb-xjd259705@alibaba-inc.com</h4>
              </ul>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    move1() {
      let returnEle = null;
      returnEle = document.querySelector("#home");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move2() {
      let returnEle = null;
      returnEle = document.querySelector("#person");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move3() {
      let returnEle = null;
      returnEle = document.querySelector("#agenda");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move4() {
      let returnEle = null;
      returnEle = document.querySelector("#Sale");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move5() {
      let returnEle = null;
      returnEle = document.querySelector("#media");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move6() {
      let returnEle = null;
      returnEle = document.querySelector("#contact");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
  },
};
</script>

<style scoped>
.bckcolor {
  margin: 0;
  width: 100%;
  min-width: 1600px;
  height: 100%;
  background-image: url("https://img.alicdn.com/tfs/TB1CL85vz39YK4jSZPcXXXrUFXa-1920-7975.jpg");
  background-size: cover;
}
header {
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 1600px;
  height: 90px;
  background-image: url("https://img.alicdn.com/tfs/TB1bekytfzO3e4jSZFxXXaP_FXa-1920-87.jpg");
  background-size: cover;
  z-index: 100;
}
nav {
  width: 1600px;
  height: 90px;

  display: flex;
  margin: 0 auto;
}
nav > span {
  display: inline-block;
  margin-left: 217px;
  width: 122px;
  padding-top: 35px;
  height: 40px;
  font-size: 25px;
  font-weight: 500;
}

nav > ul {
  width: 995px;
  height: 30px;
  margin-left: 150px;
  padding-top: 30px;
}
nav > ul li {
  float: left;
  margin-left: 50px;
  width: 87px;
  height: 30px;
  list-style-type: none;
  font-weight: 500;
  font-size: 17px;
}
.banner {
  height: 550px;
  min-width: 1600px;
  width: 100%;
}
.banner > img {
  width: 100%;
  height: 100%;
}
section {
  width: 100%;
  min-width: 1600px;
  height: 100%;
}
section > div {
  margin: 0 auto;
}
section > div:nth-child(1) {
  width: 1175px;
  height: 845px;
}
section > div:nth-child(1) > .left {
  width: 585px;
  height: 585px;
  padding-top: 80px;
}
section > div:nth-child(1) > .left > p {
  color: rgb(254, 254, 254);
  font-size: 26px;
  font-weight: 400;
  margin: 0 0 10px 0;
}

section > div:nth-child(1) > .right {
  width: 485px;
  height: 105px;
  float: right;

  margin-top: 100px;
}
section > div:nth-child(1) > .right > p {
  color: rgb(254, 254, 254);
  font-size: 26px;
  font-weight: 400;
  margin: 0 0 10px 80px;
}
.cont {
  width: 100%;
  height: 390px;
  margin-top: -26px;
  position: relative;
}
.img1 {
  position: absolute;
  left: 0;

  top: 220px;
  z-index: 0;
}
.img2 {
  width: 1175px;
  height: 390px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

section > div:nth-child(3) {
  width: 1175px;
  height: 1245px;
}

section > div:nth-child(4) {
  width: 1177px;
  height: 3010px;
}

section > div:nth-child(5) {
  width: 1400px;
  height: 500px;
}

section > div:nth-child(6) {
  width: 1175px;
  height: 750px;
}

.sale {
  width: 450px;
  height: 110px;
  margin: 50px auto;
}
.sale > img {
  width: 100%;
  height: 100%;
}
.content {
  width: 1280px;
  height: 360px;
  margin: 0 auto;
}
.left1 {
  width: 595px;
  height: 350px;
  float: left;
  text-align: center;
}
.right1 {
  width: 595px;
  height: 350px;
  float: right;
  text-align: center;
}
.bg-1 {
  width: 200px;
  height: 105px;
  background-color: rgb(185, 96, 20);
  float: left;
}
.bg-1 > span {
  font-size: 40px;
  color: rgb(254, 254, 254);
  font-weight: 500;
  padding: 25px;
  display: block;
}

.bg-2 {
  width: 395px;
  height: 105px;
  background-color: rgb(255, 160, 79);
  float: right;
}

.bg-2 > span {
  font-size: 40px;
  color: rgb(0, 60, 255);
  font-weight: 500;
  padding: 25px;
  display: block;
}
.link {
  width: 362px;
  height: 45px;
  background-color: rgb(83, 251, 116);
  margin: 0 auto;
  margin-bottom: 20px;
}
.link > span {
  padding: 6px;
  display: block;
}
.link > span > a {
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  color: black;
}
.desc {
  color: rgb(254, 254, 254);
}
footer {
  width: 100%;
  min-width: 1600px;
  background-image: url("https://img.alicdn.com/tfs/TB1tCJY4Uz1gK0jSZLeXXb9kVXa-1920-407.jpg");
  background-size: cover;
  height: 410px;
}

footer > div {
  width: 1175px;
  height: 382px;
  margin: 0 auto;
}
footer > div > p {
  color: black;

  font-weight: bold;
  font-size: 37px;
  margin: 0 auto;
  width: 150px;
  height: 60px;
  padding-top: 39px;
}
footer > div > ul {
  width: 1015px;
  height: 175px;
  margin: 0 auto;
}
footer > div > ul > li {
  float: left;
  width: 415xp;
  height: 162px;
  list-style-type: none;
  margin: 0 70px;
}
.back {
  width: 160px;
  height: 40px;
  text-align: center;
  margin-top: 30px;
  margin-right: 25px;
  background: -webkit-linear-gradient(right, #e76363, #7142dd);
}
.back > a {
  line-height: 40px;
  font-weight: 400;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
}
</style>
