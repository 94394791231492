<template>
  <div>
    <header>
      <span>
        <img
          width="130%"
          src="https://img.alicdn.com/imgextra/i1/O1CN01Seh6is1IZuIZJdp3G_!!6000000000908-2-tps-100-36.png"
          alt=""
        />
      </span>
      <!-- <el-menu
        default-active="1"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="transparent"
        text-color="white"
        active-text-color="white"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">大会议程</el-menu-item>
        <el-menu-item index="3">门票预售</el-menu-item>
        <el-menu-item index="4">合作伙伴</el-menu-item>
        <el-menu-item index="5">联系我们</el-menu-item>
      </el-menu> -->
      <div class="menu">
        <div class="menuitem" @click="move1">大会首页</div>
        <div class="menuitem" @click="move2">精彩瞬间</div>
        <div class="menuitem" @click="move3">回到现场</div>
        <div class="menuitem" @click="move4">会议资讯</div>
        <div class="menuitem" @click="move5">大会集锦</div>
      </div>
      <div class="menuitem1" @click="move6">大会页面</div>
    </header>
    <section>
      <div class="topimg" id="home">
        <video
          autoplay
          loop
          muted
          src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/06.mp4"
        ></video>
        <!-- <img
          src="https://img.alicdn.com/imgextra/i3/O1CN01emjtQt1FvU706dYAr_!!6000000000549-2-tps-868-413.png"
          alt=""
        /> -->
      </div>
      <div class="toptitle" id="spoiler">
        <span style="margin-right:150px">2021/05/11</span>
        <span>中国·杭州</span>
        <span style="margin-left:30px">|</span>
        <span style="margin-left:30px">城西银泰4楼 传奇奢华影城中国巨幕厅</span>
      </div>
      <div class="middle">
        <div class="boxsum" style="margin-top:160px">
          <div class="box1">
            <img
              style="width:100%"
              src="https://img.alicdn.com/imgextra/i3/O1CN01g552sS1KYC34xM8qH_!!6000000001175-2-tps-269-103.png"
              alt=""
            />
          </div>
          <div class="span1">
            <div>精彩瞬间</div>
            <div>MOMENT</div>
          </div>
        </div>
        <div class="carousel">
          <el-carousel height="300px" class="topcarou">
            <el-carousel-item>
              <div class="carouselsum">
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i3/O1CN01YxZqtr1o24l36FJTO_!!6000000005166-0-tps-5760-3840.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01J4l3Jy1FasIVV9DS8_!!6000000000504-0-tps-5760-3840.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i2/O1CN01tNc4M41K90H7IeJgW_!!6000000001120-0-tps-5760-3840.jpg"
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carouselsum">
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i3/O1CN01zyrNou1rhhbOliahz_!!6000000005663-0-tps-7952-5304.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i2/O1CN01Giy7On1DTQd1BAd8i_!!6000000000217-0-tps-7952-5304.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01VE8roH1ZUGu9EqT4O_!!6000000003197-0-tps-7952-5304.jpg"
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carouselsum">
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i4/O1CN01ArL0xx1zLjWr5y699_!!6000000006698-0-tps-7952-5304.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01fTedGH1ZGX1TbqMvj_!!6000000003167-0-tps-6124-4082.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i1/O1CN01y5ZcMV1sYpXsvrswL_!!6000000005779-0-tps-1200-800.jpg"
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carouselsum">
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i4/O1CN01Qbzlaf1qaXnO5ZZFD_!!6000000005512-0-tps-1200-802.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i4/O1CN01wGxtgs1w5krfrOZh7_!!6000000006257-0-tps-5760-3840.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i2/O1CN01WQEzs51wyiOP611jb_!!6000000006377-0-tps-9504-6336.jpg"
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="carouselsum">
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i4/O1CN01Y3tEjX28GXZwX2eI9_!!6000000007905-0-tps-1200-802.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i3/O1CN01HyF06V1KAprNgACQ8_!!6000000001124-0-tps-1200-802.jpg"
                    alt=""
                  />
                </div>
                <div class="topcarousel">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i4/O1CN01FTmFCy1M1nGzulEGs_!!6000000001375-0-tps-1200-802.jpg"
                    alt=""
                  />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="boxsum">
          <div class="box1">
            <img
              style="width:100%"
              src="https://img.alicdn.com/imgextra/i3/O1CN01g552sS1KYC34xM8qH_!!6000000001175-2-tps-269-103.png"
              alt=""
            />
          </div>
          <div class="span1" id="review">
            <div>回到现场</div>
            <div>REVIEW</div>
          </div>
        </div>
        <div class="part1">
          <div class="parttitle">Wonder·新趋势</div>
        </div>
        <div class="partvideosum">
          <div class="partvideo">
            <div class="partvideo1">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i2/O1CN01aTOx0t1HE5pRwz3A7_!!6000000000725-2-tps-834-464.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E8%82%96%E5%88%A9%E5%8D%8E720.mp4"
              ></video>
            </div>
            <div class="partvideo2">
              <div class="partvideotitle1" style="padding-top:0px">
                肖利华 博士
              </div>
              <div class="partvideotitle2">阿里巴巴集团副总裁</div>
              <div class="partvideotitle2">阿里云智能新零售事业部总经理</div>
            </div>
          </div>
          <div class="partvideo">
            <div class="partvideo1">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i2/O1CN01MvwJkJ21WqN63Vt1w_!!6000000006993-2-tps-836-462.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E9%99%B6%E4%BF%8A720.mp4"
              ></video>
            </div>
            <div class="partvideo2">
              <div class="partvideotitle1">陶俊</div>
              <div class="partvideotitle2">
                欧莱雅北亚区兼中国消费者洞察总监
              </div>
            </div>
          </div>
          <div class="partvideo">
            <div class="partvideo1">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i2/O1CN01ApsgSW1DAeOIjrRri_!!6000000000176-2-tps-836-464.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%86%B7%E6%B3%A0720.mp4"
              ></video>
            </div>
            <div class="partvideo2">
              <div class="partvideotitle1">冷泠</div>
              <div class="partvideotitle2">添可智能科技CEO</div>
            </div>
          </div>
        </div>
        <div class="part2">
          <div class="parttitle">Wonder·新增长</div>
        </div>
        <div class="partvideosum">
          <div class="partvideo">
            <div class="partvideo1">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i2/O1CN0122JZQ11xAcgzF6twF_!!6000000006403-2-tps-836-464.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%BC%A0%E8%96%87720.mp4"
              ></video>
            </div>
            <div class="partvideo2">
              <div class="partvideotitle1">张薇</div>
              <div class="partvideotitle2">阿里鱼副总经理、市场营销负责人</div>
            </div>
          </div>
          <div class="partvideo">
            <div class="partvideo1">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i4/O1CN01u6ZAz91plFRCkfyQB_!!6000000005400-2-tps-836-468.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%BC%A0%E6%99%93%E6%B6%931080.mp4"
              ></video>
            </div>
            <div class="partvideo2">
              <div class="partvideotitle1">张晓涓</div>
              <div class="partvideotitle2">
                群邑中国首席产品及服务官
              </div>
            </div>
          </div>
          <div class="partvideo">
            <div class="partvideo1">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i2/O1CN01INCF9a1mqKzSsNgem_!!6000000005005-2-tps-834-460.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%AD%99%E5%B2%A9%E5%B2%A9720.mp4"
              ></video>
            </div>
            <div class="partvideo2">
              <div class="partvideotitle1">孙岩岩</div>
              <div class="partvideotitle2">阿里妈妈营销策略中心负责人</div>
            </div>
          </div>
        </div>
        <div class="part3">
          <div class="parttitle">Wonder·新消费</div>
        </div>
        <div class="partvideosum">
          <div class="partvideo">
            <div class="partvideo11">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i1/O1CN01rD2zP11eAe0k70GfM_!!6000000003831-2-tps-1232-686.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E8%8B%B1%E8%BF%88720.mp4"
              ></video>
            </div>
            <div class="partvideo22">
              <div class="partvideotitle1">英迈</div>
              <div class="partvideotitle2">天猫快速消费品事业部个护总经理</div>
            </div>
          </div>
          <div class="partvideo">
            <div class="partvideo11">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i2/O1CN01wnAgeo1nXqcNheErp_!!6000000005100-2-tps-1236-684.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E7%8E%8B%E5%BF%B5%E6%AC%A7720.mp4"
              ></video>
            </div>
            <div class="partvideo22">
              <div class="partvideotitle1">王念欧</div>
              <div class="partvideotitle2">
                AMIRO创始人兼CEO
              </div>
            </div>
          </div>
        </div>
        <div class="partvideosum" style="margin-top:-80px">
          <div class="partvideo">
            <div class="partvideo11">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i2/O1CN01HDP1hb1YYZ05KYOjy_!!6000000003071-2-tps-1238-686.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%8F%A4%E8%89%AF720.mp4"
              ></video>
            </div>
            <div class="partvideo22">
              <div class="partvideotitle1">古良</div>
              <div class="partvideotitle2">古良吉吉品牌创始人</div>
            </div>
          </div>
          <div class="partvideo">
            <div class="partvideo11">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i1/O1CN01hephTt1pG6VPrMStl_!!6000000005332-2-tps-1238-688.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%A5%A5%E9%92%89720.mp4"
              ></video>
            </div>
            <div class="partvideo22">
              <div class="partvideotitle1">奥钉</div>
              <div class="partvideotitle2">
                聚划算策略中心负责人
              </div>
            </div>
          </div>
        </div>
        <div class="part4">
          <div class="parttitle">Wonder·新生态</div>
        </div>
        <div class="partvideosum">
          <div class="partvideo">
            <div class="partvideo1">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i3/O1CN015k4dsy21Ie5k4eQYV_!!6000000006962-2-tps-836-464.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E9%99%86%E9%80%8A720.mp4"
              ></video>
            </div>
            <div class="partvideo2">
              <div class="partvideotitle1">陆逊</div>
              <div class="partvideotitle2">
                碧橙数字VP、母婴快消事业群总经理
              </div>
            </div>
          </div>
          <div class="partvideo">
            <div class="partvideo1">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i3/O1CN01p1BZFB1hbcBEnVMt6_!!6000000004296-2-tps-838-468.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E7%B1%B3%E7%A7%80%E4%B8%BD720.mp4"
              ></video>
            </div>
            <div class="partvideo2">
              <div class="partvideotitle1">米秀丽</div>
              <div class="partvideotitle2">
                微博营销策略总监
              </div>
            </div>
          </div>
          <div class="partvideo">
            <div class="partvideo1">
              <video
                width="100%"
                controls
                poster="https://img.alicdn.com/imgextra/i2/O1CN01zurANB1iE5S43ffSQ_!!6000000004380-2-tps-834-462.png"
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E9%A9%AC%E5%85%8B720.mp4"
              ></video>
            </div>
            <div class="partvideo2">
              <div class="partvideotitle1">马克</div>
              <div class="partvideotitle2">天字直播中心品牌直播负责人</div>
            </div>
          </div>
        </div>
        <div class="boxsum" id="information">
          <div class="box1">
            <img
              style="width:100%"
              src="https://img.alicdn.com/imgextra/i3/O1CN01g552sS1KYC34xM8qH_!!6000000001175-2-tps-269-103.png"
              alt=""
            />
          </div>
          <div class="span1">
            <div>会议资讯</div>
            <div>INFORMATION</div>
          </div>
        </div>
        <div class="information">
          <div class="informationsum">
            <div class="information1" @click="open1">
              商家未来生意红利在哪？阿里副总裁肖利华说了这三个字
            </div>
          </div>
          <div class="informationsum">
            <div class="information1" @click="open2">
              又一新赛道开启，品牌们的新机会来了
            </div>
          </div>
          <div class="informationsum">
            <div class="information1" @click="open3">
              成立两年，年销13亿，这个新品牌是怎么做到的？
            </div>
          </div>
          <div
            class="informationsum"
            style="border-bottom:1px solid rgb(222, 255, 0)"
          >
            <div class="information1" @click="open4">
              2021电商新趋势玩法和增长机会有哪些？新网商营销大会来了
            </div>
          </div>
        </div>
        <div class="boxsum" id="highlights">
          <div class="box1">
            <img
              style="width:100%"
              src="https://img.alicdn.com/imgextra/i3/O1CN01g552sS1KYC34xM8qH_!!6000000001175-2-tps-269-103.png"
              alt=""
            />
          </div>
          <div class="span1">
            <div>大会集锦</div>
            <div>HIGHLIGHTS</div>
          </div>
        </div>
        <div class="highlights">
          <div class="highlightsum">
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i4/O1CN01h9sbMS1jvQXqm2Z06_!!6000000004610-0-tps-3126-5559.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i4/O1CN01bJ3cus1o8whONOk4W_!!6000000005181-0-tps-3126-5559.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i3/O1CN017wxxiV1oZ3H86Y0Xy_!!6000000005238-0-tps-3126-5559.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i3/O1CN01DmzJck1TJqBmotJP6_!!6000000002362-0-tps-3126-5559.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="highlightsum">
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i4/O1CN01Wogngc1HAt3UGL8YY_!!6000000000718-0-tps-1074-1911.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i1/O1CN01kOdQAd1LqnlZahfnj_!!6000000001351-0-tps-1612-2866.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i3/O1CN01xpetew28EFbEz2L8F_!!6000000007900-0-tps-1612-2866.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i1/O1CN01lVa49M1cpHvSXKAvH_!!6000000003649-0-tps-1612-2866.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="highlightsum">
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i3/O1CN01Q9LmO31uoyk4zXP9B_!!6000000006085-0-tps-1612-2866.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i4/O1CN01VnSh4S1RszLvBAl8Z_!!6000000002168-0-tps-1075-1911.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i4/O1CN01hFkgPm23r784enxhh_!!6000000007308-0-tps-1612-2867.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i4/O1CN01kuoIe21WMzlP60vRO_!!6000000002775-0-tps-1612-2867.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="highlightsum">
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i4/O1CN01E5eNQi1gaPWT6iJKs_!!6000000004158-0-tps-1612-2867.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i4/O1CN01y1VYyj1amQDNm3gOF_!!6000000003372-0-tps-1612-2866.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i1/O1CN01wO2t4u1UjmEN3spnf_!!6000000002554-0-tps-1612-2866.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum1">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i3/O1CN01bS6ntC1k0vITphvbE_!!6000000004622-0-tps-1612-2867.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="highlightsum">
            <div class="highlightsum2">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i4/O1CN01xZTmoD1ftogrRWiZk_!!6000000004065-0-tps-9776-5427.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="highlightsum">
            <div class="highlightsum3">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i4/O1CN01XDgGeu1Qnf87Z8kxR_!!6000000002021-0-tps-750-1334.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum3">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i2/O1CN01dIb43h1UbzV7cQlcE_!!6000000002537-0-tps-750-1334.jpg"
                alt=""
              />
            </div>
            <div class="highlightsum3">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i2/O1CN01tH28c51Pg2vb6Ww1Z_!!6000000001869-0-tps-750-1334.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="highlightsum">
            <div class="highlightsum4">
              <video
                width="100%"
                controls
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%90%89%E5%90%89%E7%BB%88%E7%9A%84%E5%89%AF%E6%9C%AC.m4v"
              ></video>
            </div>
            <div class="highlightsum4">
              <video
                width="100%"
                controls
                src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/amiro1%281%29%E7%9A%84%E5%89%AF%E6%9C%AC.m4v"
              ></video>
            </div>
          </div>
        </div>
        <div class="sponsor" style="margin-top:50px">主办方</div>
        <div class="sponsorimg">
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01lmZvbf1PeDKldOFzZ_!!6000000001865-2-tps-194-68.png"
            alt="天下网商"
          />
        </div>
        <div class="sponsor">合作支持</div>
        <div class="sponsorimg">
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01C9Yopl28KCk3dwQBv_!!6000000007913-2-tps-352-55.png"
            alt="阿里巴巴"
          />
        </div>
        <div class="sponsor">协办</div>
        <div class="sponsorimg1">
          <img
            width="100%"
            src="https://img.alicdn.com/imgextra/i1/O1CN01qO7iJA1LtY8cZtt8q_!!6000000001357-2-tps-2200-704.png"
            alt="阿里巴巴"
          />
        </div>
        <div class="sponsor">活动支持</div>
        <div class="sponsorimg">
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01mVpGZr1zpVGWLtNpV_!!6000000006763-2-tps-196-56.png"
            alt="活动行"
          />
        </div>
        <div class="buttomdiv">
          联系我们
        </div>
        <footer>
          <div id="contact">
            <ul>
              <li>
                <ul>
                  <h1>商务合作：</h1>
                  <h3>院长</h3>
                  <h4>wechat：edison6066</h4>
                  <h4>Email：wb-wds464292@alibaba-inc.com</h4>
                </ul>
              </li>
              <li>
                <ul>
                  <h1>媒体合作：</h1>
                  <h3>Suri</h3>
                  <h4>wechat：88288769</h4>
                  <h4>E-mail：wb-xjd259705@alibaba-inc.com</h4>
                </ul>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    open1() {
      window.open("https://mp.weixin.qq.com/s/VDNMXdXA73jAEfIxf_CMYA");
    },
    open2() {
      window.open("https://mp.weixin.qq.com/s/6GIMVMsYnmtV3HgP17SjCQ");
    },
    open3() {
      window.open("https://mp.weixin.qq.com/s/apuQA0359eC4nMuJetJ_vw");
    },
    open4() {
      window.open("https://mp.weixin.qq.com/s/X7xXnVHmz9X0xc56Cle6WQ");
    },
    buyticket() {
      window.open("http://hdxu.cn/mhuQZ");
    },
    move1() {
      let returnEle = null;
      returnEle = document.querySelector("#home");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move2() {
      let returnEle = null;
      returnEle = document.querySelector("#spoiler");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move3() {
      let returnEle = null;
      returnEle = document.querySelector("#review");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move4() {
      let returnEle = null;
      returnEle = document.querySelector("#information");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move5() {
      let returnEle = null;
      returnEle = document.querySelector("#highlights");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move6() {
      window.location.href =
        "https://www.tianxiawangshang.com/activity/wsfh/wow";
    },
    // handleSelect(index) {
    //   let returnEle = null;
    //   if (index === "1") {
    //     returnEle = document.querySelector("#home");
    //   }
    //   if (index === "2") {
    //     returnEle = document.querySelector("#spoiler");
    //   }
    //   if (index === "3") {
    //     returnEle = document.querySelector("#ticket");
    //   }
    //   if (index === "4") {
    //     returnEle = document.querySelector("#together");
    //   }
    //   if (index === "5") {
    //     returnEle = document.querySelector("#contact");
    //   }
    //   if (returnEle) {
    //     returnEle.scrollIntoView(true);
    //   }
    // },
  },
};
</script>

<style scoped>
.highlights {
  margin: 50px auto 130px;
  width: 1400px;
}
.highlightsum {
  display: flex;
  margin-top: 20px;
}
.highlightsum1 {
  width: 310px;
  height: 550px;
  margin-left: 20px;
}
.highlightsum2 {
  width: 1300px;
  height: 730px;
  margin-left: 20px;
}
.highlightsum3 {
  width: 420px;
  height: 760px;
  margin-left: 20px;
}
.highlightsum4 {
  width: 650px;
  height: 1150px;
  margin-left: 20px;
}
.informationsum {
  border-top: 1px solid rgb(222, 255, 0);
  padding: 30px 0;
  text-align: left;
}
.information {
  margin: 50px auto 130px;
  width: 1400px;
}
.information1 {
  font-size: 30px;
  color: rgb(222, 255, 0);
}
.information1:hover {
  cursor: pointer;
}
.information2 {
  font-size: 35px;
  color: white;
}
.carouselsum {
  display: flex;
  margin-left: 40px;
}
.topcarousel {
  width: 415px;
  height: 275px;
  margin-left: 30px;
}
.carousel {
  margin: 60px auto 120px;
  width: 1450px;
}
.partvideosum {
  margin: 0 auto;
  width: 1400px;
  display: flex;
}
.partvideo {
  margin: 35px auto 80px;
}
.partvideo1 {
  width: 425px;
  height: 240px;
  border: 2px solid rgb(0, 239, 255);
  box-sizing: border-box;
}
.partvideo11 {
  width: 625px;
  height: 350px;
  border: 2px solid rgb(0, 239, 255);
  box-sizing: border-box;
}
.partvideo2 {
  width: 425px;
  height: 100px;
  background-color: rgb(0, 239, 255);
}
.partvideo22 {
  width: 625px;
  height: 100px;
  background-color: rgb(0, 239, 255);
}
.partvideotitle1 {
  padding-top: 15px;
  font-size: 30px;
  font-weight: 550;
  margin-left: 20px;
}
.partvideotitle2 {
  font-size: 17px;
  margin-left: 20px;
}

.buttomdiv {
  margin-top: 200px;
  width: 100%;
  height: 50px;
  background-color: black;
  text-align: center;
  color: white;
  font-size: 26px;
  line-height: 50px;
  letter-spacing: 6px;
}
.ticket {
  display: flex;
  width: 55%;
  margin: 50px auto 0;
}
.ticketimg {
  width: 502px;
  height: 195px;
  border: 3px solid white;
}
.ticketbutton {
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01rzgOWe1h7qQSO0h0r_!!6000000004231-2-tps-502-195.png");
}
.chooseticket {
  width: 200px;
  height: 100px;
  background-color: #ac642a;
  text-align: center;
  line-height: 100px;
  font-size: 40px;
  font-weight: 500;
  color: white;
}
.money {
  width: 400px;
  height: 100px;
  background-color: #f0a35f;
  text-align: center;
  line-height: 100px;
  font-size: 40px;
  font-weight: 500;
  color: blue;
  margin-bottom: 20px;
}
.link {
  width: 220px;
  height: 55px;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01Ho0y8v21Kw3mQQGF1_!!6000000006967-2-tps-217-52.png");
  margin: 0 auto;
  margin-top: 25px;
}
.link > span {
  display: block;
  text-align: center;
  line-height: 50px;
}
.link > span > a {
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  color: white;
  letter-spacing: 6px;
}
.desc {
  color: white;
  text-align: center;
}
.ticket2 {
  margin-left: 120px;
}
.sponsor {
  background-color: rgb(0, 140, 255);
  width: 460px;
  height: 70px;
  margin: 30px auto;
  border-radius: 15px;
  text-align: center;
  line-height: 70px;
  font-size: 40px;
  font-weight: 600;
}
.sponsorimg {
  width: 400px;
  height: 70px;
  margin: 0 auto;
  text-align: center;
}
.sponsorimg1 {
  width: 600px;
  height: 205px;
  margin: 0 auto;
  text-align: center;
}
footer {
  width: 100%;
  margin-top: 50px;
  min-width: 1300px;
  height: 310px;
  color: white;
  box-sizing: border-box;
}

footer > div {
  width: 1175px;

  margin: 0 auto;
}

footer > div > ul {
  width: 1015px;
  height: 175px;
  margin: 0 auto;
}
footer > div > ul > li {
  float: left;
  width: 415xp;
  height: 162px;
  list-style-type: none;
  margin: 0 70px;
}
/* 大会议程 */
.contentsum {
  display: flex;
  align-items: center;
  margin-top: 60px;
}
.pmimg {
  width: 210px;
  height: 210px;
}
.pmtitle {
  margin-left: 30px;
}
.pmtitle1 {
  color: white;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
}
.pmtitle2 {
  color: white;
  font-size: 25px;
  font-weight: 400;
  margin-left: 15px;
}

/* 大会剧透 */
.middle {
  width: 100%;
}
.pianchang {
  margin: 20px auto 0;
  width: 80%;
  color: rgb(0, 207, 255);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pianchang1 {
  margin: -40px auto 0;
  width: 80%;
  color: rgb(0, 207, 255);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pian1 {
  font-size: 30px;
}
.pian2 {
  font-size: 55px;
}
.part1 {
  height: 100px;
  color: white;
  margin: 50px 0 0 19%;
  font-size: 40px;
  font-weight: 500;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01UJvZ6a1dYAjNzSWFL_!!6000000003747-2-tps-360-84.png");
}
.part2 {
  height: 100px;
  color: white;
  margin: 0 0 0 19%;
  font-size: 40px;
  font-weight: 500;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01iIVC4f22uUQSuPFwS_!!6000000007180-2-tps-360-86.png");
}
.part3 {
  height: 100px;
  color: white;
  margin: 0 0 0 19%;
  font-size: 40px;
  font-weight: 500;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i1/O1CN012rWZx21pepsrRDYIi_!!6000000005386-2-tps-361-87.png");
}
.part4 {
  height: 100px;
  color: white;
  margin: 0 0 0 19%;
  font-size: 40px;
  font-weight: 500;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01siW5os1xCSGNKRtDN_!!6000000006407-2-tps-364-84.png");
}
.parttitle {
  margin: 0 0 0 250px;
  padding-top: 55px;
}
.boxsum {
  display: flex;
}
.box2 {
  width: 50px;
  height: 150px;
  background-image: linear-gradient(to bottom right, green, yellow);
  margin-left: 40px;
}
.box3 {
  width: 8%;
  height: 150px;
  background-image: linear-gradient(to bottom right, green, yellow);
  margin-left: 40px;
}
.span1 {
  margin-left: 3%;
}
.span1 div:nth-child(1) {
  color: white;
  font-size: 76px;
  font-weight: 700;
  display: block;
  margin: -20px 0 -5px 0;
}
.span1 div:nth-child(2) {
  color: white;
  font-size: 26px;
  font-weight: 400;
  display: block;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 1300px;
  height: 90px;
  background-color: black;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
header span {
  margin: 0 0 0 0;
}
.menu {
  display: flex;
}
.menuitem {
  width: 180px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 23px;
  font-weight: 500;
  color: white;
}
.menuitem:hover {
  cursor: pointer;
}
.menuitem1 {
  width: 130px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  font-weight: 500;
  margin-left: 20px;
  background-size: cover;
  background-image: url("https://img.alicdn.com/imgextra/i1/O1CN01Fhg8i723L3PGGbQi1_!!6000000007238-2-tps-129-42.png");
}
.menuitem1:hover {
  cursor: pointer;
}
.el-menu-demo {
  margin-right: 5%;
  min-width: 500px;
}
.el-menu-item {
  font-size: 17px;
  font-weight: 500;
}
.el-menu-item:hover {
  background-color: transparent !important;
}
.el-submenu__title:hover {
  background-color: transparent !important;
}
.topimg {
  width: 100%;
  text-align: center;
  background-size: cover;
  background-image: url("https://img.alicdn.com/imgextra/i1/O1CN01QIzdKG1eWAcGf4mY8_!!6000000003878-2-tps-1920-603.png");
}
video {
  width: 100%;
}
.toptitle {
  width: 100%;
  height: 50px;
  background-color: rgb(27, 123, 215);
  text-align: center;
  color: white;
  font-size: 22px;
  line-height: 50px;
  margin-top: -5px;
}
.left {
  height: 750px;
  margin: 90px 0 0 350px;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i1/O1CN01KeLOH31JLXUIpoj3K_!!6000000001012-2-tps-336-546.png");
}
.left p {
  font-size: 24px;
  font-weight: 400;
  color: white;
  margin-left: 300px;
}
section {
  width: 100%;
  min-width: 1500px;
  height: 100%;
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN014dILWA2478z1Xxg4c_!!6000000007343-2-tps-1920-7779.png");
  background-size: cover;
  margin-top: 90px;
}
</style>
