<template>
  <div>
    <div class="top">
      <h1>业务合作</h1>
      <span class="desc">感谢您信任天下网商！</span>
      <span class="desclink">
        <a href="https://tz0pp4t9ht.jiandaoyun.com/f/666c0930dd56799434dad066" target="_blank" >联系我们</a>
      </span>
    </div>

    <div class="contact">
      <div>
        <div class="left1"><span> 媒体合作</span></div>
        <div class="right1">
          <p>联系人：郭庆</p>
          <p>电话:18668218090</p>
          <p>邮箱:wb-gq821130@alibaba-inc.com</p>
        </div>
      </div>
      <div>
        <div class="left1"><span> 营销合作</span></div>
        <div class="right1">
          <p>联系人：朱利丹</p>
          <p>电话:13567180584</p>
          <p>邮箱:wb-zhulidan@alibaba-inc.com</p>
        </div>
      </div>
      <div>
        <div class="left1"><span> 培训咨询</span></div>
        <div class="right1">
          <p>联系人：资源管理</p>
          <p>电话：0571-85022088-79324</p>
          <p>邮箱：txws-fengkong@alibaba-inc.com</p>
        </div>
      </div>
      <div>
        <div class="left1"><span> 投诉反馈 </span></div>
        <div class="right1">
          <p>联系人：天下网商风控部</p>
          <p>电话：0571-85022088-79324</p>
          <p>邮箱：txws-fengkong@alibaba-inc.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.section {
  height: auto;
  min-width: 1285px;
  margin-top: 10px;
}
.top {
  width: 1285px;
  height: 105px;
}
h1 {
  color: #333;
}

.desc {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  color: #333;
}

.desclink{
  color: red;
  font-size: 18px;
  text-decoration: underline;
}
.contact {
  width: 1285px;
  height: 530px;
}
.contact > div {
  width: 550px;
  height: 150px;
  border: 3px solid rgb(250, 250, 250);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 30px 60px 30px 0;
}

.left1 {
  background-color: rgb(216, 17, 28);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
}
.left1 > span {
  font-size: 20px;
  font-weight: blod;
  color: #fff;
  letter-spacing: 4px;
  width: 50px;
  height: 50px;
  display: block;
  padding: 25px;
}
.right1 {
  width: 365px;
  height: 100px;
  padding-top: 20px;
  margin-left: 20px;
}
.right1 p {
  color: #333;
  font-size: 18px;
  font-weight: 400;
  margin: 10px;
}
</style>
