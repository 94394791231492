<template>
  <div>
    <div class="top-banner">
      <el-carousel height="392px">
        <el-carousel-item
          v-for="(item, index) in $store.state.topswiperList"
          :key="index"
        >
          <a :href="item.bannerLink" target="_blank">
            <div class="swiper-title">
              <span>
                {{ item.bannerName }}
              </span>
            </div>
            <img :src="item.image" class="swiper-img"
          /></a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <section class="article-content">
      <div class="article" v-for="(item, index) in articlelist" :key="index">
        <div class="article-left">
          <router-link :to="'/articledetail/' + item.contentId" target="_blank">
            <img :src="item.thumb" style=" width: 220px;height: 146px;"
          /></router-link>
        </div>
        <div class="article-right">
          <ul>
            <li>
              <router-link
                :to="'/articledetail/' + item.contentId"
                target="_blank"
              >
                <h2>{{ item.title }}</h2></router-link
              >
            </li>
            <li>
              <router-link
                :to="'/articledetail/' + item.contentId"
                target="_blank"
              >
                <span>{{ item.intro }}</span></router-link
              >
            </li>
            <li>
              <div class="tag">
                <div class="tag-left">
                  <span v-for="(item, index) in item.tagList" :key="index">
                    <router-link
                      :to="'/taglist/' + item.tagId + '?name=' + item.tagName"
                      target="_blank"
                      >{{ item.tagName }}</router-link
                    >
                  </span>
                </div>
                <div class="tag-right">
                  <img :src="img2" alt="" />
                  <span>{{ item.author }}</span>

                  <span>{{ item.createTime }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <el-pagination
      background
      @prev-click="prevChange"
      @next-click="nextChange"
      @current-change="currentChange"
      :current-page="page"
      :total="newscount"
      layout=" prev, pager, next"
      :hide-on-single-page="value"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getHomeListCount, getHomeList } from "@/api/home";

export default {
  data() {
    return {
      img2: "http://cdnupload.iwshang.com/23-23.png",
      tagname: "",
      newscount: 0,
      articlelist: [],
      page: 1,
      value: true,
    };
  },
  methods: {
    //给sessionStorage存值
    setContextData: function(key, value) {
      if (typeof value == "string") {
        sessionStorage.setItem(key, value);
      } else {
        sessionStorage.setItem(key, JSON.stringify(value));
      }
    },
    // 从sessionStorage取值
    getContextData: function(key) {
      const str = sessionStorage.getItem(key);
      if (typeof str == "string") {
        try {
          return JSON.parse(str);
        } catch (e) {
          return str;
        }
      }
      return;
    },
    // 删除sessionStorage存储的值
    delContextData: function(key) {
      const str = sessionStorage.removeItem(key);
      if (typeof str == "string") {
        try {
          return JSON.parse(str);
        } catch (e) {
          return str;
        }
      }
      return;
    },

    getArticlelist(i) {
      let params = {};
      params.page = i;
      params.pageSize = 11;
      getHomeList(params)
        .then((res) => {
          this.articlelist = res.data.data.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getNewscount() {
      getHomeListCount()
        .then((res) => {
          this.newscount = res.data.data.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    prevChange(val) {
      val--;
      this.getArticlelist(val);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    nextChange(val) {
      val++;
      this.getArticlelist(val);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    currentChange(val) {
      this.getArticlelist(val);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.page = val;
      this.setContextData("currentPage", this.page);
    },
  },

  created() {
    this.page = this.getContextData("currentPage") || 1;
  },
  mounted() {
    this.getArticlelist(this.page);
    this.getNewscount();
  },
};
</script>

<style>
@import "../../../assets/css/common.css";
</style>
