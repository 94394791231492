<template>
  <div class="section">
    <!-- 活动列表 -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="新网商峰会" name="17">
        <div class="first" v-for="(item, index) in activitylist" :key="index">
          <div v-if="index % 2 == 0">
            <div
              class="openLink"
              @click="openImgLink(item.activityLink, item.activityId)"
            >
              <img :src="item.thumb" alt="" />
            </div>
          </div>
          <div class="right" v-if="index % 2 == 0">
            <h3>
              <div
                class="openLink"
                @click="openImgLink(item.activityLink, item.activityId)"
              >
                {{ item.activityName }}
              </div>
            </h3>
            <p>分享嘉宾：</p>
            <p>活动时间：{{ item.activityStartTime }}</p>
            <p>活动地点：{{ item.address }}</p>
            <p class="activityIntro">活动简介：{{ item.activityIntro }}</p>
            <div
              :class="{
                begin: item.activityStatus === '进行中',
                nobegin: item.activityStatus !== '进行中',
              }"
            >
              {{ item.activityStatus }}
            </div>
          </div>

          <div class="right" v-if="index % 2 == 1">
            <h3>
              <div
                class="openLink"
                @click="openImgLink(item.activityLink, item.activityId)"
              >
                {{ item.activityName }}
              </div>
            </h3>
            <p>分享嘉宾：</p>
            <p>活动时间：{{ item.activityStartTime }}</p>
            <p>活动地点：{{ item.address }}</p>
            <p class="activityIntro">活动简介：{{ item.activityIntro }}</p>
            <span>{{ item.activityStatus }}</span>
          </div>
          <div v-if="index % 2 == 1">
            <div
              class="openLink"
              @click="openImgLink(item.activityLink, item.activityId)"
            >
              <img :src="item.thumb" alt="" />
            </div>
          </div>
        </div>
        <el-pagination
          background
          @current-change="currentChange"
          :page-size="5"
          :total="activitycount"
          layout="prev, pager, next"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="创新论坛" name="18">
        <div class="first" v-for="(item, index) in activitylist" :key="index">
          <div v-if="index % 2 == 0">
            <a :href="item.activityLink" target="_blank">
              <img :src="item.thumb" alt=""
            /></a>
          </div>
          <div class="right" v-if="index % 2 == 0">
            <h3>
              <a :href="item.activityLink" target="_blank">{{
                item.activityName
              }}</a>
            </h3>

            <p>分享嘉宾：</p>
            <p>活动时间：{{ item.activityStartTime }}</p>
            <p>活动地点：{{ item.address }}</p>
            <p>活动简介：{{ item.activityIntro }}</p>
            <span>{{ item.activityStatus }}</span>
          </div>

          <div class="right" v-if="index % 2 == 1">
            <h3>
              <a :href="item.activityLink" target="_blank">{{
                item.activityName
              }}</a>
            </h3>

            <p>分享嘉宾：</p>
            <p>活动时间：{{ item.activityStartTime }}</p>
            <p>活动地点：{{ item.address }}</p>
            <p>活动简介：{{ item.activityIntro }}</p>
            <span>{{ item.activityStatus }}</span>
          </div>
          <div v-if="index % 2 == 1">
            <a :href="item.activityLink" target="_blank">
              <img :src="item.thumb" alt=""
            /></a>
          </div>
        </div>
        <el-pagination
          background
          @current-change="currentChange"
          :page-size="5"
          :total="activitycount"
          layout="prev, pager, next"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="行业活动" name="19">
        <div class="first" v-for="(item, index) in activitylist" :key="index">
          <div v-if="index % 2 == 0">
            <a :href="item.activityLink" target="_blank"
              ><img :src="item.thumb" alt=""
            /></a>
          </div>
          <div class="right" v-if="index % 2 == 0">
            <h3>
              <a :href="item.activityLink" target="_blank">{{
                item.activityName
              }}</a>
            </h3>
            <p>分享嘉宾：</p>
            <p>活动时间：{{ item.activityStartTime }}</p>
            <p>活动地点：{{ item.address }}</p>
            <p>活动简介：{{ item.activityIntro }}</p>
            <span>{{ item.activityStatus }}</span>
          </div>

          <div class="right" v-if="index % 2 == 1">
            <h3>
              <a :href="item.activityLink" target="_blank">{{
                item.activityName
              }}</a>
            </h3>
            <p>分享嘉宾：</p>
            <p>活动时间：{{ item.activityStartTime }}</p>
            <p>活动地点：{{ item.address }}</p>
            <p>活动简介：{{ item.activityIntro }}</p>
            <span>{{ item.activityStatus }}</span>
          </div>
          <div v-if="index % 2 == 1">
            <a :href="item.activityLink" target="_blank">
              <img :src="item.thumb" alt=""
            /></a>
          </div>
        </div>
        <el-pagination
          background
          @current-change="currentChange"
          :page-size="5"
          :total="activitycount"
          layout="prev, pager, next"
        >
        </el-pagination>
        <!-- <el-pagination
          background
          @prev-click="prevChange"
          @next-click="nextChange"
          @current-change="currentChange"
          :current-page="page"
          :total="activitycount"
          layout=" prev, pager, next,total"
        > -->
        <!-- </el-pagination> -->
      </el-tab-pane>
    </el-tabs>

    <!-- 活动报道 -->
    <div class="report">
      <div>
        <h3>活动报道</h3>
      </div>
      <div class="swiper">
        <UL>
          <LI v-for="(item, index) in activityreportlist" :key="index">
            <router-link :to="'/articledetail/' + item.contentId"
              ><img :src="item.thumb" alt="" /><P>{{
                item.title
              }}</P></router-link
            ></LI
          ></UL
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getActivityCount, getActivity, getReportList } from "@/api/activity";
export default {
  name: "Section",
  data() {
    return {
      activeName: "17",
      page: 1,
      activitycount: 0,
      activitylist: [],
      activityreportlist: [],
    };
  },
  methods: {
    openImgLink(activityLink, activityId) {
      if (
        activityId === 120 ||
        activityId === 119 ||
        activityId === 117 ||
        activityId === 116 ||
        activityId === 8
      ) {
        window.open(activityLink);
      }
    },
    handleClick() {
      this.getActivitylist(this.page);
    },

    getActivitylist(i) {
      let params = {};
      params.page = i;
      params.pageSize = 5;
      params.columnId = this.activeName;

      getActivity(params).then((res) => {
        this.activitylist = res.data.data.result;
      });
      getActivityCount(params).then((res) => {
        this.activitycount = res.data.data.result;
        console.log(this.activitycount);
      });
    },

    getActivitycount() {
      let params = {};
      params.columnId = this.activeName;
      getActivityCount(params).then((res) => {
        this.activitycount = res.data.data.result;
      });
    },
    prevChange(val) {
      val--;
      this.getActivitylist(val);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    nextChange(val) {
      val++;
      this.getActivitylist(val);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    currentChange(val) {
      this.getActivitylist(val);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    getReport() {
      let params = {};
      params.page = 1;
      params.columnId = 26;
      params.pageSize = 10000;

      getReportList(params).then((res) => {
        this.activityreportlist = res.data.data.result;
      });
    },
  },

  mounted() {
    this.getActivitylist(this.page);
    // this.getActivitycount();
    this.getReport();
  },
};
</script>

<style scoped>
.openLink {
  cursor: pointer;
}
.section {
  width: 1190px;
  margin: 0 auto;
}
.first {
  display: flex;
  height: 340px;
}
.first img {
  width: 595px;
  height: 340px;
}
.first .right {
  background-color: rgb(245, 250, 253);
  width: 595px;
  height: 340px;
  position: relative;
}
.first h3 {
  line-height: 30px;
  padding-left: 20px;
  padding-bottom: 10px;
}

.first h3 > div {
  color: #444;
  font-size: 24px;
}
.first h3 > a {
  color: #444;
  font-size: 24px;
}
.first p {
  font-size: 14px;
  color: #666464;
  line-height: 14px;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
}
.activityIntro {
  line-height: 24px !important;
}
.first span {
  display: block;
  width: 130px;
  height: 40px;
  background-color: #bcbcbc;
  text-align: center;
  color: white;
  font-size: 20px;
  line-height: 40px;
  position: absolute;
  bottom: 15px;
  left: 38%;
}
.begin {
  display: block;
  width: 130px;
  height: 40px;
  background-color: #64afec;
  text-align: center;
  color: white;
  font-size: 20px;
  line-height: 40px;
  position: absolute;
  bottom: 15px;
  left: 38%;
}
.nobegin {
  display: block;
  width: 130px;
  height: 40px;
  background-color: #bcbcbc;
  text-align: center;
  color: white;
  font-size: 20px;
  line-height: 40px;
  position: absolute;
  bottom: 15px;
  left: 38%;
}
.report {
  height: 500px;
  margin-top: 0;
  margin-bottom: 100px;
}

.report h3 {
  background-color: #db0b2e;
  font-size: 18px;
  color: #ffffff;
  height: 40px;
  line-height: 34px;
  display: inline-block;
  padding: 0 15px;
}
.swiper {
  margin-top: 10px;
  width: 1160px;
  height: 430px;
  overflow-x: auto;
}
.swiper ul {
  width: 1100px;
}
.swiper ul li {
  float: left;
  width: 190px;
  height: 180px;
  margin: 12px;
  list-style-type: none;
}
.swiper img {
  display: block;
  width: 190px;
  height: 115px;
}
.swiper p {
  background-color: #ececec;
  font-size: 12px;
  line-height: 20px;
  height: 65px;
  width: 170px;
  padding: 5px 10px 0px;
  color: #010101;
  overflow: hidden;
}
.el-pagination {
  /* padding: 33px 144px 33px 200px; */
  /* margin-left: 300px; */
  text-align: center;
  padding-top: 33px;
}
</style>
