<template>
  <div class="blue_wrapper" id="app" :class="{ background: !isVIP }">
    <header>
      <div class="page-top">
        <div class="top-bar">
          <h1 style="font-size:0.5rem">
            {{ productName }}<span>商家报名表</span>
          </h1>
        </div>
      </div>
      <!-- <div class="page-top">
        <div class="top-bar">
          <div class="toptext1">{{ productName }}</div>
          <div class="toptext2">商家报名表</div>
        </div>
      </div> -->
    </header>
    <article
      :style="{ border: !isVIP ? '80px solid #644a8e' : '4px solid #fed887' }"
    >
      <el-form
        label-position="top"
        ref="form"
        :model="form"
        label-width="8px"
        :rules="rules"
      >
        <div class="toptitle">
          各位商家及客户您好，本公司为了向您提供服务，需要收集如下个人信息，本公司承诺以下信息仅用于本公司后续向您提供具体服务，绝不向第三方披露或作为其他用途，经过您本人同意或因政策及其他法律法规规定之情形除外。当您填写个人信息，即表示您已充分阅读、理解且接受本条款的全部内容，并与本公司达成一致。
        </div>
        <!-- <div class="el-form-item__content">
            <el-form-item label="成员行业：">
              <el-radio-group
                v-model="form.menberProfession"
                size="small"
                @change="init()"
              >
                <el-radio
                  :label="k.value"
                  border
                  v-for="(k, v) in menberProfession"
                  :key="v"
                  >{{ k.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </div> -->

        <el-divider content-position="left">基本信息</el-divider>

        <el-form-item
          :label="`${i + 1}、${item.questionTitle}`"
          v-for="(item, i) in formData || []"
          :key="i"
          :prop="item.required === 'TRUE' && item.questionField"
        >
          <el-input
            v-model="form[item.questionField]"
            size="mini"
            v-if="item.questionType === 'INPUT'"
            :placeholder="`请输入${item.showTitle}`"
          ></el-input>

          <el-input
            v-model="form[item.questionField]"
            size="mini"
            v-if="item.questionType === 'TEXTAREA'"
            :placeholder="`请输入${item.showTitle}`"
            type="textarea"
          ></el-input>

          <div
            class="el-form-item__content"
            v-else-if="item.questionType === 'CHECKBOX'"
          >
            <el-checkbox-group v-model="form[item.questionField]">
              <el-checkbox
                :label="k.optionTitle"
                v-for="(k, v) in item.additionList"
                :key="v"
              >
              </el-checkbox>
            </el-checkbox-group>
          </div>

          <div
            class="el-form-item__content"
            v-else-if="item.questionType === 'RADIO'"
          >
            <el-radio-group v-model="form[item.questionField]" size="mini">
              <el-radio
                :label="k.optionTitle"
                border
                v-for="(k, v) in item.additionList || []"
                :key="v"
              ></el-radio>
            </el-radio-group>
          </div>

          <!-- 其他- 身份载体 特殊处理 -->
          <div v-else-if="item.questionType === 'CHECKBOX_INPUT'">
            <el-form-item
              :label="k.optionTitle"
              v-for="(k, v) in item.additionList || []"
              :key="v"
              class="active_input"
            >
              <el-input
                type="password"
                v-model="checkbox_input[k.questionId]"
                size="mini"
              ></el-input>
            </el-form-item>
          </div>
        </el-form-item>

        <!-- <el-divider content-position="left">选择开票信息</el-divider> -->
        <el-divider content-position="left"></el-divider>
        <!-- 静态表单 -->
        <!-- <el-form-item label="开票信息" class="el-form-item__content"> -->
        <el-form-item label="" class="el-form-item__content">
          <el-radio-group
            v-model="form.signWay"
            size="mini"
            @change="$refs['form'].clearValidate()"
          >
            <el-radio-button label="INDIVIDUAL">个人签约</el-radio-button>
            <el-radio-button label="COMPANY">法人签约</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <div v-if="form.signWay === 'INDIVIDUAL'">
          <el-form-item label="签约人" prop="signer">
            <el-input
              v-model="form.signer"
              placeholder="请输入签约人"
              size="mini"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idCardNumber">
            <el-input
              v-model="form.idCardNumber"
              placeholder="请输入身份证号"
              size="mini"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="contact">
            <el-input
              v-model="form.contact"
              placeholder="请输入联系电话"
              size="mini"
            ></el-input>
          </el-form-item>
          <el-form-item label="付款人" prop="payer">
            <el-input
              v-model="form.payer"
              placeholder="请输入付款人"
              size="mini"
            ></el-input>
          </el-form-item>
          <el-form-item label="下单份数" prop="orderNum">
            <el-input
              v-model="form.orderNum"
              placeholder="请输入下单份数"
              size="mini"
              @input="calclateAmount()"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="总金额" prop="totalPrice">
            <el-input v-model="form.totalPrice" size="mini"></el-input>
          </el-form-item>
        </div>

        <div v-else>
          <el-form-item label="企业名称" prop="companyName">
            <el-input
              v-model="form.companyName"
              placeholder="请输入企业名称"
              size="mini"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="签约人" prop="signer">
              <el-input
                v-model="form.signer"
                placeholder="请输入签约人"
                size="mini"
              ></el-input>
            </el-form-item> -->
          <!-- <el-form-item label="身份证号" prop="idCardNumber">
              <el-input
                v-model="form.idCardNumber"
                placeholder="请输入身份证号"
                size="mini"
              ></el-input>
            </el-form-item> -->
          <el-form-item label="联系电话" prop="contact">
            <el-input
              v-model="form.contact"
              placeholder="请输入联系电话"
              size="mini"
            ></el-input>
          </el-form-item>
          <el-form-item label="付款方" prop="payer">
            <el-input
              v-model="form.payer"
              placeholder="请输入付款方"
              size="mini"
            ></el-input>
          </el-form-item>
          <el-form-item label="下单份数" prop="orderNum">
            <el-input
              v-model="form.orderNum"
              placeholder="请输入下单份数"
              size="mini"
              @input="calclateAmount()"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
            <el-form-item label="总金额" prop="totalPrice">
              <el-input v-model="form.totalPrice" size="mini"></el-input>
            </el-form-item>
          </el-form-item>
        </div>

        <div
          v-if="routerId !== '415' && routerId !== '416' && routerId !== '417'"
        >
          <el-checkbox
            v-model="checked"
            size="small"
            style="margin-right: 10px"
          ></el-checkbox>
          <span
            style="font-size: 12px; color: #606266; cursor: pointer;"
            @click="showDialog"
            >我同意<i style="font-style: normal;color: #3C9FE8"
              >《"{{ productName }}"合作协议》(点击查看)</i
            >规定的内容并开始接受服务</span
          >
        </div>

        <el-form-item class="footer">
          <el-button
            class="btmbutton"
            type="primary"
            @click="onSubmit"
            :style="{ background: !isVIP ? '#644a8e' : '#ff6144' }"
            :disabled="!waitsummit"
            >提交报名</el-button
          >
        </el-form-item>
      </el-form>
    </article>

    <el-dialog title="" :visible.sync="dialogVisible" width="80%">
      <!-- <h3 style="text-align: center;font-size: 18px;margin-bottom: 20px;">合同详情</h3> -->
      <div v-html="agreement"></div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        var reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      price: null,
      agreement: null,
      dialogVisible: false,
      checked: false,
      productId: null,
      productName: null,
      waitsummit: true,
      routerId: null,
      formData: [],
      menberProfession: [
        { label: "主播", value: "ANCHOR" },
        { label: "商家", value: "MERCHANT" },
        { label: "机构", value: "ORGANIZATION" },
        { label: "供应链", value: "SUPPLY_CHAIN" },
        { label: "其他", value: "OTHER" },
      ],
      checkbox_input: {},
      isVIP: true,
      form: {
        menberProfession: "ANCHOR",
        signWay: "INDIVIDUAL",
        companyName: "",
        signer: "",
        contact: "",
        idCardNumber: "",
        payer: "",
        orderNum: "",
        totalPrice: "",
      },
      rules: {
        contact: [{ validator: checkPhone, trigger: "blur", required: true }],
        idCardNumber: [
          {
            required: true,
            message: "请输入身份证号码",
            trigger: "blur",
          },
          {
            pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: "请输入正确的身份证号",
            trigger: "blur",
          },
        ],
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        orderNum: [
          { required: true, message: "请输入下单份数", trigger: "blur" },
        ],
        payer: [
          { required: true, message: "请输入付款人/方", trigger: "blur" },
        ],
        signer: [{ required: true, message: "请输入签约人", trigger: "blur" }],
      },
    };
  },
  methods: {
    calclateAmount() {
      this.form.totalPrice = this.price * this.form.orderNum;
    },
    showDialog() {
      this.dialogVisible = true;
    },
    onSubmit() {
      if (
        !this.checked &&
        this.routerId !== "415" &&
        this.routerId !== "416" &&
        this.routerId !== "417"
      ) {
        this.$message.warning("请先阅读合同条款！");
        return false;
      }
      this.form.Identity_account = [];
      this.form.questionList = [
        {
          questionId: 1,
          answer: this.getMenberProfession(this.form.menberProfession),
        },
        {
          questionId: 23,
          answer: this.productId === 210 ? "会员" : "理事",
        },
      ];

      if (this.form.menberProfession === "OTHER") {
        Object.keys(this.checkbox_input).forEach((item) => {
          if (this.checkbox_input[item]) {
            var obj = {
              questionId: item,
              answer: this.checkbox_input[item],
            };
            this.form.questionList.push(obj);
            this.form.Identity_account && this.form.Identity_account.push(obj);
          }
        });
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          var arr = [];
          Object.keys(this.form).forEach((item) => {
            var flag = this.formData.find((k) => k.questionField === item);
            if (flag) {
              var obj = {
                questionId: flag.questionId,
                answer: this.form[item].toString(),
              };
              arr.push(obj);
            }
          });
          if (this.form.signWay === "COMPANY") {
            this.form.signer = this.form.companyName;
          }
          var params = {
            shopId: this.form.shop_id,
            shopName: this.form.shop_name,
            companyName: this.form.companyName,
            idCardNumber: this.form.idCardNumber,
            menberProfession: this.form.menberProfession,
            payer: this.form.payer,
            orderNum: this.form.orderNum, // default
            productId: this.productId,
            questionList: [...this.form.questionList, ...arr],
            signWay: this.form.signWay,
            signer: this.form.signer,
            contact: this.form.contact,
          };
          this.waitsummit = false;
          var self = this;
          this.$https
            .post(this.host + "/api/onlineApply/payCommit", params)
            .then(function(response) {
              if (+response.data.statusCode === 1000) {
                self.$message.success("操作成功~");
                setTimeout(() => {
                  self.$refs["form"] && self.$refs["form"].resetFields();
                }, 4);

                // var href = self.isVIP
                //   ? "https://detail.tmall.com/item.htm?spm=a212k0.12153887.0.0.3099687dTi4zOM&id=599888634044"
                //   : "https://detail.tmall.com/item.htm?id=599456728077";
                // window.location.reload();
                // 跳转付款链接  ------- start ------
                var responseHtml = response.data.data.result;
                var tempElement = document.createElement("div");
                tempElement.innerHTML = responseHtml;
                document.body.innerHTML = responseHtml;
                var scriptHTML = tempElement.getElementsByTagName("script")[0]
                  .innerHTML;
                var scriptElement = document.createElement("script");
                scriptElement.innerHTML = scriptHTML;
                document.body.appendChild(scriptElement);
                // 跳转付款链接  ------- end ------
              }
            })
            .catch(function(error) {
              self.$message.error(`操作失败，原因${error.config}`);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    init() {
      var self = this;
      //   var menberProfession = this.form.menberProfession || "NONE";
      this.$https
        .get(
          this.host +
            `/api/product/question/list?menberProfession=NONE&productId=${this.productId}`
        )
        .then(function(res) {
          var data = res.data.data.questionList;
          var arr = ["CHECKBOX", "CHECKBOX_INPUT"];
          data.forEach((item) => {
            var val = arr.includes(item.questionType) ? [] : "";
            self.$set(self.form, item.questionField, val);
            // CHECKBOX_INPUT做特殊处理
            if (item.questionType === "CHECKBOX_INPUT") {
              item.additionList.forEach((k) => {
                self.$set(self.checkbox_input, k.questionId, "");
              });
            }
          });
          self.formData = data;
          self.createRules();

          self.$nextTick(() => {
            self.$refs["form"].clearValidate();
          });
        });
    },
    createRules() {
      this.formData.forEach((item) => {
        if (item.required === "TRUE") {
          var msg = item.questionType === "INPUT" ? "输入" : "选择";
          var trigger = item.questionType === "INPUT" ? "blur" : "change";
          var obj = {
            required: true,
            message: `请${msg}${item.showTitle}`,
            trigger: trigger,
          };
          this.$set(this.rules, item.questionField, obj);
        }
      });
    },
    getMenberProfession(key) {
      var item = this.menberProfession.find((item) => {
        return item.value === key;
      });
      return item.label;
    },
    getParamValue(url, key) {
      var regex = new RegExp(key + "=([^&]*)", "i");
      return url.match(regex)[1];
    },
    getAgreement() {
      var self = this;
      this.$https
        .get(this.host + `/api/product/detail?productId=${this.productId}`)
        .then(function(res) {
          self.agreement = res.data.data.productContract;
          self.productName = res.data.data.productName;
          document.title = res.data.data.productName;
          self.price = res.data.data.price;
          if (self.productId == 278) {
            self.form.orderNum = 1;
            self.form.totalPrice = self.form.orderNum * self.price;
          }
        });
    },
    setRem() {
      const baseSize = 75;
      // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
      const scale = document.documentElement.clientWidth / 750;
      // 设置页面根节点字体大小
      document.documentElement.style.fontSize =
        baseSize * Math.min(scale, 2) + "px";
    },
  },
  created() {
    // 210 会员 211理事
    this.host = process.env.VUE_APP_TOP || "";
    this.productId = this.$route.params.id;
    this.isVIP = true;
    this.routerId = this.$route.params.id;
    this.setRem();
    this.init();
    this.getAgreement();
  },
  mounted() {
    window.onresize = () => {
      //调用methods中的事件
      this.setRem();
    };
  },
};
</script>

<style scoped>
.toptitle {
  font-size: 12px;
  padding-bottom: 25px;
  font-weight: 600;
}
.el-divider {
  font-size: 15px;
  margin-bottom: 25px;
}
header {
  width: 100%;
  height: 280px;
}
header img {
  width: 100%;
  height: 100%;
}
.blue_wrapper {
  /* background: url(../imgs/art2.png); */
  background-size: 100% auto;
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 1.533333rem;
}
.blue_wrapper article {
  background: #ffffff;
  width: 9.066667rem;
  margin: 0 auto;
  border: 4px solid #654693;
  height: auto;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 24px;
}
.blue_wrapper article form {
  padding: 0.25rem 0.413333rem;
  box-sizing: border-box;
}
div/deep/.el-form-item__label {
  display: block !important;
  float: none !important;
  width: 100% !important;
  text-align: left !important;
  line-height: 25px !important;
}
div/deep/.el-form-item__content {
  margin-left: 0 !important;
  display: block;
}
div/deep/.el-checkbox {
  margin-left: 0 !important;
  font-size: 12px !important;
  font-weight: normal !important;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.footer button {
  font-family: "AlibabaPuHuiTiR";
  font-weight: normal;
  color: #fffefc;
  width: 4.88rem;
  height: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
div/deep/.el-form-item {
  margin-bottom: 0.2rem !important;
}

div/deep/.el-radio.is-bordered + div/deep/.el-radio.is-bordered {
  margin-left: 0 !important;
  margin-bottom: 10px;
}
div/deep/.el-form-item__error {
  padding-top: 0 !important;
}
div/deep/.el-button--primary {
  border: none !important;
}
.active_input {
  display: flex;
}
.active_input div/deep/.el-form-item__label {
  width: 1.5rem !important;
  float: left !important;
  display: flex !important;
  align-items: center;
}
.active_input div/deep/.el-form-item__content {
  flex: 1;
}
.background {
  /* background: url(../imgs/art1.png) !important; */
}

div/deep/.el-radio {
  margin-right: 0.333333rem !important;
}
[v-cloak] {
  display: none;
}
.bg {
  width: 100%;
  height: 350px;
  background-image: url("https://img.alicdn.com/tfs/TB1RPoyrACWBuNjy0FaXXXUlXXa-1920-350.jpg");
  background-size: cover;
  background-position: center center;
  /* height: 5rem; */
}

.page-top {
  width: 100%;
  height: 350px;
  background-image: url(https://img.alicdn.com/tfs/TB1RPoyrACWBuNjy0FaXXXUlXXa-1920-350.jpg);
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: 1px solid #ededed;
}
.page-top .top-bar {
  width: 1000px;
  height: 250px;
  margin: 0 auto;
  display: table;
  text-align: center;
}
.page-top .top-bar h1 {
  display: table-cell;
  vertical-align: middle;
  line-height: 1.2;
  font-size: 84px;
  color: #f6f6f6;
  font-weight: 700;

  text-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
}
.page-top .top-bar span {
  padding: 5px 0;
  margin: 20px auto 0;
  color: 24px;
  font-size: 24px;
  display: block;
  width: 500px;
  background-color: #4b4053;
  letter-spacing: 50px;
  font-weight: 100;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2) {
  header {
    height: 5.6rem;
  }
  .page-top {
    width: 100%;
    height: 5rem;
    background-size: cover;
  }
  .page-top .top-bar {
    width: 100%;
    height: 5rem;
  }
  .page-top .top-bar h1 {
    font-size: 76px;
    display: inline-block;
    font-size: 1rem;
    margin-top: 1.5rem;
  }
  .page-top .top-bar h1 span {
    width: 80%;
    letter-spacing: 0.2rem;
    font-size: 0.5rem;
    font-weight: 500;
  }
  .footer button {
    height: 1rem;
  }
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* sdasdsa */
/* .footer button {
  font-family: "AlibabaPuHuiTiR";
  font-weight: normal;
  color: #fffefc;
  width: 700px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 20px auto;
  font-size: 40px;
  letter-spacing: 20px;
}
div/deep/.el-form-item__label {
  display: block !important;
  float: none !important;
  width: 100% !important;
  text-align: left !important;
  line-height: 25px !important;
} */
/* .toptext1 {
  font-size: 65px;
  font-weight: 700;
  color: white;
  padding-top: 50px;
}
.toptext2 {
  font-size: 55px;
  font-weight: 400;
  color: white;
  width: 400px;
  height: 100px;
  line-height: 100px;
  background-color: #4b4053;
  margin: 0 auto;
  letter-spacing: 15px;
  text-align: center;
  margin-top: 20px;
} */
/* .page-top {
  width: 100%;
  min-width: 1300px;
  height: 350px;
  background-image: url(https://img.alicdn.com/tfs/TB1RPoyrACWBuNjy0FaXXXUlXXa-1920-350.jpg);
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: 1px solid #ededed;
  text-align: center;
} */
/* article {
  background: #ffffff;
  width: 1280px;
  margin: 80px auto;
  border: 4px solid #654693;
  height: auto;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 24px;
}
article form {
  padding: 50px 80px;
  box-sizing: border-box;
} */
</style>
