<template>
  <div class="bcgcolor">
    <section>
      <div class="top">
        <img
          src="https://img.alicdn.com/imgextra/i4/O1CN015scOQB1YxINfyQ9lv_!!6000000003125-2-tps-1169-732.png"
          alt=""
        />
        <div class="headimg" @click="gobuy">
          <img
            width="220%"
            src="https://img.alicdn.com/imgextra/i2/O1CN01k47HFO1pkKdjNZDBM_!!6000000005398-2-tps-913-184.png"
            alt=""
          />
        </div>
      </div>
      <div class="main1">
        <img
          src="https://img.alicdn.com/imgextra/i4/O1CN01dju3EJ1NPtj0FUyOs_!!6000000001563-2-tps-1356-817.png"
          alt=""
        />
      </div>
      <div class="main2">
        <img
          src="https://img.alicdn.com/imgextra/i2/O1CN01MArDHp1CzCTs34jUt_!!6000000000151-2-tps-1213-762.png"
          alt=""
        />
      </div>
      <div class="main3">
        <img
          src="https://img.alicdn.com/imgextra/i1/O1CN01ukjtVx268dVTok0l7_!!6000000007617-2-tps-1365-892.png"
          alt=""
        />
      </div>
      <div class="main4" id="buy">
        <div class="buysum">
          <img
            src="https://img.alicdn.com/imgextra/i3/O1CN01uVlwAq1enZkI8OhPz_!!6000000003916-2-tps-1180-835.png"
            alt=""
          />
          <!-- <div class="buy" @click="buy">立即订购</div> -->
        </div>
      </div>
      <div class="main6">
        <img
          src="https://img.alicdn.com/imgextra/i1/O1CN01eyILcK1oLlmWvhkMW_!!6000000005209-2-tps-1211-514.png"
          alt=""
        />
      </div>
      <div class="main7">
        <img
          src="https://img.alicdn.com/imgextra/i1/O1CN01EFy1e51yOeRqNPLid_!!6000000006569-2-tps-1211-543.png"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    gobuy() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/252");
    },
  },
};
</script>

<style scoped>
.bcgcolor {
  background-color: white;
}
.carousel-control.left {
  background-color: aqua;
}
.allvideo {
  margin-top: 20px;
  display: flex;
}
.onevideo {
  width: 225px;
  height: 720px;
  background-color: white;
  margin-left: 30px;
  position: relative;
}
.title {
  width: 140px;
  height: 35px;
  background-color: rgb(251, 199, 1);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 35px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 40px;
  z-index: 999;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.el-carousel {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 170px;
}
.buy {
  width: 110px;
  height: 50px;
  background-color: rgb(234, 59, 30);
  color: white;
  position: absolute;
  bottom: 37%;
  left: 77.5%;
  line-height: 50px;
  border-radius: 30px;
  font-size: 20px;
  border: 2px solid black;
}
.buy:hover {
  cursor: pointer;
}
.buysum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.top {
  text-align: center;
  position: relative;
}
.main1 {
  text-align: center;
  padding-top: 80px;
  margin-left: 40px;
}
.main2 {
  text-align: center;
  margin-top: 75px;
  margin-left: 35px;
}
.main3 {
  text-align: center;
  margin-top: 75px;
  margin-right: 35px;
}
.main4 {
  text-align: center;
  margin-top: 75px;
}
.main5 {
  text-align: center;
  margin-top: 75px;
}
.main6 {
  text-align: center;
  margin-top: 75px;
  margin-right: 25px;
}
.main7 {
  text-align: center;
  margin-top: 75px;
  margin-right: 25px;
  padding-bottom: 100px;
}
section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01prSHkC1lbqqDfMgJT_!!6000000004838-2-tps-1920-6000.png");
  background-size: cover;
  margin: 0 auto;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
.headimg {
  position: absolute;
  width: 400px;
  bottom: 0%;
  left: 35%;
  transform: translateX(-50%);
}
.headimg:hover {
  cursor: pointer;
}
</style>
