<template>
  <div class="bcgcolor">
    <header>
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i2/O1CN01nCfPDn1u3nwEfwJ0r_!!6000000005982-2-tps-1920-695.png"
        alt=""
      />
      <div class="headimg" @click="gobuy">
        <img
          width="90%"
          src="https://img.alicdn.com/imgextra/i1/O1CN01Hjwnle1M3cqRv1GuT_!!6000000001379-2-tps-448-95.png"
          alt=""
        />
      </div>
    </header>
    <section>
      <!-- <div class="main7">
        <img
          src="https://img.alicdn.com/imgextra/i4/O1CN01bAcBN026a7H7Hq8gz_!!6000000007677-2-tps-1178-647.png"
          alt=""
        />
      </div> -->
      <div class="main1">
        <img
          src="https://img.alicdn.com/imgextra/i4/O1CN018yBYs81gI5jR1lwo5_!!6000000004118-2-tps-1179-2559.png"
          alt=""
        />
      </div>
      <div class="main2">
        <img
          src="https://img.alicdn.com/imgextra/i4/O1CN01gwNTwI27roBXvof4S_!!6000000007851-2-tps-1122-820.png"
          alt=""
        />
      </div>
      <div class="main3">
        <img
          src="https://img.alicdn.com/imgextra/i2/O1CN012pvt4x1buxGSIUH6l_!!6000000003526-2-tps-1122-1035.png"
          alt=""
        />
      </div>
      <div class="main4">
        <div class="buysum">
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN019NG4j31yE7OSNYKSF_!!6000000006546-2-tps-1122-1239.png"
            alt=""
          />
          <div id="buy"></div>
          <!-- <div class="buy" @click="buy">立即订购</div> -->
          <div class="buy1" @click="buy1">立即订购</div>
        </div>
      </div>
      <div class="main5">
        <div class="videosum">
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN015XzGZg23K8boGJ0al_!!6000000007236-2-tps-1122-1262.png"
            alt=""
          />
          <el-carousel
            class="carousel1"
            indicator-position="outside"
            trigger="click"
            arrow="always"
            :autoplay="false"
            height="100%"
          >
            <el-carousel-item>
              <div class="allvideo">
                <div class="onevideo" style="margin-left:60px">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/783537643/p/1/e/6/t/1/305094114416.mp4"
                  ></video>
                  <div class="title">花式吃法</div>
                  <div class="bttitle">无人物出镜，专注拍摄美食本身</div>
                </div>
                <div class="onevideo">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/351149007/p/1/e/6/t/1/304136776723.mp4"
                  ></video>
                  <div class="title">好物分享</div>
                  <div class="bttitle">咖啡卖点讲解试喝</div>
                </div>
                <div class="onevideo">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/1891132294/p/1/e/6/t/1/303376144102.mp4"
                  ></video>
                  <div class="title">家居分享</div>
                  <div class="bttitle">四件套床品配合场景展示</div>
                </div>
                <div class="onevideo">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/655317410/p/1/e/6/t/1/305196112860.mp4"
                  ></video>
                  <div class="title">口播讲解</div>
                  <div class="bttitle">口播无实物讲解</div>
                </div>
                <div class="onevideo">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/905029907/p/1/e/6/t/1/298937829261.mp4"
                  ></video>
                  <div class="title">评测种草</div>
                  <div class="bttitle">护肤品亲测对比种草</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="allvideo">
                <div class="onevideo" style="margin-left:60px">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/3069937192/p/1/e/6/t/1/306818429301.mp4"
                  ></video>
                  <div class="title">护肤分享</div>
                  <div class="bttitle">个人护肤好物分享</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <el-carousel
            class="carousel2"
            indicator-position="outside"
            trigger="click"
            arrow="always"
            :autoplay="false"
            height="100%"
          >
            <el-carousel-item>
              <div class="allvideo">
                <div class="onevideo" style="margin-left:60px">
                  <video
                    controls
                    poster="https://img.alicdn.com/imgextra/i2/O1CN01jR2GMi1aGMUIMjlQF_!!6000000003302-0-tps-450-800.jpg"
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/283601870/p/1/e/6/t/1/257943930804.mp4"
                  ></video>
                  <div class="title1">美妆试色</div>
                  <div class="bttitle">口红详细试色体验</div>
                </div>
                <div class="onevideo">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/3069937192/p/1/e/6/t/1/307824054708.mp4"
                  ></video>
                  <div class="title1">服装试穿</div>
                  <div class="bttitle">室外场景试穿拍摄</div>
                </div>
                <div class="onevideo">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/1988138835/p/1/e/6/t/1/283266767114.mp4"
                  ></video>
                  <div class="title1">母婴产品</div>
                  <div class="bttitle">童模出镜，丰富内容</div>
                </div>
                <div class="onevideo">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/2965546536/p/1/e/6/t/1/299282402609.mp4"
                  ></video>
                  <div class="title1">开箱评测</div>
                  <div class="bttitle">开箱亲测体验，分享使用感受</div>
                </div>
                <div class="onevideo">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/1954697454/p/1/e/6/t/1/299734299398.mp4"
                  ></video>
                  <div class="title1">评测教程</div>
                  <div class="bttitle">详细演示使用步骤和效果</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="allvideo">
                <div class="onevideo1" style="margin-left:60px">
                  <video
                    controls
                    width="100%"
                    height="303px"
                    src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%85%83%E6%97%A6%E5%A7%90%E5%A7%90%2BCT%E7%9C%BC%E5%BD%B1.mp4"
                  ></video>
                  <div class="title1">美妆教程</div>
                  <div class="bttitle">详细美妆教学步骤演示</div>
                </div>
                <div class="onevideo">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/2985450414/p/1/e/6/t/1/292906641895.mp4"
                  ></video>
                  <div class="title1">服饰搭配</div>
                  <div class="bttitle">搭配不同服装展示</div>
                </div>
                <div class="onevideo">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/739718346/p/1/e/6/t/1/285662562841.mp4"
                  ></video>
                  <div class="title1">护肤评测</div>
                  <div class="bttitle">卖点详解，使用步骤演示</div>
                </div>
                <div class="onevideo">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/678595113/p/1/e/6/t/1/291186319515.mp4"
                  ></video>
                  <div class="title1">亲测详解</div>
                  <div class="bttitle">场景引入，数码产品卖点详解</div>
                </div>
                <div class="onevideo">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/280615580/p/1/e/6/t/1/290763900904.mp4"
                  ></video>
                  <div class="title1">家居体验</div>
                  <div class="bttitle">配合真实家居环境使用体验</div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="allvideo">
                <div class="onevideo" style="margin-left:60px">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/2533228628/p/1/e/6/t/1/285138603803.mp4"
                  ></video>
                  <div class="title1">护肤亲测</div>
                  <div class="bttitle">详细讲解卖点，使用评测</div>
                </div>
                <div class="onevideo">
                  <video
                    controls
                    width="100%"
                    src="https://cloud.video.taobao.com/play/u/2080307907/p/1/e/6/t/1/285239368589.mp4"
                  ></video>
                  <div class="title1">服饰搭配</div>
                  <div class="bttitle">搭配不同服装展示</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="main6">
        <img
          src="https://img.alicdn.com/imgextra/i1/O1CN01KxZ9wy1KaU22bug9g_!!6000000001180-2-tps-1259-1490.png"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    gobuy() {
      let returnEle = null;
      returnEle = document.querySelector("#buy");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    buy() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/275");
    },
    buy1() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/278");
    },
  },
};
</script>

<style scoped>
div /deep/.el-carousel__arrow {
  width: 40px;
  height: 40px;
  font-size: 18px;
}
.bcgcolor {
  background-color: white;
}
.carousel-control.left {
  background-color: aqua;
}
.allvideo {
  margin-top: 20px;
  display: flex;
}
.onevideo {
  width: 170px;
  height: 620px;
  margin-left: 34px;
  position: relative;
}
.onevideo1 {
  width: 170px;
  height: 300px;
  background-color: white;
  margin-left: 34px;
  position: relative;
}
.title {
  width: 110px;
  height: 30px;
  background-color: rgb(251, 199, 1);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.title1 {
  width: 110px;
  height: 30px;
  background-color: rgb(14, 210, 250);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.bttitle {
  margin-left: 10px;
  width: 150px;
  height: 30px;
  font-size: 16px;
  font-weight: 450;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.carousel1 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 570px;
}
.carousel2 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 100px;
}
#buy {
  position: absolute;
  bottom: 78%;
}
.buy {
  width: 185px;
  height: 50px;
  background-color: rgb(234, 59, 30);
  color: white;
  position: absolute;
  bottom: 10%;
  left: 62%;
  line-height: 50px;
  border-radius: 30px;
  font-size: 28px;
  font-weight: 600;
  border: 2px solid black;
}
.buy1 {
  width: 185px;
  height: 50px;
  background-color: rgb(234, 59, 30);
  color: white;
  position: absolute;
  bottom: 10.2%;
  left: 41.6%;
  line-height: 50px;
  border-radius: 30px;
  font-size: 28px;
  font-weight: 600;
  border: 2px solid black;
}
.buy:hover {
  cursor: pointer;
}
.buy1:hover {
  cursor: pointer;
}
.buysum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1 {
  text-align: center;
  padding-top: 80px;
  margin-top: -5px;
}
.main2 {
  text-align: center;
  margin-top: 75px;
}
.main3 {
  text-align: center;
  margin-top: 75px;
}
.main4 {
  text-align: center;
  margin-top: 75px;
}
.main5 {
  text-align: center;
  margin-top: 75px;
}
.main6 {
  text-align: center;
  margin-top: 75px;
  margin-right: 135px;
}
.main7 {
  text-align: center;
  padding-top: 80px;
  margin-top: -5px;
}
section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01RzrfaT28SRs0ljF4r_!!6000000007931-2-tps-1920-8700.png");
  background-size: cover;
  margin: 0 auto;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
.headimg {
  position: absolute;
  width: 400px;
  bottom: 5%;
  left: 52%;
  transform: translateX(-50%);
}
.headimg:hover {
  cursor: pointer;
}
</style>
