import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
import less from 'less'
import VueBus from 'vue-bus';
import Meta from 'vue-meta'


router.beforeEach((to, from, next) => {
  //  路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(less);
Vue.use(VueBus);
Vue.use(Meta);

Vue.prototype.$https = axios;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
