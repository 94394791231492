import request from "@/utils/request";

// 列表数量
export function getNewlistCount(params) {
  return request({
    url: "/api/product/content/count",
    method: "get",
    params,
  });
}

// 列表
export function getNewlist(params) {
  return request({
    url: "api/product/content/list",
    method: "get",
    params,
  });
}
