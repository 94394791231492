import request from "@/utils/request";

// 详情
export function getDetail(params) {
  return request({
    url: "/api/product/content/detail",
    method: "get",
    params,
  });
}
