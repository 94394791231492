<template>
  <div>
    <header>
      <span>
        <img
          width="120%"
          src="https://img.alicdn.com/imgextra/i1/O1CN01AESlgG1cAz6m62av9_!!6000000003561-2-tps-89-31.png"
          alt=""
        />
      </span>
      <div class="menu">
        <div class="menuitem" @click="move1">首页</div>
        <!-- <div class="menuitem" @click="move2">大会亮点</div> -->
        <div class="menuitem" @click="move3">精彩呈现</div>
        <div class="menuitem" @click="move4">合作伙伴</div>
        <div class="menuitem" @click="move5">联系我们</div>
        <div class="menuitem1" @click="move6">峰会回顾</div>
      </div>
    </header>
    <section>
      <div class="main1" id="main1">
        <div class="main1sum">
          <img
            width="100%"
            src="https://img.alicdn.com/imgextra/i2/O1CN01LXU0Bb1ed2cGWya0l_!!6000000003893-2-tps-1920-8061.png"
            alt=""
          />
        </div>
        <div class="sum1" id="sum1"></div>
        <div class="sum2" id="sum2"></div>
        <div class="sum3" id="sum3"></div>
      </div>
      <!-- <div class="main2" id="main2">
        <img
          width="100%"
          src="https://img.alicdn.com/imgextra/i2/O1CN01Vlokuy25aHrI4LUoJ_!!6000000007542-2-tps-1920-6546.png"
          alt=""
        />
        <div id="main3"></div>
        <div id="main4"></div>
      </div> -->
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    buyticket() {
      window.open("http://hdxu.cn/mhuQZ");
    },
    move1() {
      let returnEle = null;
      returnEle = document.querySelector("#main1");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move2() {
      let returnEle = null;
      returnEle = document.querySelector("#sum1");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move3() {
      let returnEle = null;
      returnEle = document.querySelector("#sum1");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move4() {
      let returnEle = null;
      returnEle = document.querySelector("#sum2");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move5() {
      let returnEle = null;
      returnEle = document.querySelector("#sum3");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move6() {
      window.location.href =
        "https://www.tianxiawangshang.com/activity/wsfh/remouldback";
    },
  },
};
</script>

<style scoped>
.sum1 {
  top: 17%;
  left: 10%;
  position: absolute;
  width: 382px;
  height: 200px;
}
.sum2 {
  bottom: 16%;
  position: absolute;
  width: 382px;
  height: 200px;
}
.sum3 {
  bottom: 0;
  position: absolute;
  width: 382px;
  height: 200px;
}
.main1sum {
  width: 100%;
  position: relative;
  margin: 0 auto;
}
.main1 {
  text-align: center;
  padding-top: 0px;
  margin-top: -5px;
}
.main2 {
  text-align: center;
  margin-top: 85px;
}
#main3 {
  position: absolute;
  bottom: 18%;
}
#main4 {
  position: absolute;
  bottom: 0;
}
header {
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 1300px;
  height: 80px;
  background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01Rml089292dChXdb0l_!!6000000008010-2-tps-1920-66.png");
  background-size: cover;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
header span {
  margin: 0 0 0 0;
}
.menu {
  display: flex;
}
.menuitem {
  width: 200px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-size: 23px;
  font-weight: 500;
  color: black;
}
.menuitem:hover {
  cursor: pointer;
}
.menuitem1 {
  width: 130px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  font-weight: 500;
  margin-left: 20px;
  background-size: cover;
  color: white;
  background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01Qu5CY81Er4k90As7l_!!6000000000404-2-tps-122-40.png");
}
.menuitem1:hover {
  cursor: pointer;
}
.el-menu-demo {
  margin-right: 5%;
  min-width: 500px;
}
.el-menu-item {
  font-size: 17px;
  font-weight: 500;
}
.el-menu-item:hover {
  background-color: transparent !important;
}
.el-submenu__title:hover {
  background-color: transparent !important;
}
.topimg {
  width: 100%;
  text-align: center;
  background-size: cover;
  background-image: url("https://img.alicdn.com/imgextra/i1/O1CN01QIzdKG1eWAcGf4mY8_!!6000000003878-2-tps-1920-603.png");
}
section {
  width: 100%;
  min-width: 1350px;
  height: 100%;
  background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01MS5NEu1KaU4jSPqG4_!!6000000001180-2-tps-1920-8635.png");
  background-size: cover;
  margin-top: 80px;
  position: relative;
}
</style>
