import request from "@/utils/request";

// 搜索列表数量
export function getSearchCount(params) {
  return request({
    url: "/api/product/content/searchArticleByKeywordCount",
    method: "get",
    params,
  });
}

// 搜索列表
export function getSearch(params) {
  return request({
    url: "/api/product/content/searchArticleByKeyword",
    method: "get",
    params,
  });
}
