<template>
  <div>
    <header>
      <span>
        <img
          width="100%"
          src="https://img.alicdn.com/imgextra/i1/O1CN01WwoKIw1bRdrDW1WO3_!!6000000003462-2-tps-160-51.png"
          alt=""
        />
      </span>
      <div class="menu">
        <div
          :class="{
            menuitem: this.meunshow1 === false,
            menuitem1: this.meunshow1 === true,
          }"
          @click="meun1"
        >
          万象MCN
        </div>
        <div>|</div>
        <div
          :class="{
            menuitem: this.meunshow2 === false,
            menuitem1: this.meunshow2 === true,
          }"
          @click="meun2"
        >
          商家合作
        </div>
        <div>|</div>
        <div
          :class="{
            menuitem: this.meunshow3 === false,
            menuitem1: this.meunshow3 === true,
          }"
          @click="meun3"
        >
          达人合作
        </div>
      </div>
    </header>
    <section>
      <div v-show="this.meunshow1 === true">
        <div class="topcar">
          <el-carousel :height="hdgd" class="topcarou">
            <el-carousel-item>
              <div class="topcarousel">
                <img
                  src="https://img.alicdn.com/imgextra/i2/O1CN01mNED8z1J5VdbHbgNZ_!!6000000000977-2-tps-1920-680.png"
                  alt=""
                />
                <div class="topcarouseltitle">
                  <div class="topcarouseltitle1">万象MCN</div>
                  <div class="topcarouseltitle2">
                    构建数字时代下淘宝逛逛达人生态圈，依托自孵及签约的达人资源及深度沉淀的社交大数据实现商家及品牌内容营销的精准匹配，同步为达人提供全方位的成长路径，打通商业变现的链路。
                  </div>
                  <div class="topcarouseltitle3">
                    <div class="title31">账号签约&孵化</div>
                    <div class="title31" style="margin-left:30px">品牌广告</div>
                    <div class="title31" style="margin-left:30px">
                      用户资产运营
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="topcarousel">
                <img
                  src="https://img.alicdn.com/imgextra/i4/O1CN01dtIIV922VIeOGgpYJ_!!6000000007125-2-tps-1920-680.png"
                  alt=""
                />
                <div class="topcarouseltitle">
                  <div class="topcarouseltitle1">万象MCN</div>
                  <div class="topcarouseltitle2">
                    构建数字时代下淘宝逛逛达人生态圈，依托自孵及签约的达人资源及深度沉淀的社交大数据实现商家及品牌内容营销的精准匹配，同步为达人提供全方位的成长路径，打通商业变现的链路。
                  </div>
                  <div class="topcarouseltitle3">
                    <div class="title31">账号签约&孵化</div>
                    <div class="title31" style="margin-left:30px">品牌广告</div>
                    <div class="title31" style="margin-left:30px">
                      用户资产运营
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="main1">
          <div class="main1sum">
            <img
              src="https://img.alicdn.com/imgextra/i2/O1CN01fy2AWr25t42nsLVSw_!!6000000007583-2-tps-1199-737.png"
              alt=""
            />
            <div class="main1title1">
              <div class="title1top">达人无从选择</div>
              <div class="title1btn">
                千千万万的达人，真真假假的粉丝量，什么样的达人才是好的？
              </div>
            </div>
            <div class="main1title2">
              <div class="title1top">价格无从定义</div>
              <div class="title1btn">
                市场没有标准的价格，机构的价格高的离谱，什么样的价格才是合理的？
              </div>
            </div>
            <div class="main1title3">
              <div class="title1top" style="text-align:right">内容无从判断</div>
              <div class="title1btn" style="text-align:center">
                给了产品，出来内容，但是永远达不到期望的要求，什么样的内容才是种草的？
              </div>
            </div>
            <div class="main1title4">
              <div class="title1top" style="text-align:right">数据无从考究</div>
              <div class="title1btn" style="text-align:center">
                什么样的数据都可以维护，数据可以依据的维度到底哪些是合理的？
              </div>
            </div>
            <div class="main1title5">
              商家的苦恼
            </div>
          </div>
        </div>
        <div class="main2">
          <div class="main2sum">
            <img
              style="margin-top:200px"
              src="https://img.alicdn.com/imgextra/i2/O1CN01EF3JDU1DoxExAh6Ct_!!6000000000264-2-tps-1152-424.png"
              alt=""
            />
            <div class="main2title1">
              我们的优势
            </div>
            <div class="main2title2">
              <div class="title2top">深耕内容运营</div>
              <div class="title2btn">
                承接淘宝官方多个栏目账号私域微淘内容运营
              </div>
              <div class="title2btn">
                为官方账号的数据活跃和用户唤醒提供支持
              </div>
              <div class="title2btn">
                为淘宝官方的各个内容板块
              </div>
              <div class="title2btn">
                提供专栏定制创意
              </div>
            </div>
            <div class="main2title3">
              <div class="title2top">丰富资源矩阵</div>
              <div class="title2btn">
                拥抱阿里经济体赋能行业及品牌
              </div>
              <div class="title2btn">
                拥有齐全的站内站外渠道资源
              </div>
              <div class="title2btn">
                丰富的主播&达人资源
              </div>
              <div class="title2btn">
                专业的执行服务团队
              </div>
            </div>
            <div class="main2title4">
              <div class="title2top">完善服务体系</div>
              <div class="title2btn">
                专业的执行服务团队
              </div>
              <div class="title2btn">
                齐全的组织架构
              </div>
              <div class="title2btn">
                标准化的服务流程
              </div>
              <div class="title2btn">
                体系化的红人运营孵化细则
              </div>
            </div>
          </div>
        </div>
        <div class="main3">
          <div class="main3sum">
            <img
              style="margin-top:100px;margin-right:40px"
              src="https://img.alicdn.com/imgextra/i4/O1CN016mbHaj1f1lwuV1rma_!!6000000003947-2-tps-1096-2382.png"
              alt=""
            />
            <div class="main3title1">
              我们的资源
            </div>
            <div class="main3title2">
              达人资源
            </div>
            <div class="main3title3">
              渠道资源
            </div>
            <div class="main3title4">
              孵化达人账号
            </div>
            <div class="main3car">
              <el-carousel height="400px" class="main3carousel">
                <el-carousel-item>
                  <div class="main3carousel1">
                    <div class="main3left">
                      <div class="touxiang">
                        <img
                          src="https://img.alicdn.com/imgextra/i1/O1CN01QcPdPr1ydJ6hkjfOu_!!6000000006601-2-tps-100-100.png"
                          alt=""
                        />
                      </div>
                      <div class="lefttitle">
                        <div class="lefttitle1">小红书</div>
                        <div class="lefttitle2">
                          目前小红书库内账号数量6000+，垂类覆盖美妆、美食、穿搭、家居等全部类目，且包含各个垂类的头部账号。可承接图文、视频传播。
                        </div>
                      </div>
                      <div class="righttitle">
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i4/O1CN010a66As1Gy3xlWAfaV_!!6000000000690-0-tps-240-240.jpg"
                            alt=""
                          />
                          <div class="rightname">Rainee子鹿</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              38.6w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i1/O1CN01UX5MKv1sxYvc2lGWM_!!6000000005833-0-tps-240-240.jpg"
                            alt=""
                          />
                          <div class="rightname">你喵_niMmm</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              23w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i4/O1CN01yL7fWz1Nrpt8tT8s2_!!6000000001624-0-tps-240-240.jpg"
                            alt=""
                          />
                          <div class="rightname">要小心</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              17.1w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i4/O1CN01A3GmgA1erEq99dT73_!!6000000003924-0-tps-240-240.jpg"
                            alt=""
                          />
                          <div class="rightname">Regina</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              16.6w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i3/O1CN01KE2flz1iXK4zDBR3Z_!!6000000004422-0-tps-240-240.jpg"
                            alt=""
                          />
                          <div class="rightname">依格瓦</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              14.5w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i4/O1CN01DvKgLX1pIqsNNzSyo_!!6000000005338-0-tps-240-240.jpg"
                            alt=""
                          />
                          <div class="rightname">Sour大甜甜</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              14.4w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i4/O1CN01c4pCmY20RyXcSmEkB_!!6000000006847-0-tps-240-240.jpg"
                            alt=""
                          />
                          <div class="rightname">Miya小娅</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              9.9w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i3/O1CN01iJkzhT287q3AIKeAI_!!6000000007886-0-tps-240-240.jpg"
                            alt=""
                          />
                          <div class="rightname">乔安娜</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              6.5w
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="main3carousel1">
                    <div class="main3left">
                      <div class="touxiang">
                        <img
                          src="https://img.alicdn.com/imgextra/i4/O1CN01Bzh4OU1K7Agjl6GVw_!!6000000001116-2-tps-100-100.png"
                          alt=""
                        />
                      </div>
                      <div class="lefttitle">
                        <div class="lefttitle1">微博</div>
                        <div class="lefttitle2">
                          微博平台媒体账号与自有账号，库内账号数量为5000+，垂类覆盖美妆、美食、穿搭、生活，制定出了独一的微博运营资源，极具价格优势与内容吸引力。
                        </div>
                      </div>
                      <div class="righttitle">
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i4/O1CN014DAQbx1itJ5bRnHvy_!!6000000004470-0-tps-180-180.jpg"
                            alt=""
                          />
                          <div class="rightname">林汤团</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              301w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i1/O1CN01mqGjLM1TpRVtp2bud_!!6000000002431-0-tps-180-180.jpg"
                            alt=""
                          />
                          <div class="rightname">小兔兔的小兔</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              229w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i1/O1CN01lkWwRc1eTsdlvTqON_!!6000000003873-0-tps-180-180.jpg"
                            alt=""
                          />
                          <div class="rightname">肖恩Yetta</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              216w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i4/O1CN01DXpLNy1trRFXhLUVT_!!6000000005955-0-tps-180-180.jpg"
                            alt=""
                          />
                          <div class="rightname">Tina是缇娜吖</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              122w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i2/O1CN01teeU0E1OXVvlrc653_!!6000000001715-0-tps-180-180.jpg"
                            alt=""
                          />
                          <div class="rightname">花帮小黛黛</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              120w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i4/O1CN01OzI0Rz1Sp9eJb4FMQ_!!6000000002295-0-tps-180-180.jpg"
                            alt=""
                          />
                          <div class="rightname">甜蜜多巴胺</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              112w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i2/O1CN01UxNJMk1F6e9gtr8x9_!!6000000000438-0-tps-180-180.jpg"
                            alt=""
                          />
                          <div class="rightname">孫兆彤</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              112w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i2/O1CN01iIzjfO1g8vkk3knlF_!!6000000004098-0-tps-180-180.jpg"
                            alt=""
                          />
                          <div class="rightname">于鑫淼儿</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              50w
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="main3carousel1">
                    <div class="main3left">
                      <div class="touxiang">
                        <img
                          src="https://img.alicdn.com/imgextra/i2/O1CN01hvKBrx1zREHTMHPnj_!!6000000006710-2-tps-100-100.png"
                          alt=""
                        />
                      </div>
                      <div class="lefttitle">
                        <div class="lefttitle1">B站</div>
                        <div class="lefttitle2">
                          库内已合作账号2000+，覆盖b站所有类目形成了一个完整的达人资源矩阵。完全以内容质量为第一优先级进行达人的筛选和合作。
                        </div>
                      </div>
                      <div class="righttitle">
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i4/O1CN01UeNNUp1RfFT1ZEOsM_!!6000000002138-49-tps-128-128.webp"
                            alt=""
                          />
                          <div class="rightname">名叫李了了</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              25.7w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i4/O1CN01jH1fTb1I16Ckpi7co_!!6000000000832-49-tps-128-128.webp"
                            alt=""
                          />
                          <div class="rightname">大盒打盒</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              25.3w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i3/O1CN01dHWk761InBnEUuHaX_!!6000000000937-49-tps-128-128.webp"
                            alt=""
                          />
                          <div class="rightname">cecici</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              17w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i2/O1CN01BSuvpv1vEAWOQoNwJ_!!6000000006140-49-tps-128-128.webp"
                            alt=""
                          />
                          <div class="rightname">Hi-Melisa</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              15w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i4/O1CN012XVSVN1doCb83sjbv_!!6000000003782-49-tps-128-128.webp"
                            alt=""
                          />
                          <div class="rightname" style="width:100px">
                            不挑食的萌大佬
                          </div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              11.7w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i2/O1CN01LHS2cx1pfkg5Djhug_!!6000000005388-49-tps-128-128.webp"
                            alt=""
                          />
                          <div class="rightname">萱草daylily</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              11w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i3/O1CN01KTL0dO1oZVfO1s1AQ_!!6000000005239-49-tps-128-128.webp"
                            alt=""
                          />
                          <div class="rightname">我是李鹿一</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              10.1w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i1/O1CN01YLT0WS1YvSnhvtcLt_!!6000000003121-49-tps-128-128.webp"
                            alt=""
                          />
                          <div class="rightname">大头李儿hh</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              9.6w
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="main3carousel1">
                    <div class="main3left">
                      <div class="touxiang">
                        <img
                          src="https://img.alicdn.com/imgextra/i1/O1CN01GYWNP51fwZ3yLRqGQ_!!6000000004071-2-tps-100-100.png"
                          alt=""
                        />
                      </div>
                      <div class="lefttitle">
                        <div class="lefttitle1">逛逛</div>
                        <div class="lefttitle2">
                          万象为淘宝逛逛官方机构，机构旗下绑定账号500+，自孵化账号18+，合作机构15+，站外签约达人500+，合作达人5000+，站内合作达人800+，覆盖类目主要为穿搭、美食、生活家居等领域。
                        </div>
                      </div>
                      <div class="righttitle">
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i3/O1CN01d8yGbQ200xAhTFltU_!!6000000006788-0-tps-790-790.jpg"
                            alt=""
                          />
                          <div class="rightname" style="width:100px">
                            小柚今天穿什么
                          </div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              94w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i3/O1CN01PxF8Gl1oKOaz67CYf_!!6000000005206-0-tps-640-640.jpg"
                            alt=""
                          />
                          <div class="rightname">豌二豆</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              21w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i3/O1CN01tbKu571HlWjn9Wc58_!!6000000000798-0-tps-790-790.jpg"
                            alt=""
                          />
                          <div class="rightname">是大笑</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              11w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i3/O1CN012jKuET1RRVaNRQIWs_!!6000000002108-0-tps-790-1053.jpg"
                            alt=""
                          />
                          <div class="rightname">农夫3拳</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              5.1w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i4/O1CN01yth50N1u13ZIj5OBe_!!6000000005976-0-tps-686-686.jpg"
                            alt=""
                          />
                          <div class="rightname" style="width:100px">
                            信口开饭的笑恺
                          </div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              4.2w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i4/O1CN01XSyacL1rOvMHWtWmy_!!6000000005622-0-tps-300-300.jpg"
                            alt=""
                          />
                          <div class="rightname">阿酱会吃</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              4w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i2/O1CN01vr44Og1qqZefAtKZf_!!6000000005547-0-tps-790-791.jpg"
                            alt=""
                          />
                          <div class="rightname">是poppy呀</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              3w
                            </div>
                          </div>
                        </div>
                        <div class="rightimg">
                          <img
                            src="https://img.alicdn.com/imgextra/i3/O1CN01Ln3VdE1FEQt9VnrtQ_!!6000000000455-0-tps-790-790.jpg"
                            alt=""
                          />
                          <div class="rightname">光Aucifer</div>
                          <div class="righttitle1">
                            <div>粉丝数：</div>
                            <div
                              style="color:rgb(255, 107, 34);font-size:16px;font-weight:500"
                            >
                              2.1w
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="main3title5">
              <div class="title3top">账号领域</div>
              <div class="title3btn">美食、美妆、家居生活、穿搭等</div>
            </div>
            <div class="main3title6">
              <div class="title3top">账号总粉丝数</div>
              <div class="title3btn">200万+</div>
            </div>
            <div class="main3title7">
              <div class="title3top">账号合作品牌</div>
              <div class="title3btn">每日好店、淘宝直播、极有家、 有好货等</div>
            </div>
            <div class="main3title8">
              <div class="title3top">写手矩阵</div>
              <div class="title3btn" style="width:200px">
                目前写手团队有200+成员 拍摄剪辑团队10+成员
              </div>
            </div>
            <div class="main3title9">
              签约达人账号
            </div>
            <div class="main3title10">
              <div class="title3top1">账号分布</div>
              <div class="title3btn" style="width:300px">
                小红书、微博、B站、抖音、快手、逛逛等
              </div>
            </div>
            <div class="main3title11">
              <div class="title3top1">账号领域</div>
              <div class="title3btn" style="width:300px">
                各垂直领域
              </div>
            </div>
            <div class="main3title12">
              <div class="title3btn">逛逛账号</div>
              <div class="title3btn">
                1-3月总发布条数
              </div>
            </div>
            <div class="main3title13">
              <div class="title3btn">逛逛账号</div>
              <div class="title3btn">
                1-3月总获赞条数
              </div>
            </div>
            <div class="main3title14">
              <div class="title3btn">逛逛账号</div>
              <div class="title3btn">
                1-3月总涨粉条数
              </div>
            </div>
            <div class="main3title15">
              基地&团队资源
            </div>
            <div class="main3title16">
              <div class="title3top" style="font-size:20px">
                在全国重点城市和产业带建立各类直播/拍摄基地，
              </div>
              <div class="title3top" style="font-size:20px;width:800px">
                同时拥有内容、短视频、直播、红人签约、广告等小组组建成团队进行各项内容的服务
              </div>
            </div>
            <div :class="{ quxianimg: testShow }" ref="testref">
              <img
                width="100%"
                src="https://img.alicdn.com/imgextra/i3/O1CN01WPAFR21cbY2cWxGzd_!!6000000003619-2-tps-1366-271.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="main4">
          <div class="main4sum">
            <img
              style="margin-top:200px;"
              src="https://img.alicdn.com/imgextra/i1/O1CN01uZzB0g21jfSJjzMWg_!!6000000007021-2-tps-1278-695.png"
              alt=""
            />
            <div class="main4title1">
              我们的流程
            </div>
            <div class="main4title2">
              达人运营流程
            </div>
            <div class="main4title3">
              服务对接流程
            </div>
            <div class="main4title4">
              全约红人运营细则
            </div>
            <div class="main4title5">
              <div class="title4top">试用期红人</div>
              <div class="title4btn">
                <div class="title4btn1">1</div>
                <div class="title4btn2">基本信息人设</div>
              </div>
              <div class="title4btn">
                <div class="title4btn1">2</div>
                <div class="title4btn2">内容产出及测试</div>
              </div>
              <div class="title4btn">
                <div class="title4btn1">3</div>
                <div class="title4btn2">课程安排及跟进</div>
              </div>
              <div class="title4btn">
                <div class="title4btn1">4</div>
                <div class="title4btn2">定期沟通及疏导</div>
              </div>
            </div>
            <div class="main4title6">
              <div class="title4top">内容孵化期红人</div>
              <div class="title4btn">
                <div class="title4btn1">1</div>
                <div class="title4btn2">持续产出内容</div>
              </div>
              <div class="title4btn">
                <div class="title4btn1">2</div>
                <div class="title4btn2">对接变现</div>
              </div>
              <div class="title4btn">
                <div class="title4btn1">3</div>
                <div class="title4btn2">预约制作</div>
              </div>
              <div class="title4btn">
                <div class="title4btn1">4</div>
                <div class="title4btn2">跟踪调整</div>
              </div>
            </div>
            <div class="main4title7">
              <div class="title4top">准飞跃发展期红人</div>
              <div class="title4btn">
                <div class="title4btn1">1</div>
                <div class="title4btn2">整体把控</div>
              </div>
              <div class="title4btn">
                <div class="title4btn1">2</div>
                <div class="title4btn2">优化资源配置</div>
              </div>
              <div class="title4btn">
                <div class="title4btn1">3</div>
                <div class="title4btn2">拓展平台</div>
              </div>
            </div>
            <div class="main4title8">
              <div class="title4toptwo">品牌需求规划</div>
              <div class="title4btntwo">
                根据品牌需求和渠道情况进行沟通并且制定内容规划&执行排期
              </div>
            </div>
            <div class="main4title9">
              <div class="title4toptwo">初期内容沟通</div>
              <div class="title4btntwo">
                执行中的文案/脚本/样片提供给品牌审核并优化
              </div>
            </div>
            <div class="main4title10">
              <div class="title4toptwo">执行内容沟通</div>
              <div class="title4btntwo">
                根据文案/脚本/样片的基础进行内容的拍摄&剪辑，并和品牌确认
              </div>
            </div>
            <div class="main4title11">
              <div class="title4toptwo">确认成片</div>
              <div class="title4btntwo">
                品牌确认后进行渠道投放
              </div>
            </div>
            <div class="main4title12">
              <div class="title4toptwo">内容汇总</div>
              <div class="title4btntwo">
                投放达人内容链接回收， 整合提供给品牌
              </div>
            </div>
            <div class="main4title13">
              <div class="title4toptwo">结案报告</div>
              <div class="title4btntwo">
                内容投放后，反馈品牌阶段性的投放数据
              </div>
            </div>
          </div>
        </div>
        <div class="main5">
          <div class="main5sum">
            <img
              style="margin-top:340px;"
              src="https://img.alicdn.com/imgextra/i4/O1CN01FWlcJm1FSAlq5w6fU_!!6000000000485-2-tps-1140-2397.png"
              alt=""
            />
            <div class="main5title1">
              我们的案例
            </div>
            <div class="main5title2">
              <div>在达人渠道投放上拥有非常丰富的经验</div>
              <div>
                同时承接淘宝官方多个栏目账号私域微淘内容运营，为官方账号的数据活跃和用户唤醒提供支持
              </div>
            </div>
            <div class="main5title3">
              达人投放
            </div>
            <div class="main5title4">
              以品牌需求为基础，定制化适配相关达人进行推广投放
            </div>
            <div class="main5title5">
              达人图文投放
            </div>
            <div class="main5title6">
              达人短视频投放
            </div>
            <div class="main5title7">
              图文/短视频制作
            </div>
            <div class="main5title8">
              专业摄影剪辑小编等人员按照brief生产相关产品内容
            </div>
            <div class="main5title9">
              图文制作
            </div>
            <div class="main5title10">
              短视频制作
            </div>
            <div class="main5title11">
              <div class="main5viedo" style="line-height:800px">
                <video
                  controls
                  width="100%"
                  src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E6%B5%B7%E5%B0%94%E5%86%B7%E6%9F%9C-%E5%8F%A3%E6%92%AD.mp4"
                ></video>
              </div>
              <div class="main5viedo">
                <video
                  controls
                  width="100%"
                  src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%AE%89%E8%A7%86%E4%BC%98-%E5%8F%A3%E6%92%AD.mp4"
                ></video>
              </div>
              <div class="main5viedo" style="line-height:1080px">
                <video
                  controls
                  width="100%"
                  src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%AE%89%E8%A7%86%E4%BC%98-%E5%A6%86%E5%AE%B9.mp4"
                ></video>
              </div>
            </div>
            <div class="main5title12">
              <div>可根据品牌的需求产出不同风格的内容，</div>
              <div>如场景化图文、产品化图文、风格化图文等。</div>
            </div>
            <div class="main5title13">
              <div class="main5viedosum">
                <div class="main5viedosum1">
                  <video
                    controls
                    width="100%"
                    src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%87%AF%E7%91%9E%E7%8E%9B--%E5%89%A7%E6%83%85.mp4"
                  ></video>
                </div>
                <div class="main5viedosum2">口播化短视频</div>
              </div>
              <div class="main5viedosum">
                <div class="main5viedosum1">
                  <video
                    controls
                    width="100%"
                    src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%87%AF%E7%91%9E%E7%8E%9B-vlog.mp4"
                  ></video>
                </div>
                <div class="main5viedosum2">产品化短视频</div>
              </div>
              <div class="main5viedosum">
                <div class="main5viedosum1">
                  <video
                    controls
                    width="100%"
                    src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E6%B5%B7%E5%B0%94%E5%86%B7%E6%9F%9C-VLOG.mp4"
                  ></video>
                </div>
                <div class="main5viedosum2">剧情化短视频</div>
              </div>
            </div>
          </div>
        </div>
        <div class="main6">
          <div class="main6sum">
            <img
              style="margin-top:500px;"
              src="https://img.alicdn.com/imgextra/i3/O1CN01SEqlHW1xEHr9TolSt_!!6000000006411-2-tps-1140-474.png"
              alt=""
            />
            <div class="main6title1">
              合作品牌
            </div>
            <div class="main6title2">
              <div class="main6pdf" @click="openpdf1">
                <div class="main6pdf1">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i2/O1CN01wvMCCn1mEK7MpmbZL_!!6000000004922-2-tps-535-300.png"
                    alt=""
                  />
                </div>
                <div class="main6pdf2">
                  <div>安视优</div>
                  <div class="sanjiao">
                    <div class="sanjiao1"></div>
                  </div>
                </div>
              </div>
              <div class="main6pdf" @click="openpdf2">
                <div class="main6pdf1">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i3/O1CN01dC9WUk1ESLIuu3Ixj_!!6000000000350-2-tps-535-300.png"
                    alt=""
                  />
                </div>
                <div class="main6pdf2">
                  <div>聚划算</div>
                  <div class="sanjiao">
                    <div class="sanjiao1"></div>
                  </div>
                </div>
              </div>
              <div class="main6pdf" @click="openpdf3">
                <div class="main6pdf1">
                  <img
                    width="100%"
                    src="https://img.alicdn.com/imgextra/i4/O1CN01sy7EBI23pHXypgb2Z_!!6000000007304-2-tps-535-300.png"
                    alt=""
                  />
                </div>
                <div class="main6pdf2">
                  <div>UR</div>
                  <div class="sanjiao">
                    <div class="sanjiao1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main7">
          <div class="main7sum">
            <img
              style="margin-top:200px;margin-right:200px"
              src="https://img.alicdn.com/imgextra/i2/O1CN01eMc0k21et4QbVcQHu_!!6000000003928-2-tps-536-80.png"
              alt=""
            />
            <div class="main7title1">
              联系我们
            </div>
            <div class="main7title2">
              <div>售前咨询 0571-85022088-75728</div>
              <div>意见反馈 0571-85022088-79324</div>
            </div>
            <div class="main7title3">
              <div>旺旺 天下网商-客满2</div>
              <div>旺旺 天下网商-客满</div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="this.meunshow2 === true">
        <div class="peopleimg">
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01t4L0x91EehznCJ5QO_!!6000000000377-2-tps-1920-300.png"
            alt=""
          />
          <div class="toptitle">
            <div class="toptitle1">商家合作</div>
            <div class="toptitle2">
              为品牌商家匹配精准优质达人，提供定制化营销解决方案，全方面提升品牌价值
            </div>
          </div>
        </div>
        <div>
          <div class="peopleout">
            <div class="outimg">
              <img
                style="margin:40px 0"
                src="https://img.alicdn.com/imgextra/i3/O1CN01RH8pnb1fLvNN046v4_!!6000000003991-2-tps-763-2.png"
                alt=""
              />
              <div class="outtitle">基本信息</div>
            </div>
            <div class="peopleform">
              <el-form
                ref="shopform"
                :model="shopform"
                :rules="shoprules"
                label-width="120px"
                size="small"
              >
                <el-form-item label="联系人" prop="contactName">
                  <el-input
                    v-model="shopform.contactName"
                    placeholder="请输入联系人"
                  ></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="contactPhone">
                  <el-input
                    v-model="shopform.contactPhone"
                    placeholder="请输入电话"
                  ></el-input>
                </el-form-item>
                <el-form-item label="品牌名" prop="brand">
                  <el-input
                    v-model="shopform.brand"
                    placeholder="请输入品牌名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="角色" prop="role">
                  <el-input
                    v-model="shopform.role"
                    placeholder="请输入角色"
                  ></el-input>
                </el-form-item>
                <div class="personbtn1">
                  <img
                    src="https://img.alicdn.com/imgextra/i2/O1CN01G245H11Qaq2RE8ojb_!!6000000001993-2-tps-191-62.png"
                    alt=""
                  />
                  <div class="btntitle" @click="summitShop">提交</div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
        <div class="btnblock"></div>
      </div>
      <div v-show="this.meunshow3 === true">
        <div class="peopleimg">
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01BjWvx11YIX8MybkkV_!!6000000003036-2-tps-1920-300.png"
            alt=""
          />
          <div class="toptitletow">
            <div class="toptitle1">达人合作</div>
            <div class="toptitle2">
              专业的团队、成熟的孵化体系、优质的平台资源，
            </div>
            <div class="toptitle3">
              高效快速推动达人商业化，打造具备优质商业价值的头部达人
            </div>
          </div>
        </div>
        <div>
          <div class="peopleout">
            <div class="outimg">
              <img
                style="margin:40px 0"
                src="https://img.alicdn.com/imgextra/i3/O1CN01RH8pnb1fLvNN046v4_!!6000000003991-2-tps-763-2.png"
                alt=""
              />
              <div class="outtitle">基本信息</div>
            </div>
            <div class="peopleform">
              <el-form
                ref="peopleform"
                :model="peopleform"
                :rules="peoplerules"
                label-width="120px"
                size="small"
              >
                <!-- <el-form-item label="供应商种类" prop="supplierType">
                  <el-radio-group v-model="peopleform.supplierType">
                    <el-radio label="0">个人供应商</el-radio>
                    <el-radio label="1">企业供应商</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="采购业务类型" prop="procurementType">
                  <el-radio-group v-model="peopleform.procurementType">
                    <el-radio label="0">图文</el-radio>
                    <el-radio label="1">视频</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="合作品牌" prop="brand">
                  <el-input
                    v-model="peopleform.brand"
                    placeholder="请输入合作品牌"
                  ></el-input>
                </el-form-item> -->
                <el-form-item label="姓名" prop="name">
                  <el-input
                    v-model="peopleform.name"
                    placeholder="请输入您的姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="phone">
                  <el-input
                    v-model="peopleform.phone"
                    placeholder="请输入您的电话"
                  ></el-input>
                </el-form-item>
                <el-form-item label="主渠道昵称" prop="phone">
                  <el-input
                    v-model="peopleform.phone"
                    placeholder="请输入主渠道昵称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="主渠道链接" prop="phone">
                  <el-input
                    v-model="peopleform.phone"
                    placeholder="请输入主渠道链接"
                  ></el-input>
                </el-form-item>
                <!-- <el-form-item label="邮箱" prop="email">
                  <el-input
                    v-model="peopleform.email"
                    placeholder="请输入您的邮箱"
                  ></el-input>
                </el-form-item>
                <el-form-item label="银行卡号" prop="bankCard">
                  <el-input
                    v-model="peopleform.bankCard"
                    placeholder="请输入您的银行卡号"
                  ></el-input>
                </el-form-item>
                <el-form-item label="开户银行" prop="bank">
                  <el-input
                    v-model="peopleform.bank"
                    placeholder="请输入您的开户银行"
                  ></el-input>
                </el-form-item>
                <el-form-item label="身份证复印件" prop="idCopyPic">
                  <el-upload
                    class="upload-demo"
                    name="file"
                    action="http://139.129.236.203:4024/api/customer/follow/uploadMcnImage"
                    :headers="headers"
                    :on-success="uploadSuccess"
                    :on-remove="handleRemove"
                    :file-list="fileList"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
                </el-form-item>
                <el-form-item>
                  <el-checkbox v-model="ischoose"> </el-checkbox>
                </el-form-item>
                <div class="checkboxspan" @click="checktrue">
                  已阅读并同意《承诺函》相关告知和信息
                </div>
                <div class="checkboxspan1">
                  以上信息为天下网商合作及打款使用，不对外泄露
                </div> -->
                <div class="personbtn1">
                  <img
                    src="https://img.alicdn.com/imgextra/i2/O1CN01G245H11Qaq2RE8ojb_!!6000000001993-2-tps-191-62.png"
                    alt=""
                  />
                  <div class="btntitle" @click="summitPeople">提交</div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
        <div class="btnblock"></div>
      </div>
    </section>
  </div>
</template>

<script>
import { commitPeople, commitShop } from "@/api/wxmcn";

export default {
  data() {
    return {
      headers: {},
      hdgd: "680px",
      meunshow1: true,
      meunshow2: false,
      meunshow3: false,
      ischoose: false,
      testShow: false,
      peopleform: {},
      fileList: [],
      shopform: {},
      shoprules: {
        contactName: [
          { required: true, message: "请填写联系人", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请填写电话", trigger: "blur" },
        ],
        brand: [{ required: true, message: "请填写品牌名", trigger: "blur" }],
        role: [{ required: true, message: "请填写角色", trigger: "blur" }],
      },
      peoplerules: {
        // supplierType: [
        //   { required: true, message: "请选择供应商种类", trigger: "blur" },
        // ],
        // procurementType: [
        //   { required: true, message: "请选择采购业务类型", trigger: "blur" },
        // ],
        name: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请填写电话", trigger: "blur" }],
        // bankCard: [
        //   { required: true, message: "请填写银行卡号", trigger: "blur" },
        // ],
        // bank: [{ required: true, message: "请填写开户银行", trigger: "blur" }],
      },
    };
  },
  methods: {
    openpdf1() {
      window.open(
        "https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E5%AE%89%E8%A7%86%E4%BC%98.pdf"
      );
    },
    openpdf2() {
      window.open(
        "https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E3%80%90%E8%81%9A%E5%88%92%E7%AE%97%E3%80%91.pdf"
      );
    },
    openpdf3() {
      window.open(
        "https://txws-media.oss-cn-hangzhou.aliyuncs.com/%E3%80%90UR%E3%80%91.pdf"
      );
    },
    handleScroll() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置
      if (this.currentScroll >= this.$refs.testref.offsetTop + 600) {
        //当前滚动位置到达testref的时候，显示div（100作为调整用）
        this.testShow = true;
      }
    },
    uploadSuccess(response) {
      this.peopleform.idCopyPic = response.data.result;
    },
    handleRemove() {
      this.peopleform.idCopyPic = null;
    },
    checktrue() {
      console.log(111);
    },
    summitPeople() {
      this.$refs.peopleform.validate((valid) => {
        if (valid) {
          // if (!this.peopleform.idCopyPic) {
          //   this.$message.error("未上传身份证复印件");
          //   return;
          // }
          // if (!this.ischoose) {
          //   this.$message.error("需同意《承诺函》相关告知和信息");
          //   return;
          // }
          this.$confirm("是否确认提交?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let params = this.peopleform;
              commitPeople(params)
                .then(() => {
                  this.$message.success("提交成功");
                  this.peopleform = {};
                })
                .catch(() => {
                  this.$message.error("提交失败");
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消提交",
              });
            });
        } else {
          return false;
        }
      });
    },
    summitShop() {
      this.$refs.shopform.validate((valid) => {
        if (valid) {
          this.$confirm("是否确认提交?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let params = this.shopform;
              commitShop(params)
                .then(() => {
                  this.$message.success("提交成功");
                  this.shopform = {};
                })
                .catch(() => {
                  this.$message.error("提交失败");
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消提交",
              });
            });
        } else {
          return false;
        }
      });
    },
    meun1() {
      this.meunshow1 = true;
      this.meunshow2 = false;
      this.meunshow3 = false;
    },
    meun2() {
      this.meunshow2 = true;
      this.meunshow1 = false;
      this.meunshow3 = false;
    },
    meun3() {
      this.meunshow3 = true;
      this.meunshow2 = false;
      this.meunshow1 = false;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // var width = document.documentElement.clientWidth;
    // if (width < 1400) {
    //   this.hdgd = 460 + "px";
    // }
    // let that = this;
    // window.onresize = function windowResize() {
    //   // 通过捕获系统的onresize事件触发我们需要执行的事件
    //   var w = window.innerWidth;
    //   var h = 680;
    //   if (w > 1920) {
    //     h = 680;
    //   } else if (w < 1300) {
    //     h = 460;
    //   } else {
    //     h = 0.35 * w;
    //   }
    //   that.hdgd = h + "px";
    // };
  },
};
</script>

<style scoped>
.main7title1 {
  top: 80px;
  left: 515px;
  position: absolute;
  font-size: 43px;
  font-weight: 600;
}
.main7title1::after {
  content: "";
  display: block;
  width: 110px;
  height: 5px;
  border-radius: 10px;
  background-color: rgb(43, 130, 236);
  position: absolute;
  left: 35px;
  bottom: -5px;
}
.main7title2 {
  top: 200px;
  left: 340px;
  position: absolute;
  font-size: 18px;
  line-height: 40px;
}
.main7title3 {
  top: 200px;
  left: 800px;
  position: absolute;
  font-size: 18px;
  line-height: 40px;
  text-align: left;
}

.main6title1 {
  top: 0px;
  left: 515px;
  position: absolute;
  font-size: 43px;
  font-weight: 600;
}
.main6title1::after {
  content: "";
  display: block;
  width: 110px;
  height: 5px;
  border-radius: 10px;
  background-color: rgb(43, 130, 236);
  position: absolute;
  left: 35px;
  bottom: -5px;
}
.main6title2 {
  top: 160px;
  left: -80px;
  position: absolute;
  display: flex;
}
.main6pdf {
  margin-left: 100px;
}
.main6pdf:hover {
  cursor: pointer;
}
.main6pdf1 {
  width: 330px;
  height: 190px;
}
.main6pdf2 {
  font-weight: 550;
  font-size: 18px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  color: rgb(52, 52, 52);
}
.main6pdf3 {
  width: 250px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(153, 153, 153);
  text-align: left;
}
.sanjiao {
  width: 20px;
  height: 20px;
  border: 2px solid rgb(37, 89, 255);
  border-radius: 50%;
}
.sanjiao1 {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 9px solid rgb(37, 89, 255);
  border-bottom: 5px solid transparent;
  margin: 5px 0 0 6px;
}

/* 我们的案例 */
.main5title1 {
  top: 30px;
  left: 495px;
  position: absolute;
  font-size: 43px;
  font-weight: 600;
}
.main5title1::after {
  content: "";
  display: block;
  width: 110px;
  height: 5px;
  border-radius: 10px;
  background-color: rgb(43, 130, 236);
  position: absolute;
  left: 55px;
  bottom: -5px;
}
.main5title2 {
  width: 1050px;
  top: 150px;
  left: 100px;
  position: absolute;
  font-size: 24px;
  font-weight: 400;
}
.main5title3 {
  top: 280px;
  left: 540px;
  position: absolute;
  font-size: 30px;
  font-weight: 600;
}
.main5title4 {
  top: 348px;
  left: 365px;
  position: absolute;
  font-size: 20px;
  font-weight: 400;
}
.main5title5 {
  top: 439px;
  left: 540px;
  position: absolute;
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.main5title6 {
  top: 1075px;
  left: 530px;
  position: absolute;
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.main5title7 {
  top: 1820px;
  left: 490px;
  position: absolute;
  font-size: 30px;
  font-weight: 600;
}
.main5title8 {
  top: 1896px;
  left: 370px;
  position: absolute;
  font-size: 20px;
  font-weight: 400;
}
.main5title9 {
  top: 1996px;
  left: 560px;
  position: absolute;
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.main5title10 {
  top: 2703px;
  left: 553px;
  position: absolute;
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.main5title11 {
  top: 1155px;
  left: 0px;
  position: absolute;
  display: flex;
}
.main5title12 {
  top: 2103px;
  left: 433px;
  line-height: 30px;
  position: absolute;
  font-size: 17px;
  color: rgb(153, 153, 153);
}
.main5title13 {
  top: 2800px;
  left: 50px;
  position: absolute;
  display: flex;
}
.main5viedosum {
  margin-left: 45px;
}
.main5viedosum1 {
  width: 315px;
  height: 175px;
  background-color: rgb(255, 193, 194);
}
.main5viedosum2 {
  font-size: 20px;
  margin-top: 15px;
  color: rgb(153, 153, 153);
}
.main5viedo {
  width: 350px;
  height: 618px;
  background-color: rgb(255, 193, 194);
  margin-left: 45px;
}
/* 我们的流程 */
.main4title1 {
  top: 80px;
  left: 542px;
  position: absolute;
  font-size: 43px;
  font-weight: 600;
}
.main4title1::after {
  content: "";
  display: block;
  width: 110px;
  height: 5px;
  border-radius: 10px;
  background-color: rgb(43, 130, 236);
  position: absolute;
  left: 55px;
  bottom: -5px;
}
.main4title2 {
  top: 207px;
  left: 559px;
  position: absolute;
  font-size: 30px;
  font-weight: 600;
}
.main4title3 {
  top: 700px;
  left: 559px;
  position: absolute;
  font-size: 30px;
  font-weight: 600;
}
.main4title4 {
  top: 308px;
  left: 550px;
  position: absolute;
  font-size: 24px;
  font-weight: 600;
  color: white;
}
.main4title5 {
  top: 415px;
  left: 254px;
  position: absolute;
}
.main4title6 {
  top: 415px;
  left: 585px;
  position: absolute;
}
.main4title7 {
  top: 415px;
  left: 900px;
  position: absolute;
}
.main4title8 {
  top: 920px;
  left: 65px;
  position: absolute;
}
.main4title9 {
  top: 920px;
  left: 265px;
  position: absolute;
}
.main4title10 {
  top: 920px;
  left: 460px;
  position: absolute;
}
.main4title11 {
  top: 920px;
  left: 660px;
  position: absolute;
}
.main4title12 {
  top: 920px;
  left: 855px;
  position: absolute;
}
.main4title13 {
  top: 920px;
  left: 1050px;
  position: absolute;
}
.title4toptwo {
  font-weight: 550;
  font-size: 18px;
  margin-bottom: 15px;
  color: rgb(52, 52, 52);
}
.title4btntwo {
  width: 170px;
  font-size: 15px;
  font-weight: 500;
  color: rgb(153, 153, 153);
  margin-left: 10px;
}
.title4top {
  font-weight: 550;
  font-size: 20px;
  margin-bottom: 5px;
  color: rgb(52, 52, 52);
}
.title4btn {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 3px;
}
.title4btn1 {
  width: 17px;
  height: 17px;
  line-height: 17px;
  font-size: 13px;
  border-radius: 50%;
  background-color: rgb(51, 181, 255);
  color: white;
}
.title4btn2 {
  font-size: 16px;
  font-weight: 500;
  color: rgb(153, 153, 153);
  margin-left: 10px;
}
/* 我们的资源 */
.main3car {
  top: 317px;
  left: 30px;
  position: absolute;
}
.main3carousel {
  width: 1200px;
}
.main3left {
  display: flex;
}
.touxiang img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid rgb(230, 230, 230);
}
.lefttitle {
  text-align: left;
  margin-left: 40px;
}
.lefttitle1 {
  font-weight: 550;
  font-size: 20px;
  margin-bottom: 5px;
  color: rgb(52, 52, 52);
  position: relative;
}
.lefttitle2 {
  width: 460px;
  font-size: 18px;
  font-weight: 400;
  color: rgb(153, 153, 153);
  line-height: 33px;
}
.lefttitle1::after {
  content: "";
  display: block;
  width: 60px;
  height: 7px;
  background-color: rgba(255, 173, 132, 0.6);
  position: absolute;
  left: 0px;
  bottom: 1px;
}
.rightimg {
  margin-left: 30px;
  margin-bottom: 30px;
  position: relative;
}
.rightimg img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid rgb(255, 217, 199);
  box-sizing: border-box;
}
.righttitle {
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
}
.righttitle1 {
  font-size: 15px;
  display: flex;
  align-items: center;
  position: relative;
}
.rightname {
  font-size: 14px;
  border-radius: 10px;
  width: 90px;
  height: 20px;
  background-color: rgb(255, 225, 211);
  position: absolute;
  left: 5px;
  bottom: 25px;
}

.main3title1 {
  top: -10px;
  left: 490px;
  position: absolute;
  font-size: 43px;
  font-weight: 600;
}
.main3title2 {
  top: 107px;
  left: 543px;
  position: absolute;
  font-size: 30px;
  font-weight: 600;
}
.main3title1::after {
  content: "";
  display: block;
  width: 110px;
  height: 5px;
  border-radius: 10px;
  background-color: rgb(43, 130, 236);
  position: absolute;
  left: 55px;
  bottom: -5px;
}
.main3title3 {
  top: 157px;
  left: 550px;
  position: absolute;
  font-size: 25px;
  font-weight: 500;
  margin-top: 60px;
}
.main3title3::after {
  content: "";
  display: block;
  width: 110px;
  height: 7px;
  background-color: rgba(255, 225, 211, 0.7);
  position: absolute;
  left: -5px;
  bottom: 4px;
}
.main3title4 {
  top: 617px;
  left: 530px;
  position: absolute;
  font-size: 25px;
  font-weight: 500;
  margin-top: 60px;
}
.main3title9 {
  top: 967px;
  left: 530px;
  position: absolute;
  font-size: 25px;
  font-weight: 500;
  margin-top: 60px;
}
.main3title4::after {
  content: "";
  display: block;
  width: 155px;
  height: 7px;
  background-color: rgba(255, 225, 211, 0.7);
  position: absolute;
  left: -5px;
  bottom: 4px;
}
.main3title9::after {
  content: "";
  display: block;
  width: 155px;
  height: 7px;
  background-color: rgba(255, 225, 211, 0.7);
  position: absolute;
  left: -5px;
  bottom: 4px;
}
.main3title5 {
  top: 895px;
  left: 45px;
  position: absolute;
}
.main3title6 {
  top: 895px;
  left: 343px;
  position: absolute;
}
.main3title7 {
  top: 895px;
  left: 645px;
  position: absolute;
}
.main3title8 {
  top: 895px;
  left: 950px;
  position: absolute;
}
.main3title10 {
  top: 1130px;
  left: 300px;
  position: absolute;
}
.main3title11 {
  top: 1130px;
  left: 650px;
  position: absolute;
}
.main3title12 {
  top: 1300px;
  left: 205px;
  position: absolute;
}
.main3title13 {
  top: 1300px;
  left: 488px;
  position: absolute;
}
.main3title14 {
  top: 1300px;
  left: 783px;
  position: absolute;
}
.main3title15 {
  top: 1760px;
  left: 500px;
  position: absolute;
  font-size: 30px;
  font-weight: 600;
}
.main3title16 {
  top: 1850px;
  left: 215px;
  position: absolute;
}
.title3top1 {
  width: 100px;
  font-weight: 550;
  font-size: 18px;
  margin-left: 95px;
  margin-bottom: 15px;
  color: rgb(52, 52, 52);
  border: 2px solid rgb(10, 145, 254);
  border-radius: 20px;
}
.title3top {
  font-weight: 550;
  font-size: 18px;
  margin-bottom: 5px;
  color: rgb(52, 52, 52);
}
.title3btn {
  width: 230px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(153, 153, 153);
}

/* 我们的优势 */
.main2title1 {
  top: -10px;
  left: 490px;
  position: absolute;
  font-size: 43px;
  font-weight: 600;
  margin-top: 60px;
}
.main2title1::after {
  content: "";
  display: block;
  width: 110px;
  height: 5px;
  border-radius: 10px;
  background-color: rgb(43, 130, 236);
  position: absolute;
  left: 55px;
  bottom: -5px;
}
.main2title2 {
  top: 415px;
  left: 50px;
  position: absolute;
}
.main2title3 {
  top: 415px;
  left: 487px;
  position: absolute;
}
.main2title4 {
  top: 415px;
  left: 898px;
  position: absolute;
}
.title2top {
  font-weight: 550;
  font-size: 25px;
  margin-bottom: 15px;
  color: rgb(52, 52, 52);
}
.title2btn {
  margin-top: 10px;
  color: rgb(153, 153, 153);
}
.main7sum {
  width: 1200px;
  height: 418px;
  position: relative;
  margin: 0 auto;
}
.main6sum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main5sum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main4sum {
  width: 1300px;
  position: relative;
  margin: 0 auto;
}
.main3sum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main2sum {
  width: 1200px;
  height: 710px;
  position: relative;
  margin: 0 auto;
}
.main1sum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1title1 {
  top: 182px;
  left: 113px;
  position: absolute;
  text-align: left;
}
.main1title2 {
  top: 382px;
  left: 260px;
  position: absolute;
  text-align: left;
}
.main1title3 {
  top: 138px;
  left: 800px;
  position: absolute;
  text-align: left;
}
.main1title4 {
  top: 293px;
  left: 785px;
  position: absolute;
  text-align: left;
}
.main1title5 {
  bottom: 95px;
  left: 540px;
  position: absolute;
  text-align: left;
  font-size: 25px;
  font-weight: 500;
}
.title1top {
  font-weight: 450;
  font-size: 22px;
  margin-bottom: 15px;
  color: rgb(52, 52, 52);
}
.title1btn {
  width: 275px;
  color: rgb(153, 153, 153);
}
div /deep/.topcarou .el-carousel__indicators--horizontal {
  display: block;
}
div /deep/.main3carousel .el-carousel__indicators--horizontal {
  display: block;
  left: 100px;
  bottom: 120px;
}
div /deep/.main3carousel .el-carousel__button {
  width: 35px;
  height: 6px;
  background-color: rgb(43, 130, 236);
  border-radius: 2px;
  margin-left: 10px;
}
div /deep/.main3carousel .el-carousel__arrow {
  display: none;
}
.topcarousel {
  position: relative;
}
.topcarouseltitle {
  position: absolute;
  bottom: 260px;
  left: 390px;
}
.topcarouseltitle1 {
  font-size: 35px;
  font-weight: 500;
  color: black;
  margin-bottom: 23px;
}
.topcarouseltitle2 {
  width: 600px;
  font-size: 20px;
  font-weight: 400;
  color: black;
  line-height: 35px;
}
.topcarouseltitle3 {
  display: flex;
  margin-top: 50px;
}
.title31 {
  width: 135px;
  height: 25px;
  border: 1px solid black;
  border-radius: 20px;
  color: black;
  text-align: center;
  line-height: 25px;
}
.quxianimg {
  animation-name: myfirst;
  animation-duration: 8s;
  position: absolute;
  bottom: 30%;
}

@keyframes myfirst {
  0% {
    transform: translateX(-120px);
    opacity: 0;
  }
  40% {
    transform: translateX(0px);
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes myfirst /* Safari and Chrome */ {
  0% {
    -webkit-transform: translateX(-120px);
    opacity: 0;
  }
  40% {
    -webkit-transform: translateX(0px);
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.toptitle {
  position: absolute;
  bottom: 155px;
  left: 50%;
  transform: translateX(-50%);
}
.toptitletow {
  position: absolute;
  bottom: 130px;
  left: 50%;
  transform: translateX(-50%);
}
.toptitle1 {
  font-size: 35px;
  font-weight: 500;
  color: white;
}
.toptitle2 {
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin-top: 25px;
}
.toptitle3 {
  font-size: 18px;
  font-weight: 400;
  color: white;
}
.btnblock {
  height: 100px;
}
.checkboxspan {
  width: 300px;
  height: 20px;
  font-size: 14px;
  color: #606266;
  font-weight: 500;
  margin: -43px 0 0 145px;
  position: relative;
  z-index: 999;
}
.checkboxspan:hover {
  cursor: pointer;
}
.checkboxspan1 {
  line-height: 19px;
  font-size: 14px;
  color: #606266;
  font-weight: 500;
  margin: 10px 0 0 120px;
}
.personbtn1 {
  text-align: center;
  padding-top: 40px;
  position: relative;
}
.btntitle {
  width: 190px;
  height: 60px;
  position: absolute;
  top: 54px;
  left: 215px;
  font-size: 20px;
  color: white;
  letter-spacing: 15px;
  font-weight: 500;
}
.btntitle:hover {
  cursor: pointer;
}
.outimg {
  position: relative;
  text-align: center;
}
.outtitle {
  position: absolute;
  top: 30px;
  left: 540px;
  font-size: 16px;
  font-weight: 500;
}
.peopleout {
  width: 1140px;
  height: 800px;
  background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01Di0Njh1jdZ5yxEoEw_!!6000000004571-2-tps-1152-817.png");
  background-size: cover;
  margin: 0 auto;
}
.peopleform {
  width: 600px;
  margin: 0 auto;
}
.peopleimg {
  text-align: center;
  position: relative;
}
.main1 {
  text-align: center;
}
.main2 {
  text-align: center;
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01YgG4Qw1jxiWN3WcGf_!!6000000004615-2-tps-1920-710.png");
  background-size: cover;
  min-width: 1300px;
}
.main3 {
  text-align: center;
  margin-top: 100px;
  position: relative;
}
.main4 {
  text-align: center;
  margin-top: 100px;
  background-image: url("https://img.alicdn.com/imgextra/i1/O1CN01o0SxXq1HQuuoxkEES_!!6000000000753-2-tps-1920-1118.png");
  background-size: cover;
  min-width: 1300px;
  padding-bottom: 225px;
}
.main5 {
  text-align: center;
  margin-top: 75px;
  padding-bottom: 350px;
}
.main6 {
  text-align: center;
  margin-top: 40px;
  padding-bottom: 100px;
}
.main7 {
  text-align: center;
  background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01s2JEgO1VhJitrJMKs_!!6000000002684-2-tps-1920-420.png");
  background-size: cover;
  min-width: 1300px;
}
section {
  width: 100%;
  min-width: 1200px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
header {
  /* position: fixed;
  top: 0; */
  width: 100%;
  min-width: 1200px;
  max-width: 1920px;
  height: 80px;
  background-color: white;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.menu {
  display: flex;
  align-items: center;
}
.menuitem {
  width: 200px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 15px;
  font-weight: 500;
  color: black;
}
.menuitem1 {
  width: 200px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 15px;
  font-weight: 500;
  color: black;
  position: relative;
}
.menuitem1::after {
  content: "";
  display: block;
  width: 73px;
  height: 7px;
  background-color: rgba(255, 225, 211, 0.6);
  position: absolute;
  left: 65px;
  bottom: 10px;
}
.menuitem:hover {
  cursor: pointer;
}
</style>
