<template>
  <div>
    <!-- banner图 -->
    <div id="second-banner">
      <div
        class="banner2"
        v-for="(item, index) in $store.state.advertisementList"
        :key="index"
      >
        <a :href="item.bannerLink" target="_blank">
          <img :src="item.thumb" alt=""
        /></a>
      </div>
    </div>

    <!-- 业务动态 -->
    <div id="trends">
      <span>业务动态</span>
      <div class="trends-content">
        <el-timeline>
          <el-timeline-item
            color="rgb(215, 12, 24)"
            v-for="(item, index) in $store.state.trendsList"
            :key="index"
            @click.native="showIntro(item)"
          >
            <div class="timetitle">
              {{ item.title }}
            </div>
            <div v-show="item.isShow" class="dialog">
              <span>{{ item.intro }}</span>
            </div>
          </el-timeline-item>
        </el-timeline>
        <!-- <ul>
          <li
            v-for="(item, index) in $store.state.trendsList"
            :key="index"
            @click="showIntro(item)"
          >
            {{ item.title }}
            <div v-show="item.isShow" class="dialog">
              <span>{{ item.intro }}</span>
            </div>
          </li>
        </ul> -->
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div id="partners">
      <div class="partners-top">
        <span>合作伙伴</span>
      </div>
      <div class="partners-content">
        <el-carousel height="190px" width="310px">
          <el-carousel-item style="height: 190px; width: 310px">
            <div
              class="swiper-cont"
              v-for="(item, index) in partnerList"
              :key="index"
              v-show="index < 8"
            >
              <img :src="item.thumb" alt="" />
              <span>{{ item.name }}</span>
            </div>
          </el-carousel-item>
          <el-carousel-item style="height: 190px; width: 310px">
            <div
              class="swiper-cont"
              v-for="(item, index) in partnerList"
              :key="index"
              v-show="index >= 8 && index< 16"
            >
              <img :src="item.thumb" alt="" />
              <span>{{ item.name }}</span>
            </div>
          </el-carousel-item>
          <el-carousel-item style="height: 190px; width: 310px">
            <div
              class="swiper-cont"
              v-for="(item, index) in partnerList"
              :key="index"
              v-show="index >= 16 && index < 24"
            >
              <img :src="item.thumb" alt="" />
              <span>{{ item.name }}</span>
            </div>
          </el-carousel-item>
          <el-carousel-item style="height: 190px; width: 310px">
            <div
              class="swiper-cont"
              v-for="(item, index) in partnerList"
              :key="index"
              v-show="index >= 24"
            >
              <img :src="item.thumb" alt="" />
              <span>{{ item.name }}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      partnerList: [
        {
          thumb:
            "https://img.alicdn.com/imgextra/i4/O1CN01MbwYhL1sFb6gRC9Xi_!!6000000005737-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i3/O1CN01aHC8vx1gk22FA02PV_!!6000000004179-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i1/O1CN01PNFzR11JkjTlJy3R1_!!6000000001067-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i2/O1CN01bFcDZ01T3oWRFvvX0_!!6000000002327-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i3/O1CN01luiolq1O1SPBydUf0_!!6000000001645-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i2/O1CN01e0JGIF1yUbmbPWL5n_!!6000000006582-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i2/O1CN01AFZCiJ1nzmyMZe3k7_!!6000000005161-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i4/O1CN012diT5w1P1kGq1o8Ob_!!6000000001781-2-tps-60-60.png",
          name: "",
        },

        {
          thumb:
            "https://img.alicdn.com/imgextra/i1/O1CN01itwqUf1zWjEOwQJwl_!!6000000006722-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i3/O1CN014q2M0t1QKoN5fDrLR_!!6000000001958-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i1/O1CN01PYw3cy25M5j7ywrPQ_!!6000000007511-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i1/O1CN01JZ1xXw1MoLS1dDKpt_!!6000000001481-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i4/O1CN01UiYpRL1ZQbvl4qTip_!!6000000003189-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i3/O1CN01sn8ORG1DqKcw53kXc_!!6000000000267-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i4/O1CN01EJOgUb1CnINyMsxfY_!!6000000000125-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i2/O1CN01ixFPi61VAnnSNlp6z_!!6000000002613-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i2/O1CN01zaHVAU1tzEBBjpZg8_!!6000000005972-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i4/O1CN01R6vr1I1r7yuC0CGEn_!!6000000005585-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i3/O1CN01gtMutE1IVmwO6cTjW_!!6000000000899-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i4/O1CN01Lc0rvX1jewU1FH2ZO_!!6000000004574-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i1/O1CN01NEz2ue27wOLnGmnUj_!!6000000007861-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i4/O1CN01hoHWIQ1UJDSM1oStj_!!6000000002496-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i1/O1CN01oMqepn27eWu7K3y0W_!!6000000007822-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i2/O1CN017s77ei1KrtHmDjoz9_!!6000000001218-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i4/O1CN01Hp4gRi1ONtoQIw1O1_!!6000000001694-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i4/O1CN015Nu97c257R3Il3LUt_!!6000000007479-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i3/O1CN01Rns4Xr28fHAFbtYOx_!!6000000007959-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i2/O1CN01veyhyT1oO3xkulDpH_!!6000000005214-2-tps-60-60.png",
          name: "",
        },
        {
          thumb:
            "https://img.alicdn.com/imgextra/i2/O1CN01UCJ93E1nHMYPBmi4b_!!6000000005064-2-tps-60-60.png",
          name: "",
        },
     {
          thumb:
            "https://img.alicdn.com/imgextra/i3/O1CN01nvtu201cV8h8SLiJV_!!6000000003605-2-tps-60-60.png",
          name: "",
        },
        
      ],
    };
  },
  methods: {
    showIntro(item) {
      if (!item.isShow) {
        this.$set(item, "isShow", false);
        item.isShow = !item.isShow;
      } else {
        item.isShow = !item.isShow;
      }
    },
  },
};
</script>

<style scoped>
.timetitle:hover {
  color: rgb(215, 12, 24);
}
#second-banner {
  width: 310px;
  height: 402px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.banner2 {
  width: 150px;
  float: left;
}
.banner2 img {
  width: 150px;
  height: 124px;
}
#trends {
  width: 310px;
}
#trends > span {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  width: 72px;
  height: 18px;
  border-bottom: 3px solid rgb(215, 12, 24);
  padding: 10px 0;
}
.trends-content ul {
  margin: 20px 0;
  padding: 0 5px;
  width: 296px;
}
.trends-content ul li {
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #3e4047;
  list-style: none;
  cursor: pointer;
  position: relative;
  padding-left: 10px;
}
.trends-content ul li:before {
  content: "";
  width: 6px;
  height: 6px;
  display: inline-block;
  border-radius: 50%;
  background: rgb(215, 12, 24);
  vertical-align: middle;
  position: absolute;
  top: 5px;
  left: 0;
}
.dialog {
  width: 276px;
  height: auto;
  background-color: #fff;
  border: 2px solid;
  border-color: rgb(239, 239, 239) rgb(239, 239, 239) rgb(215, 12, 24)
    rgb(239, 239, 239);
  color: #27282d;
  position: relative;
  margin-top: 10px;
  z-index: 100;
  border-radius: 5px;
}
.dialog::before {
  content: "";
  border: 10px solid;
  border-color: transparent transparent rgb(239, 239, 239) transparent;
  width: 0px;
  height: 0px;
  position: absolute;
  left: 45px;
  top: -20px;
}
.dialog::after {
  content: "";
  width: 0;
  height: 0;
  border: 10px solid;
  position: absolute;
  left: 45px;
  top: -19px;
  border-color: transparent transparent #fff transparent;
}
.dialog > span {
  display: block;
  margin: 10px auto;
  font-size: 13px;
  font-weight: 400;
  color: #666464;
  width: 250px;
  line-height: 22px;
}
#hot {
  margin-top: 30px;
  height: 290px;
  width: 310px;
}

.hot-top {
  height: 26px;
}
.hot-top > span {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  width: 75px;
  height: 26px;
  border-bottom: 3px solid rgb(215, 12, 24);
}
.more {
  float: right;
  width: 85px;
  height: 26px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 8px;
  vertical-align: middle;
  text-align: center;
  line-height: 26px;
}
.more span {
  color: rgb(128, 128, 128);
  font-weight: 400px;
  font-size: 14px;
}
.hot-content {
  margin-top: 15px;
  height: 70px;
}
.hot-content .left {
  float: left;
}
.hot-content .left img {
  height: 70px;
  width: 110px;
}
.hot-content .right {
  float: right;
  height: 70px;
  width: 190px;
}
.hot-content .right span {
  font-size: 14px;
  font-weight: 400px;
  color: #333;
}
#demo {
  margin-top: 30px;
  height: 230px;
  width: 310px;
}
.demo-top {
  height: 26px;
}
.demo-top > span {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  width: 110px;
  height: 26px;
  border-bottom: 3px solid rgb(215, 12, 24);
}
#demo img {
  display: inline-block;
  width: 310px;
  height: 180px;
  margin-top: 20px;
}

#partners {
  width: 310px;
  margin-top: 30px;
}
.partners-top > span {
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  width: 72px;
  height: 25px;
  border-bottom: 3px solid rgb(215, 12, 24);
}
.partners-content {
  width: 310px;
  height: 190px;
  margin-top: 15px;

  display: inline-block;
}
.swiper-cont {
  width: 60px;
  height: 90px;
  text-align: center;
  float: left;
  margin: 5px;
}
.swiper-cont span {
  font-size: 14px;
  font-weight: 400;
  color: #3e4047;
  display: block;
}
</style>
