<template>
  <div>
    <div class="cont-01">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i1/O1CN01x2UjHC241BuETDlFU_!!6000000007330-2-tps-1920-1163.png"
        alt=""
      />
    </div>
    <div class="cont-02">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i3/O1CN01RvL4Oo1MMrYUlmyOb_!!6000000001421-2-tps-1920-725.png"
        alt=""
      />
    </div>
    <div class="cont-03">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i1/O1CN01QfGFuW1Nv2jGuMx7c_!!6000000001631-2-tps-1920-534.png"
        alt=""
      />
    </div>
    <div class="cont-04">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i3/O1CN01N3CxF81rH8h9ZqNwV_!!6000000005605-2-tps-1920-1257.png"
        alt=""
      />
    </div>
    <div class="cont-05">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i4/O1CN018w3czX1MxxdKZJY1r_!!6000000001502-2-tps-1920-3217.png"
        alt=""
      />
    </div>
    <div class="cont-05">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i1/O1CN01AkAFCv1TseLzzWzx4_!!6000000002438-2-tps-1920-2067.png"
        alt=""
      />
    </div>
    <div class="cont-05">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i2/O1CN01HYCXfC1I3qeG2zj56_!!6000000000838-2-tps-1920-3640.png"
        alt=""
      />
    </div>
    <div class="cont-06">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i3/O1CN01iPG9dC1QLj2LuHtuV_!!6000000001960-2-tps-1920-1586.png"
        alt=""
      />
      <!--       <div class="w-1150"> -->
      <div class="first">
        <a
          href="https://www.tianxiawangshang.com/tzyx/zebra/product/517"
          target="_blank"
          class="a1"
        ></a>
      </div>
      <div class="first1">
        <a
          href="https://www.tianxiawangshang.com/tzyx/zebra/product/518"
          target="_blank"
          class="a1"
        ></a>
      </div>
      <div class="first2">
        <a
          href="https://www.tianxiawangshang.com/tzyx/zebra/product/519"
          target="_blank"
          class="a1"
        ></a>
      </div>
      <div class="first3">
        <a
          href="https://www.tianxiawangshang.com/tzyx/zebra/product/520"
          target="_blank"
          class="a1"
        ></a>
      </div>
      <!--       </div> -->
    </div>
    <div class="cont-05">
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i4/O1CN01qJMsI31hSuiWfnMqJ_!!6000000004277-2-tps-1920-725.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.cont-01 {
  max-width: 1920px;
  min-width: 1200px;
}

.cont-02 {
  max-width: 1920px;
  min-width: 1200px;
  margin-top: -8.2%;
}

.cont-03 {
  max-width: 1920px;
  min-width: 1200px;
  margin-top: -2.3%;
}

.cont-04 {
  max-width: 1920px;
  min-width: 1200px;
  margin-top: -2.3%;
}

.cont-05 {
  max-width: 1920px;
  min-width: 1200px;
  margin-top: -2.3%;
}

.cont-06 {
  max-width: 1920px;
  min-width: 1200px;
  margin-top: -2.3%;
  position: relative;
}

.bottom {
  max-width: 1920px;
  min-width: 1200px;
}

.first {
  position: absolute;
  width: 135px;
  height: 35px;
  top: 21.5%;
  left: 26.8%;
}

.a1 {
  display: block;
  width: 135px;
  height: 35px;
}

.first1 {
  position: absolute;
  width: 135px;
  height: 35px;
  top: 34.5%;
  left: 26.8%;
}

.first2 {
  position: absolute;
  width: 135px;
  height: 35px;
  top: 48%;
  left: 26.8%;
}
.first3 {
  position: absolute;
  width: 135px;
  height: 35px;
  top: 61%;
  left: 26.8%;
}
</style>
