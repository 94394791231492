<template>
  <div class="bcgcolor">
    <header>
      <img
        width="100%"
        src="https://img.alicdn.com/imgextra/i3/O1CN01Ypqb1m1LEKVRSoWa3_!!6000000001267-2-tps-1920-641.png"
        alt=""
      />
      <div class="headimg" @click="gobuy">
        <img
          width="90%"
          src="https://img.alicdn.com/imgextra/i3/O1CN01OGcf8x1hXx1RAUsdP_!!6000000004288-2-tps-421-88.png"
          alt=""
        />
      </div>
    </header>
    <section>
      <div class="main1">
        <img
          src="https://img.alicdn.com/imgextra/i2/O1CN010ucH4b1Z9f5agnUC5_!!6000000003152-2-tps-1218-2670.png"
          alt=""
        />
      </div>
      <div class="main2">
        <img
          src="https://img.alicdn.com/imgextra/i1/O1CN01XSJ2Ir1dKtGTMzBrI_!!6000000003718-2-tps-1218-1060.png"
          alt=""
        />
      </div>
      <div class="main3">
        <div class="buysum">
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01eAFOmA1Hv94Pk7n5z_!!6000000000819-2-tps-1218-687.png"
            alt=""
          />
          <div id="buy"></div>
          <div class="buy" @click="buy"></div>
        </div>
      </div>
      <div class="main4">
        <img
          src="https://img.alicdn.com/imgextra/i4/O1CN018bylor1zwNE4WfAIS_!!6000000006778-2-tps-1218-838.png"
          alt=""
        />
      </div>
      <div class="main5">
        <img
          src="https://img.alicdn.com/imgextra/i3/O1CN01uKlH5D1faa43xxORS_!!6000000004023-2-tps-1218-1307.png"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    gobuy() {
      let returnEle = null;
      returnEle = document.querySelector("#buy");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    buy() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/354");
    },
  },
};
</script>

<style scoped>
div /deep/.el-carousel__arrow {
  width: 40px;
  height: 40px;
  font-size: 18px;
}
.bcgcolor {
  background-color: white;
  background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01KuGleO1KRmWXUd91Y_!!6000000001161-2-tps-1920-7475.png");
  background-size: cover;
}
.carousel-control.left {
  background-color: aqua;
}
.allvideo {
  margin-top: 20px;
  display: flex;
}
.onevideo {
  width: 170px;
  height: 620px;
  margin-left: 34px;
  position: relative;
}
.onevideo1 {
  width: 170px;
  height: 300px;
  background-color: white;
  margin-left: 34px;
  position: relative;
}
.title {
  width: 110px;
  height: 30px;
  background-color: rgb(251, 199, 1);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.title1 {
  width: 110px;
  height: 30px;
  background-color: rgb(14, 210, 250);
  border: 2px solid black;
  border-radius: 10px;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: -20px;
  left: 30px;
  z-index: 999;
}
.bttitle {
  margin-left: 10px;
  width: 150px;
  height: 30px;
  font-size: 16px;
  font-weight: 450;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.carousel1 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 570px;
}
.carousel2 {
  width: 1110px;
  height: 400px;
  background-color: transparent;
  color: white;
  position: absolute;
  left: 40px;
  bottom: 100px;
}
#buy {
  position: absolute;
  top: 0;
}
.buy {
  width: 335px;
  height: 70px;
  color: white;
  position: absolute;
  bottom: 13%;
  left: 38%;
  line-height: 50px;
  border-radius: 30px;
}
.buy1 {
  width: 185px;
  height: 50px;
  background-color: rgb(234, 59, 30);
  color: white;
  position: absolute;
  bottom: 10%;
  left: 22.5%;
  line-height: 50px;
  border-radius: 30px;
  font-size: 28px;
  font-weight: 600;
  border: 2px solid black;
}
.buy:hover {
  cursor: pointer;
}
.buy1:hover {
  cursor: pointer;
}
.buysum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1 {
  text-align: center;
  margin-top: -80px;
}
.main2 {
  text-align: center;
  margin-top: 75px;
}
.main3 {
  text-align: center;
  margin-top: 75px;
}
.main4 {
  text-align: center;
  margin-top: 75px;
}
.main5 {
  text-align: center;
  margin-top: 75px;
}
.main6 {
  text-align: center;
  margin-top: 75px;
  margin-right: 135px;
}
.main7 {
  text-align: center;
  padding-top: 80px;
  margin-top: -5px;
}
section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01KuGleO1KRmWXUd91Y_!!6000000001161-2-tps-1920-7475.png");
  background-size: cover;
  margin: 0 auto;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
.headimg {
  position: absolute;
  width: 400px;
  bottom: 17%;
  left: 52%;
  transform: translateX(-50%);
}
.headimg:hover {
  cursor: pointer;
}
</style>
