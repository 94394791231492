<template>
  <div>
    <section>
      <div class="main1">
        <img
          width="100%"
          src="https://img.alicdn.com/imgextra/i3/O1CN01hftaUt1J8ibtxZLcD_!!6000000000984-0-tps-1920-4413.jpg"
          alt=""
        />
        <div class="first" @click="goshop"></div>
        <div class="first1" @click="goshop1"></div>
        <div class="first2" @click="goshop2"></div>
 
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rightshow: true,
      phoneshow: false,
      a: "visible",
    };
  },
  methods: {
    changeStyle() {
      if (this.a === "visible") {
        this.a = "hidden";
      } else {
        this.a = "visible";
      }
    },
    gobuy() {
      let returnEle = null;
      returnEle = document.querySelector("#buy");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    goshop() {
      window.open("https://detail.tmall.com/item.htm?id=692132600018&spm=a1z10.1-b.w4004-22300800038.2.455845b0J6t8pQ");
    },
    goshop1() {
      window.open("https://detail.tmall.com/item.htm?id=692143144810&spm=a1z10.1-b.w4004-22300800038.4.455845b0J6t8pQ&sku_properties=14829532:72110507");
    },
    goshop2() {
      window.open("https://market.m.taobao.com/app/mtb/wwlight-web-air/wwlight.html?ver=3&touid=%E5%A4%A9%E4%B8%8B%E7%BD%91%E5%95%86-%E5%AE%A2%E6%BB%A1&siteid=cntaobao&status=1&charset=utf-8");
    },
  },
};
</script>

<style scoped>
.first {
  position: absolute;
  width: 10.93%;
  height: 0.99%;
  top: 57.12%;
  left: 66.87%;
  cursor: pointer;
}

.first1 {
  position: absolute;
  width: 10.78%;
  height: 0.95%;
  top: 65.93%;
  left: 66.97%;
  cursor: pointer;
}
.first2 {
  position: absolute;
  width: 0.78%;
  height: 0.33%;
  top: 96.26%;
  left: 43.64%;
  cursor: pointer;
}

#buy {
  position: absolute;
  top: 0;
}
.buy {
  width: 470px;
  height: 55px;
  color: white;
  position: absolute;
  bottom: 13%;
  left: 50%;
  line-height: 50px;
}
.buy:hover {
  cursor: pointer;
}
.buysum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1 {
  text-align: center;
  padding-top: 50px;
  position: relative;
}

section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;

  margin: -50px auto 0;
  box-shadow: inset 0px 45px 10px -15px white;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
.headimg {
  position: absolute;
  width: 25%;
  bottom: 25%;
  left: 52%;
  transform: translateX(-50%);
}
.headimg:hover {
  cursor: pointer;
}
</style>
