<template>
  <div>
    <section>
      <div class="main1">
        <img
          width="100%"
          src="https://img.alicdn.com/imgextra/i3/O1CN01tTUIck1uRcmSYiuNK_!!6000000006034-0-tps-1920-11660.jpg"
          alt=""
        />
        <div class="first" @click="goshop"></div>
        <div class="first1" @click="goshop1"></div>
        <div class="first2" @click="goshop2"></div>
        <div class="first4" @click="gowang"></div>
        <div class="first5" @click="gowang1"></div>
      
 
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rightshow: true,
      phoneshow: false,
      a: "visible",
    };
  },
  methods: {
    changeStyle() {
      if (this.a === "visible") {
        this.a = "hidden";
      } else {
        this.a = "visible";
      }
    },
    gobuy() {
      let returnEle = null;
      returnEle = document.querySelector("#buy");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    goshop() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/618");
    },
    goshop1() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/534");
    },
    goshop2() {
      window.open("https://www.tianxiawangshang.com/tzyx/zebra/product/535");
    },
    gowang() {
      window.open("https://market.m.taobao.com/app/im/chat/index.html?#/");
    },
    gowang1() {
      window.open("https://www.taobao.com/go/market/webww/ww.php?ver=3&touid=天下网商914&siteid=cntaobao&status=1&charset=utf-8");
    },
    

  },
};
</script>

<style scoped>
.first {
  position: absolute;
  width: 4.79%;
  height: 0.18%;
  top: 47.86%;
  left: 60.78%;
  cursor: pointer;
}

.first1 {
  position: absolute;
  width: 5.26%;
  height: 0.17%;
  top: 52.71%;
  left: 60.52%;
  cursor: pointer;
}
.first2 {
  position: absolute;
  width: 5.20%;
  height: 0.17%;
  top: 57.79%;
  left: 60.57%;
  cursor: pointer;
}
.first3 {
  position: absolute;
  width: 5.31%;
  height: 0.18%;
  top: 64.34%;
  left: 60.46%;
  cursor: pointer;
}
.first4 {
  position: absolute;
  width: 5.88%;
  height: 0.20%;
  top: 97.93%;
  left: 53.85%;
  cursor: pointer;
}
.first5 {
  position: absolute;
  width: 5.88%;
  height: 0.21%;
  top: 98.32%;
  left: 52.44%;
  cursor: pointer;
}


#buy {
  position: absolute;
  top: 0;
}
.buy {
  width: 470px;
  height: 55px;
  color: white;
  position: absolute;
  bottom: 13%;
  left: 50%;
  line-height: 50px;
}
.buy:hover {
  cursor: pointer;
}
.buysum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.videosum {
  width: 1200px;
  position: relative;
  margin: 0 auto;
}
.main1 {
  text-align: center;
  padding-top: 50px;
  position: relative;
}

section {
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;

  margin: -50px auto 0;
  box-shadow: inset 0px 45px 10px -15px white;
}
header {
  position: relative;
  width: 100%;
  min-width: 1300px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
}
.headimg {
  position: absolute;
  width: 25%;
  bottom: 25%;
  left: 52%;
  transform: translateX(-50%);
}
.headimg:hover {
  cursor: pointer;
}
</style>
