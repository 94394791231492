import request from "@/utils/request";

// 标签列表数量
export function getTaglistCount(params) {
  return request({
    url: "/api/product/content/getContentListByTagIdCount",
    method: "get",
    params,
  });
}

// 标签列表
export function getTaglist(params) {
  return request({
    url: "/api/product/content/getContentListByTagId",
    method: "get",
    params,
  });
}
