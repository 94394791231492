import request from "@/utils/request";

// 列表数量
export function getActivityCount(params) {
  return request({
    url: "/api/product/activity/count",
    method: "get",
    params
  });
}

// 列表
export function getActivity(params) {
  return request({
    url: "/api/product/activity/list",
    method: "get",
    params,
  });
}

//活动报道
export function getReportList(params) {
  return request({
    url: "/api/product/content/list",
    method: "get",
    params,
  });
}
