<template>
  <div>
    <header>
      <span>
        <img
          width="130%"
          src="https://img.alicdn.com/imgextra/i1/O1CN01Seh6is1IZuIZJdp3G_!!6000000000908-2-tps-100-36.png"
          alt=""
        />
      </span>
      <!-- <el-menu
        default-active="1"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="transparent"
        text-color="white"
        active-text-color="white"
      >
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2">大会议程</el-menu-item>
        <el-menu-item index="3">门票预售</el-menu-item>
        <el-menu-item index="4">合作伙伴</el-menu-item>
        <el-menu-item index="5">联系我们</el-menu-item>
      </el-menu> -->
      <div class="menu">
        <div class="menuitem" @click="move1">首页</div>
        <div class="menuitem" @click="move2">大会议程</div>
        <div class="menuitem" @click="move3">门票预售</div>
        <div class="menuitem" @click="move4">合作伙伴</div>
        <div class="menuitem" @click="move5">联系我们</div>
      </div>
      <div class="menuitem1" @click="move6">大会回顾</div>
    </header>
    <section>
      <div class="topimg" id="home">
        <video
          autoplay
          loop
          muted
          src="https://txws-media.oss-cn-hangzhou.aliyuncs.com/06.mp4"
        ></video>
        <!-- <img
          src="https://img.alicdn.com/imgextra/i3/O1CN01emjtQt1FvU706dYAr_!!6000000000549-2-tps-868-413.png"
          alt=""
        /> -->
      </div>
      <div class="toptitle">
        <span style="margin-right:150px">2021/05/11</span>
        <span>中国·杭州</span>
        <span style="margin-left:30px">|</span>
        <span style="margin-left:30px">城西银泰4楼 传奇奢华影城中国巨幕厅</span>
      </div>
      <div>
        <div class="left">
          <p>回看2020</p>
          <p>经典品牌推陈焕新，潮流爆品强势崛起</p>
          <p>消费人群瞬息万变，营销趋势蓬勃进化</p>
          <p>消费市场正在进行一场前所未有的迭代革新</p>

          <p style="margin-top: 50px;">2021，我们将更加关注新消费时代的到来</p>
          <p>共同探讨品牌如何紧跟数字时代的步伐</p>
          <p>在新的营销趋势下破局创新</p>

          <p style="margin-top: 50px;">天下网商攒局</p>
          <p id="spoiler">与你共话营销新趋势 揭秘营销新玩法</p>
        </div>
      </div>
      <div class="middle">
        <div class="boxsum">
          <div class="box1">
            <img
              style="width:100%"
              src="https://img.alicdn.com/imgextra/i3/O1CN01g552sS1KYC34xM8qH_!!6000000001175-2-tps-269-103.png"
              alt=""
            />
          </div>
          <div class="span1" id="spoiler">
            <div>大会剧透</div>
            <div>CONFERENCE SPOILER</div>
          </div>
        </div>
        <div class="pianchang">
          <div class="pian1">- - - - - - - - - - - - - - - - -</div>
          <div class="pian2">上午片场</div>
          <div class="pian1">- - - - - - - - - - - - - - - - -</div>
        </div>
        <div class="part1">
          <div class="parttitle">Wonder·新趋势</div>
        </div>
        <div class="pmmain">
          <div class="pmtime">
            <div>9:00-9:30</div>
            <div>9:30-10:00</div>
            <div>10:00-10:30</div>
          </div>
          <div class="pmstep">
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
          </div>
          <div class="pmcontent">
            <div class="contentsum">
              <div class="pmimg">
                <img
                  style="width:100%"
                  src="https://img.alicdn.com/imgextra/i2/O1CN0109YOdN1LPmOHELrRo_!!6000000001292-2-tps-435-435.png"
                  alt=""
                />
              </div>
              <div class="pmtitle">
                <div class="pmtitle1">《数智驱动新增长》</div>
                <div class="pmtitle2">阿里巴巴集团副总裁</div>
                <div class="pmtitle2">
                  阿里云智能新零售事业部总经理 肖利华博士
                </div>
              </div>
            </div>
            <div class="contentsum">
              <div class="pmimg">
                <img
                  style="width:100%"
                  src="https://img.alicdn.com/imgextra/i1/O1CN01YxKwkK1DCwMZELMMU_!!6000000000181-2-tps-455-455.png"
                  alt=""
                />
              </div>
              <div class="pmtitle">
                <div class="pmtitle1">《致胜“美好消费”的品牌新赛道》</div>
                <div class="pmtitle2">
                  欧莱雅北亚区兼中国消费者洞察总监 陶俊
                </div>
              </div>
            </div>
            <div class="contentsum">
              <div class="pmimg">
                <img
                  style="width:100%"
                  src="https://img.alicdn.com/imgextra/i1/O1CN01cMdOvk1hOn5I1scjf_!!6000000004268-2-tps-451-451.png"
                  alt=""
                />
              </div>
              <div class="pmtitle">
                <div class="pmtitle1">《新品牌的崛起之路》</div>
                <div class="pmtitle2">添可智能科技CEO 冷泠</div>
              </div>
            </div>
          </div>
        </div>
        <div class="part2">
          <div class="parttitle">Wonder·新增长</div>
        </div>
        <div class="pmmain">
          <div class="pmtime">
            <div>10:30-11:00</div>
            <div>11:00-11:20</div>
            <div>11:20-11:40</div>
          </div>
          <div class="pmstep">
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
          </div>
          <div class="pmcontent">
            <div class="contentsum">
              <div class="pmimg">
                <img
                  style="width:100%"
                  src="https://img.alicdn.com/imgextra/i1/O1CN01tITjdJ1nfdL9wVnN1_!!6000000005117-2-tps-448-447.png"
                  alt=""
                />
              </div>
              <div class="pmtitle">
                <div class="pmtitle1">《玩转品牌IP营销》</div>
                <div class="pmtitle2">阿里鱼副总经理 市场营销负责人 张薇</div>
              </div>
            </div>
            <div class="contentsum">
              <div class="pmimg">
                <img
                  style="width:100%"
                  src="https://img.alicdn.com/imgextra/i2/O1CN01WFXh2d1C1ezcmJt79_!!6000000000021-2-tps-230-230.png"
                  alt=""
                />
              </div>
              <div class="pmtitle">
                <div class="pmtitle1">《后疫情时代品牌营销新机遇》</div>
                <div class="pmtitle2">群邑中国首席产品及服务官 张晓涓</div>
              </div>
            </div>
            <div class="contentsum">
              <div class="pmimg">
                <img
                  style="width:100%"
                  src="https://img.alicdn.com/imgextra/i2/O1CN01nXmQS71ldgQOMfGt3_!!6000000004842-2-tps-446-446.png"
                  alt=""
                />
              </div>
              <div class="pmtitle">
                <div class="pmtitle1">《品牌营销数智化升级》</div>
                <div class="pmtitle2">阿里妈妈营销策略中心负责人 孙岩岩</div>
              </div>
            </div>
          </div>
        </div>
        <div class="pianchang1">
          <div class="pian1">- - - - - - - - - - - - - - - - -</div>
          <div class="pian2">下午片场</div>
          <div class="pian1">- - - - - - - - - - - - - - - - -</div>
        </div>
        <div class="part3">
          <div class="parttitle">Wonder·新消费</div>
        </div>
        <div class="pmmain">
          <div class="pmtime">
            <div>14:00-14:30</div>
            <div>14:30-15:00</div>
            <div>15:00-15:30</div>
            <div>15:30-16:00</div>
          </div>
          <div class="pmstep">
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
          </div>
          <div class="pmcontent">
            <div class="contentsum">
              <div class="pmimg">
                <img
                  style="width:100%"
                  src="https://img.alicdn.com/imgextra/i1/O1CN015V2fpX1w6DFssSm1u_!!6000000006258-2-tps-229-229.png"
                  alt=""
                />
              </div>
              <div class="pmtitle">
                <div class="pmtitle1">《新趋势新机遇》</div>
                <div class="pmtitle2">天猫快速消费品事业部个护总经理 英迈</div>
              </div>
            </div>
            <div class="contentsum">
              <div class="pmimg">
                <img
                  style="width:100%"
                  src="https://img.alicdn.com/imgextra/i4/O1CN01yvylIB1FpzMJyxGUr_!!6000000000537-2-tps-449-449.png"
                  alt=""
                />
              </div>
              <div class="pmtitle">
                <div class="pmtitle1">《产品创新新生态》</div>
                <div class="pmtitle2">AMIRO创始人兼CEO 王念欧</div>
              </div>
            </div>
            <div class="contentsum">
              <div class="pmimg">
                <img
                  style="width:100%"
                  src="https://img.alicdn.com/imgextra/i2/O1CN01XA3G7V1MAUmxNOBpH_!!6000000001394-2-tps-225-225.png"
                  alt=""
                />
              </div>
              <div class="pmtitle">
                <div class="pmtitle1">《新消费品牌的机会与应对》</div>
                <div class="pmtitle2">古良吉吉品牌创始人 古良</div>
              </div>
            </div>
            <div class="contentsum">
              <div class="pmimg">
                <img
                  style="width:100%"
                  src="https://img.alicdn.com/imgextra/i1/O1CN01BsKUmq25n6tNOUjYv_!!6000000007570-2-tps-444-444.png"
                  alt=""
                />
              </div>
              <div class="pmtitle">
                <div class="pmtitle1">《聚爆发-创造增长新势能》</div>
                <div class="pmtitle2">聚划算 策略中心负责人 奥钉</div>
              </div>
            </div>
          </div>
        </div>
        <div class="part4">
          <div class="parttitle">Wonder·新生态</div>
        </div>
        <div class="pmmain">
          <div class="pmtime">
            <div>16:00-16:30</div>
            <div>16:30-17:00</div>
            <div>17:00-17:30</div>
          </div>
          <div class="pmstep">
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
            <div class="pmstep2"></div>
            <div class="pmstep1"></div>
          </div>
          <div class="pmcontent">
            <div class="contentsum">
              <div class="pmimg">
                <img
                  style="width:100%"
                  src="https://img.alicdn.com/imgextra/i1/O1CN011JacK21LC2VfiHJuX_!!6000000001262-2-tps-445-445.png"
                  alt=""
                />
              </div>
              <div class="pmtitle">
                <div class="pmtitle1">《新消费时代助力品牌持续增长》</div>
                <div class="pmtitle2">碧橙数字VP 母婴快消事业群总经理 陆逊</div>
              </div>
            </div>
            <div class="contentsum">
              <div class="pmimg">
                <img
                  style="width:100%"
                  src="https://img.alicdn.com/imgextra/i3/O1CN01hOMYLl1rwofNsCLIh_!!6000000005696-2-tps-230-230.png"
                  alt=""
                />
              </div>
              <div class="pmtitle">
                <div class="pmtitle1">《微博新消费品牌营销》</div>
                <div class="pmtitle2">微博营销策略总监 米秀丽</div>
              </div>
            </div>
            <div class="contentsum">
              <div class="pmimg">
                <img
                  style="width:100%"
                  src="https://img.alicdn.com/imgextra/i1/O1CN01XrDnLM1p5ZOALhzup_!!6000000005309-2-tps-220-221.png"
                  alt=""
                />
              </div>
              <div class="pmtitle">
                <div class="pmtitle1">《商家直播战略升级》</div>
                <div class="pmtitle2">天字直播中心品牌直播负责人 马克</div>
              </div>
            </div>
          </div>
        </div>
        <div class="boxsum">
          <div class="box1">
            <img
              style="width:100%"
              src="https://img.alicdn.com/imgextra/i3/O1CN01g552sS1KYC34xM8qH_!!6000000001175-2-tps-269-103.png"
              alt=""
            />
          </div>
          <div class="span1">
            <div>门票预售</div>
            <div>ADVANCE TICKETS</div>
          </div>
        </div>
        <div class="ticket">
          <div @click="buyticket">
            <div class="ticketimg">
              <img
                src="https://img.alicdn.com/imgextra/i4/O1CN0145XfuV1ldgQZb78M6_!!6000000004842-2-tps-503-194.png"
                alt=""
              />
            </div>
            <div>
              <div class="link">
                <span
                  ><a href="http://hdxu.cn/mhuQZ" target="_blank"
                    >我要购票</a
                  ></span
                >
              </div>
            </div>
          </div>
          <div class="ticket2" @click="buyticket">
            <div class="ticketimg">
              <img
                src="https://img.alicdn.com/imgextra/i1/O1CN01uet57W1KUzHTqlzfb_!!6000000001168-2-tps-502-196.png"
                alt=""
              />
            </div>
            <div>
              <div class="link" id="together">
                <span
                  ><a href="http://hdxu.cn/mhuQZ" target="_blank"
                    >我要购票</a
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="sponsor" style="margin-top:50px">主办方</div>
        <div class="sponsorimg">
          <img
            src="https://img.alicdn.com/imgextra/i1/O1CN01lmZvbf1PeDKldOFzZ_!!6000000001865-2-tps-194-68.png"
            alt="天下网商"
          />
        </div>
        <div class="sponsor">合作支持</div>
        <div class="sponsorimg">
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01C9Yopl28KCk3dwQBv_!!6000000007913-2-tps-352-55.png"
            alt="阿里巴巴"
          />
        </div>
        <div class="sponsor">协办</div>
        <div class="sponsorimg1">
          <img
            width="100%"
            src="https://img.alicdn.com/imgextra/i1/O1CN01qO7iJA1LtY8cZtt8q_!!6000000001357-2-tps-2200-704.png"
            alt="阿里巴巴"
          />
        </div>
        <div class="sponsor">活动支持</div>
        <div class="sponsorimg">
          <img
            src="https://img.alicdn.com/imgextra/i4/O1CN01mVpGZr1zpVGWLtNpV_!!6000000006763-2-tps-196-56.png"
            alt="活动行"
          />
        </div>
        <div class="buttomdiv">
          联系我们
        </div>
        <footer>
          <div id="contact">
            <ul>
              <li>
                <ul>
                  <h1>商务合作：</h1>
                  <h3>院长</h3>
                  <h4>wechat：edison6066</h4>
                  <h4>Email：wb-wds464292@alibaba-inc.com</h4>
                </ul>
              </li>
              <li>
                <ul>
                  <h1>媒体合作：</h1>
                  <h3>Suri</h3>
                  <h4>wechat：88288769</h4>
                  <h4>E-mail：wb-xjd259705@alibaba-inc.com</h4>
                </ul>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    buyticket() {
      window.open("http://hdxu.cn/mhuQZ");
    },
    move1() {
      let returnEle = null;
      returnEle = document.querySelector("#home");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move2() {
      let returnEle = null;
      returnEle = document.querySelector("#spoiler");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move3() {
      let returnEle = null;
      returnEle = document.querySelector("#ticket");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move4() {
      let returnEle = null;
      returnEle = document.querySelector("#together");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move5() {
      let returnEle = null;
      returnEle = document.querySelector("#contact");
      if (returnEle) {
        returnEle.scrollIntoView(true);
      }
    },
    move6() {
      window.location.href =
        "https://www.tianxiawangshang.com/activity/wsfh/wowback";
    },
    // handleSelect(index) {
    //   let returnEle = null;
    //   if (index === "1") {
    //     returnEle = document.querySelector("#home");
    //   }
    //   if (index === "2") {
    //     returnEle = document.querySelector("#spoiler");
    //   }
    //   if (index === "3") {
    //     returnEle = document.querySelector("#ticket");
    //   }
    //   if (index === "4") {
    //     returnEle = document.querySelector("#together");
    //   }
    //   if (index === "5") {
    //     returnEle = document.querySelector("#contact");
    //   }
    //   if (returnEle) {
    //     returnEle.scrollIntoView(true);
    //   }
    // },
  },
};
</script>

<style scoped>
.buttomdiv {
  margin-top: 200px;
  width: 100%;
  height: 50px;
  background-color: black;
  text-align: center;
  color: white;
  font-size: 26px;
  line-height: 50px;
  letter-spacing: 6px;
}
.ticket {
  display: flex;
  justify-content: center;
}
.ticketimg {
  width: 502px;
  height: 195px;
  border: 3px solid white;
}
.ticketbutton {
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01rzgOWe1h7qQSO0h0r_!!6000000004231-2-tps-502-195.png");
}
.chooseticket {
  width: 200px;
  height: 100px;
  background-color: #ac642a;
  text-align: center;
  line-height: 100px;
  font-size: 40px;
  font-weight: 500;
  color: white;
}
.money {
  width: 400px;
  height: 100px;
  background-color: #f0a35f;
  text-align: center;
  line-height: 100px;
  font-size: 40px;
  font-weight: 500;
  color: blue;
  margin-bottom: 20px;
}
.link {
  width: 220px;
  height: 55px;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01Ho0y8v21Kw3mQQGF1_!!6000000006967-2-tps-217-52.png");
  margin: 0 auto;
  margin-top: 25px;
}
.link > span {
  display: block;
  text-align: center;
  line-height: 50px;
}
.link > span > a {
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  color: white;
  letter-spacing: 6px;
}
.desc {
  color: white;
  text-align: center;
}
.ticket2 {
  margin-left: 120px;
}
.sponsor {
  background-color: rgb(0, 140, 255);
  width: 460px;
  height: 70px;
  margin: 30px auto;
  border-radius: 15px;
  text-align: center;
  line-height: 70px;
  font-size: 40px;
  font-weight: 600;
}
.sponsorimg {
  width: 400px;
  height: 70px;
  margin: 0 auto;
  text-align: center;
}
.sponsorimg1 {
  width: 600px;
  height: 205px;
  margin: 0 auto;
  text-align: center;
}
footer {
  width: 100%;
  margin-top: 50px;
  min-width: 1300px;
  height: 310px;
  color: white;
  box-sizing: border-box;
}

footer > div {
  width: 1175px;

  margin: 0 auto;
}

footer > div > ul {
  width: 1015px;
  height: 175px;
  margin: 0 auto;
}
footer > div > ul > li {
  float: left;
  width: 415xp;
  height: 162px;
  list-style-type: none;
  margin: 0 70px;
}
/* 大会议程 */
.pm {
  width: 250px;
  height: 90px;
  background-color: rgb(0, 207, 255);
  margin-left: 19%;
  border-radius: 25px;
  margin-top: 50px;
  text-align: center;
  font-size: 58px;
  font-weight: 900;
  line-height: 90px;
}
.pmmain {
  margin-left: 19%;
  display: flex;
  margin-top: 150px;
}
.pmtime {
  color: rgb(0, 207, 255);
  font-size: 35px;
  font-weight: 600;
}
.pmtime div {
  margin-bottom: 220px;
}
.pmstep {
  margin: 10px 0 0 60px;
}
.pmstep1 {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: rgb(0, 207, 255);
}
.pmstep2 {
  height: 250px;
  border-right: 2px dashed rgb(0, 207, 255);
  margin-right: 10px;
}
.pmcontent {
  margin: -140px 0 0 70px;
}
.contentsum {
  display: flex;
  align-items: center;
  margin-top: 60px;
}
.pmimg {
  width: 210px;
  height: 210px;
}
.pmtitle {
  margin-left: 30px;
}
.pmtitle1 {
  color: white;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
}
.pmtitle2 {
  color: white;
  font-size: 25px;
  font-weight: 400;
  margin-left: 15px;
}

/* 大会剧透 */
.middle {
  width: 100%;
}
.pianchang {
  margin: 20px auto 0;
  width: 80%;
  color: rgb(0, 207, 255);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pianchang1 {
  margin: -40px auto 0;
  width: 80%;
  color: rgb(0, 207, 255);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pian1 {
  font-size: 30px;
}
.pian2 {
  font-size: 55px;
}
.part1 {
  height: 100px;
  color: white;
  margin: 50px 0 0 19%;
  font-size: 40px;
  font-weight: 500;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i2/O1CN01UJvZ6a1dYAjNzSWFL_!!6000000003747-2-tps-360-84.png");
}
.part2 {
  height: 100px;
  color: white;
  margin: 0 0 0 19%;
  font-size: 40px;
  font-weight: 500;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN01iIVC4f22uUQSuPFwS_!!6000000007180-2-tps-360-86.png");
}
.part3 {
  height: 100px;
  color: white;
  margin: 50px 0 0 19%;
  font-size: 40px;
  font-weight: 500;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i1/O1CN012rWZx21pepsrRDYIi_!!6000000005386-2-tps-361-87.png");
}
.part4 {
  height: 100px;
  color: white;
  margin: 0 0 0 19%;
  font-size: 40px;
  font-weight: 500;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i3/O1CN01siW5os1xCSGNKRtDN_!!6000000006407-2-tps-364-84.png");
}
.parttitle {
  margin: 0 0 0 250px;
  padding-top: 55px;
}
.boxsum {
  display: flex;
}
.box2 {
  width: 50px;
  height: 150px;
  background-image: linear-gradient(to bottom right, green, yellow);
  margin-left: 40px;
}
.box3 {
  width: 8%;
  height: 150px;
  background-image: linear-gradient(to bottom right, green, yellow);
  margin-left: 40px;
}
.span1 {
  margin-left: 3%;
}
.span1 div:nth-child(1) {
  color: white;
  font-size: 76px;
  font-weight: 700;
  display: block;
  margin: -20px 0 -5px 0;
}
.span1 div:nth-child(2) {
  color: white;
  font-size: 27px;
  font-weight: 400;
  display: block;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 1300px;
  height: 90px;
  background-color: black;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
header span {
  margin: 0 0 0 0;
}
.menu {
  display: flex;
}
.menuitem {
  width: 200px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 23px;
  font-weight: 500;
  color: white;
}
.menuitem:hover {
  cursor: pointer;
}
.menuitem1 {
  width: 130px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  font-weight: 500;
  margin-left: 20px;
  background-size: cover;
  background-image: url("https://img.alicdn.com/imgextra/i1/O1CN01Fhg8i723L3PGGbQi1_!!6000000007238-2-tps-129-42.png");
}
.menuitem1:hover {
  cursor: pointer;
}
.el-menu-demo {
  margin-right: 5%;
  min-width: 500px;
}
.el-menu-item {
  font-size: 17px;
  font-weight: 500;
}
.el-menu-item:hover {
  background-color: transparent !important;
}
.el-submenu__title:hover {
  background-color: transparent !important;
}
.topimg {
  width: 100%;
  text-align: center;
  background-size: cover;
  background-image: url("https://img.alicdn.com/imgextra/i1/O1CN01QIzdKG1eWAcGf4mY8_!!6000000003878-2-tps-1920-603.png");
}
video {
  width: 100%;
}
.toptitle {
  width: 100%;
  height: 50px;
  background-color: rgb(27, 123, 215);
  text-align: center;
  color: white;
  font-size: 22px;
  line-height: 50px;
  margin-top: -5px;
}
.left {
  height: 750px;
  margin: 90px 0 0 350px;
  background-repeat: no-repeat;
  background-image: url("https://img.alicdn.com/imgextra/i1/O1CN01KeLOH31JLXUIpoj3K_!!6000000001012-2-tps-336-546.png");
}
.left p {
  font-size: 24px;
  font-weight: 400;
  color: white;
  margin-left: 300px;
}
section {
  width: 100%;
  min-width: 1300px;
  height: 100%;
  background-image: url("https://img.alicdn.com/imgextra/i4/O1CN014dILWA2478z1Xxg4c_!!6000000007343-2-tps-1920-7779.png");
  background-size: cover;
  margin-top: 90px;
}
</style>
