<template>
  <div></div>
</template>

<script>
import { routertTo } from "@/api/idaxue";

export default {
  data() {
    return {
      bannerId: 0,
    };
  },
  methods: {
    getLink(classId) {
      let params = {};
      if (classId === "2") {
        params.bannerId = 98;
      } else {
        params.bannerId = 13;
      }
      routertTo(params)
        .then((res) => {
          this.Link = res.data.data.bannerLink;
          window.location.href = this.Link;
        })
        .catch(() => {
          this.$message.error("跳转失败");
        });
    },
  },
  created() {
    this.getLink(this.$route.params.classId);
  },
};
</script>
